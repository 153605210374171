import intersect from "@turf/intersect";
import turfCenter from "@turf/center";
import turfPointInPolygon from "@turf/boolean-point-in-polygon";
import turfUnion from "@turf/union";
import turfContains from "@turf/boolean-contains";
import turfDiffrence from "@turf/difference";
import polylabel from "@mapbox/polylabel";

export default class PolygonLabelGenerator {
    constructor(map) {
        this.map = map;
    }

    generateLabels(geometricLayerId) {
        return this._generateLabelFeatures(geometricLayerId);
    }

    _generateLabelFeatures(geometricLayerId) {
        let renderedFeatures = this.map.queryRenderedFeatures({
            layers: [geometricLayerId]
        });

        //Group features from diffrent tiles
        let groupedFeatures = this._groupFeatures(renderedFeatures);

        let visualCenterList = this._findVisualCenters(groupedFeatures);

        return visualCenterList.map((feature) =>
            this._getFeatureGeoJson("Point", feature.geometry.coordinates, feature.properties)
        );
    }

    _groupFeatures(features) {
        return features.reduce((a, b) => {
            if (!a.hasOwnProperty(b.properties.ogc_fid)) {
                a[b.properties.ogc_fid] = [];
            }
            a[b.properties.ogc_fid].push(b);
            return a;
        }, {});
    }

    _findVisualCenters(groupedFeatures) {
        let visualCenterList = [];

        for (let features of Object.values(groupedFeatures)) {
            let feature = features.length > 1 ? this._mergeFeatures(features) : features[0];

            if (!feature) continue;

            let ce = this._getVisualCenter(feature, features[0].properties);
            visualCenterList.push(...ce);
        }
        return visualCenterList;
    }

    _mergeFeatures(features) {
        //Union Together polygons from diffrent tiles
        let mapViewBound = this._getViewBoundingBox(this.map);
        let intersection;
        try {
            let union = features.reduce((a, b) => {
                a = turfUnion(a, b);
                return a;
            }, features[0]);
            intersection = intersect(mapViewBound, union);
        } catch (error) {
            return;
        }

        if (!intersection) return;

        //If union turns into a Multipolygon, make sure that no holes are filled
        if (intersection.geometry.type === "MultiPolygon") {
            intersection = this._removeholes(intersection);
        }

        return intersection;
    }

    _getVisualCenter(feature, properties) {
        if (feature.geometry.type === "Polygon") {
            let visualCenter = turfCenter(feature);

            //If the visual center does not fall within the polygon geometry
            let pointInside = turfPointInPolygon(visualCenter.geometry, feature.geometry);
            if (!pointInside) {
                visualCenter.geometry.coordinates = polylabel(feature.geometry.coordinates);
            }

            visualCenter.properties = properties;
            return [visualCenter];
        }
        if (feature.geometry.type === "MultiPolygon") {
            let visualCenters = [];

            feature.geometry.coordinates.forEach((coordinate) => {
                visualCenters.push(this._getFeatureGeoJson("Point", polylabel(coordinate), properties));
            });

            //If the visual center does not fall within the polygon geometry
            for (let i = 0; i < visualCenters.length; i++) {
                let visualCenter = visualCenters[i];
                let pointInside = turfPointInPolygon(visualCenter.geometry, feature.geometry);
                if (!pointInside) {
                    visualCenter.geometry.coordinates = polylabel(feature.geometry.coordinates[i]);
                }
            }

            return visualCenters;
        }
    }

    _removeholes(union) {
        let InitialGeometry = this._getFeatureGeoJson("Polygon", union.geometry.coordinates[0]);

        let validGeomtries = [];

        for (let i = 1; i < union.geometry.coordinates.length; i++) {
            let polygon = union.geometry.coordinates[i];

            let possibleHole = this._getFeatureGeoJson("Polygon", polygon);

            if (!turfContains(InitialGeometry, possibleHole)) {
                validGeomtries.push(union.geometry.coordinates[i]);
            } else {
                InitialGeometry = turfDiffrence(InitialGeometry, possibleHole);
            }
        }

        if (validGeomtries.length > 0) {
            return this._getFeatureGeoJson("MultiPolygon", [
                [InitialGeometry.geometry.coordinates[0]],
                ...validGeomtries
            ]);
        } else {
            return InitialGeometry;
        }
    }

    _getViewBoundingBox(map) {
        let mapSW = map.getBounds()._sw;
        let mapNE = map.getBounds()._ne;

        let coordinates = [
            [
                [mapSW.lng, mapSW.lat],
                [mapSW.lng, mapNE.lat],
                [mapNE.lng, mapNE.lat],
                [mapNE.lng, mapSW.lat],
                [mapSW.lng, mapSW.lat]
            ]
        ];

        return this._getFeatureGeoJson("Polygon", coordinates);
    }

    _getFeatureGeoJson(geometryType, coordinates, properties = {}) {
        return {
            type: "Feature",
            geometry: {
                type: geometryType,
                coordinates: coordinates
            },
            properties: properties
        };
    }
}
