import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./styles";
import { App } from "model/app/App";
import CustomChip from "components/CustomChip/CustomChip";
import PublicIcon from "@mui/icons-material/Public";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { getClientId, getProjectId } from "features/core/selectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import config from "config";
import OverflowTip from "components/common/OverflowTip/OverflowTip";

type Props = {
    app: App;
    getPathname: () => string;
    token: string;
};

const AppCard: FC<Props> = ({ app, token, getPathname }) => {
    const classes = useStyles();

    const clientId = useAppSelector(getClientId);
    const projectId = useAppSelector(getProjectId);

    const getImageUrl = () => {
        return `${config.apiUrl}application/${app.id}/thumbnail?key=${token}&ClientId=${clientId}&ProjectId=${projectId}`;
    };

    return (
        <Card className={classes.appCard}>
            <CardActionArea component={Link} to={getPathname()} data-testid={appCardId}>
                <CardMedia component="img" image={getImageUrl()} alt="" className={classes.image} />
                <CardContent className={classes.cardContent}>
                    <div>
                        <OverflowTip typographyClassName={classes.appName} component="h6" variant="h6">
                            {app.name}
                        </OverflowTip>
                        <Typography className={classes.modifiedUtcText} variant="body2">
                            Updated: {dayjs(app.modifiedUtc).fromNow()}
                        </Typography>
                    </div>
                    {app.public && (
                        <CustomChip
                            label={"Public"}
                            Icon={PublicIcon}
                            className={classes.blueChip}
                            expanded={true}
                            size="large"
                        />
                    )}
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default AppCard;

const appCardId = "qa-app-card";
