import { RootState } from "../store/models/RootState";

export const getAppsSelector = (state: RootState) => state.apps.apps;

export const getLoadingApps = (state: RootState) => state.apps.loadingApps;

export const getAppsError = (state: RootState) => state.apps.error;

export const getSelectedApp = (state: RootState) => state.apps.selectedApp;

export const getSelectedAppIsPublic = (state: RootState) => state.apps.selectedApp?.public;

export const getSelectedAppSearchBar = (state: RootState) => state.apps.selectedApp?.searchBar;
