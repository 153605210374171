import { Divider, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";

import InfoIcon from "@mui/icons-material/Info";
import CardContent from "@mui/material/CardContent";
import { getTypeDescription } from "./utils";
import CollapsibleCard from "../../common/CollapsibleCard/CollapsibleCard";
import ReadOnlyField from "../../common/readOnlyField/readOnlyField";
import { getAISSubscriptionInfo } from "../../../selectors/aisSelectors";

import { AIS_TITLE } from "./utils";
import { FromNow } from "../../common/FromNow/FromNow";
import CardTitle from "./CardTitle";

let boatTypes = [
    { name: "Tanker", icon: "boat-15-red.svg" },
    { name: "Pleasure Craft", icon: "boat-15-purple.svg" },
    { name: "Passenger", icon: "boat-15-blue.svg" },
    { name: "Tug & Special Craft", icon: "boat-15-teal.svg" },
    { name: "Cargo", icon: "boat-15-green.svg" },
    { name: "High-Speed Craft", icon: "boat-15-yellow.svg" },
    { name: "Fishing", icon: "boat-15-orange.svg" },
    { name: "Unspecified", icon: "boat-15.svg" },
    { name: "Navigation Aid", icon: "navigational-aid.svg" }
];

const AISComponent = () => {
    const classes = useStyles();

    const subscriptionInfo = useSelector(getAISSubscriptionInfo);

    useEffect(() => {
        setToggledSubs(initToggledSubs());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionInfo.length]);

    const initToggledSubs = () => {
        return subscriptionInfo.reduce((state, current) => {
            return { ...state, [current.id]: true };
        }, {});
    };
    const [toggledSubs, setToggledSubs] = useState(initToggledSubs());

    const renderSubscriptions = () => {
        return subscriptionInfo.map((sub) => (
            <div className={classes.section} key={sub.id}>
                <CollapsibleCard
                    onToggleClick={() => toggleCard(sub.id)}
                    Component={<CardTitle sub={sub} toggleCard={toggleCard} />}
                    isToggled={toggledSubs[sub.id]}
                    unmountOnExit
                    id={sub.name}
                >
                    <div className={classes.cardBody}>
                        <ReadOnlyField label="Description" text={sub.description || "-"} />
                        <ReadOnlyField label="Type" text={getTypeDescription(sub.type)} />
                        <ReadOnlyField label="Update Frequency In Minutes" text={sub.updateFrequency} />
                        <FromNow label="Created" date={sub.createdUtc} tooltip interval={1000} />
                        <FromNow label="Last Update" date={sub.latestUpdateUtc} tooltip interval={1000} />
                    </div>
                </CollapsibleCard>
            </div>
        ));
    };

    const toggleCard = (subId: string) => {
        const toggle = toggledSubs[subId];
        setToggledSubs({ ...toggledSubs, [subId]: !toggle });
    };

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Divider />
            <Grid container justifyContent="flex-start" className={classes.title}>
                <Typography variant="h6">{AIS_TITLE}</Typography>
            </Grid>
            <Divider />

            <div className={classes.legend}>
                <Typography gutterBottom variant="h6">
                    Legend
                </Typography>
                <div className="info-section__text">
                    {boatTypes.map((type, index) => {
                        return (
                            <div key={index} className={classes.legendItem}>
                                <div className={classes.legendText}>{type.name}</div>
                                <img className={classes.legendIcon} src={"/icons/" + type.icon} alt="" />
                            </div>
                        );
                    })}
                </div>
            </div>

            {subscriptionInfo.length ? (
                renderSubscriptions()
            ) : (
                <CardContent>
                    <div className="info-section">
                        <InfoIcon className="info-section__icon" />
                        <div className="info-section__text">
                            There is no active subscription to vessel position data at the moment. Please contact your
                            WindGIS administrator for further information.
                        </div>
                    </div>
                </CardContent>
            )}
        </Grid>
    );
};

export default AISComponent;
