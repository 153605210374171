import { useEffect, useState } from "react";
import { MapStyleService } from "../../../components/map/utils/basemapUtils";
import blankBasemapStyle from "../../../constants/map/blankBasemapStyle";
import { Basemap } from "../../../model/mapComponent/Basemap";
import { Source } from "../../../model/map/Source";
import { handleError } from "../../networkErrorUtils";

type ExtraData = {
    sources: Source[];
    layers: any[];
    paintsDict: any;
    layoutsDict: any;
    zoomRangesDict: any;
    isPublic: boolean;
};

type Params = {
    basemap: any;
    extraData?: ExtraData;
};

export const useMapStyle = ({ basemap, extraData }: Params) => {
    const [mapStyle, setMapStyle] = useState<Basemap>(blankBasemapStyle);

    useEffect(() => {
        new MapStyleService(extraData)
            .getBasemapStyle(basemap!)
            .then((res) => {
                setMapStyle(res);
            })
            .catch(handleError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [basemap]);

    return { mapStyle, setMapStyle };
};
