import { createContext, FCWC, useEffect } from "react";
import { useMap } from "@emblautec/react-map-gl";
import { useDraw } from "../../../../utils/customHooks/map/useDraw";

type MainDrawContextValue = null | MapboxDraw;

export const MainDrawContext = createContext<MainDrawContextValue>(null);

export const MainDrawProvider: FCWC = ({ children }) => {
    const draw = useDraw();
    const { mainMap } = useMap();

    useEffect(() => {
        if (!mainMap) return;

        mainMap.addControl(draw);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainMap]);

    const drawValue = mainMap ? draw : null;

    return <MainDrawContext.Provider value={drawValue}>{children}</MainDrawContext.Provider>;
};
