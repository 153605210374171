import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MapCopyright from "../../../map/printMapExtraInfo/mapCopyright";
import MapTitle from "../../../map/printMapExtraInfo/mapTitle";
import MapDate from "../../../map/printMapExtraInfo/mapDate";
import MapNotes from "../../../map/printMapExtraInfo/mapNotes";
import { getPrintFeatures } from "../../../../selectors/printSelectors";

import widgetTypes from "../../../../utils/tools/widgets";
import LegendEntries from "../../../legend/LegendEntries/LegendEntries";
import { useStyles } from "./styles";
import { useLegendEntryStyles } from "./legendEntryStyles";
import { getToggledWidgetsSet } from "features/mapTools/selectors";

const MapLegendPortrait = () => {
    const classes = useStyles();

    const legendEntryClasses = useLegendEntryStyles();

    // eslint-disable-next-line
    const printFeatures = useSelector(getPrintFeatures);
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT);

    const isRightPanelNecessary =
        printFeatures.showMapTitle ||
        printFeatures.showMapCopyright ||
        printFeatures.showMapDate ||
        printFeatures.showMapNotes;

    const spacingOptions = {
        singleEntryBreakpointSpacing: 6,
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 3,
        multiLabelItemVerticalBreakpointSpacing: 12
    };

    return (
        <Grid id="modal-info-portrait" container className={classes.root}>
            <Grid item xs={isRightPanelNecessary ? 10 : 12} className={classes.innerRoot}>
                <Grid container>
                    <Grid item xs={12} className={classes.titlecontainer}>
                        <Typography className={classes.legendTitle}>Legend</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.labelsContainer}>
                        <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
                    </Grid>
                </Grid>
            </Grid>

            {isRightPanelNecessary && (
                <Grid item xs={2} className={classes.rightSidePanel}>
                    <Grid container>
                        {/* TEMPORARILY DISABLED */}
                        {/* <Grid item xs={12}>
                            {printFeatures.showMapLogo && <MapLogo modal={modalOpen} />}
                        </Grid> */}
                        <Grid item xs={12} className={classes.sectionStyle}>
                            {printFeatures.showMapTitle && (
                                <MapTitle mapTitle={printFeatures.mapTitle} modal={modalOpen} />
                            )}
                        </Grid>
                    </Grid>
                    {printFeatures.showMapScalebar && <div id="map-scalebar"></div>}
                    {printFeatures.showMapCopyright && (
                        <MapCopyright
                            mapCopyright={printFeatures.mapCopyright}
                            modal={modalOpen}
                            showMapLegend={printFeatures.showMapLegend}
                        />
                    )}
                    {printFeatures.showMapDate && (
                        <MapDate
                            mapDateFormat={printFeatures.mapDateFormat}
                            showMapLegend={printFeatures.showMapLegend}
                            modal={modalOpen}
                        />
                    )}
                    <div className={classes.notesSectionStyle}>
                        {printFeatures.showMapNotes && <MapNotes mapNotes={printFeatures.mapNotes} modal={modalOpen} />}
                    </div>
                </Grid>
            )}
        </Grid>
    );
};

export default MapLegendPortrait;
