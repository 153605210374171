import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AppBar, Button, Dialog, Divider, Grid, Typography } from "@mui/material";
import axiosClient from "actions/apiClient";
import { EXCEL_GENERATING_ID, MAX_SHEETS } from "features/zander/constants";
import useZander from "features/zander/hooks/useZander";
import { ZanderResponse } from "features/zander/models/ZanderReponse";
import { ZanderRequest } from "features/zander/models/ZanderRequest";
import { emptySheet, initialSheet, linkDownload, sanitizeSheets, validateSheets } from "features/zander/utils";
import { useMemo, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { createManagedToastr, default as toastr } from "utils/customToastr";
import ZanderSheet from "./components/ZanderSheet/ZanderSheet";
import ZanderTabs from "./components/ZanderTabs/ZanderTabs";
import { useZanderModalStyles } from "./styles";

type Props = {
    open: boolean;
    onClose: () => void;
    lat: number;
    long: number;
};

const ZanderModal = ({ open, onClose, lat, long }: Props) => {
    const classes = useZanderModalStyles();
    const [activeSheet, setActiveSheet] = useState(0);
    const [sheets, setSheets] = useState([{ ...initialSheet }]);
    const [loading, setLoading] = useState(false);
    const [honeypot, setHoneypot] = useState("");
    const dispatch = useAppDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const managedToastr = useMemo(() => createManagedToastr(dispatch), []);

    const connectionId = useZander((response) => {
        setLoading(false);

        if (response.error) {
            toastr.error(response.errorMessage);
            managedToastr.remove(EXCEL_GENERATING_ID);
        } else {
            managedToastr.remove(EXCEL_GENERATING_ID);
            toastr.info("Your download should be starting soon");
            linkDownload(response.documentLink);
        }
    });

    const onSheetAdd = () => {
        setActiveSheet(sheets.length);
        setSheets([...sheets, { ...emptySheet }]);
    };

    const onClearSheet = () => {
        sheets.splice(activeSheet, 1, { ...emptySheet });
        setSheets([...sheets]);
    };

    const onDialogClose = () => {
        onClose();
        setActiveSheet(0);
        setSheets([{ ...initialSheet }]);
    };

    const onZanderRequest = () => {
        setLoading(true);

        if (honeypot !== "") return;

        if (!connectionId) {
            toastr.error("Server connection lost");
            return;
        }

        const request: ZanderRequest = {
            connectionId,
            lat,
            long,
            sheets: sanitizeSheets(sheets)
        };

        axiosClient
            .post<ZanderResponse>("zander", request)
            .then((response) => {
                const zanderResponse = response.data;

                if (zanderResponse.error) {
                    toastr.error(zanderResponse.errorMessage);
                    setLoading(false);
                } else {
                    managedToastr.info(EXCEL_GENERATING_ID, "Your excel is being generated");
                }
            })
            .catch(() => {
                setLoading(false);
                toastr.error("Failed to generate excel file");
            });
    };

    const onTabClose = (index: number) => {
        const length = sheets.length;
        const currentSheets = sheets;

        currentSheets.splice(index, 1);
        setSheets([...currentSheets]);

        if (activeSheet === length - 1) {
            setActiveSheet(index - 1);
        }
    };

    return (
        <Dialog open={open} className={classes.zanderDialog} onClose={onDialogClose}>
            <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" px={2} py={1}>
                <Grid item>
                    <Typography variant="h5">ESOX Zander</Typography>
                </Grid>
                <Grid item display="flex" gap={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<AddCircleIcon />}
                        onClick={onSheetAdd}
                        disabled={sheets.length >= MAX_SHEETS}
                    >
                        Add table
                    </Button>
                    <Button variant="text" color="primary" onClick={onClearSheet}>
                        Clear table
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <AppBar position="static" color="inherit" className={classes.tabsWrapper}>
                <ZanderTabs
                    activeSheet={activeSheet}
                    sheets={sheets}
                    onChange={(index: number) => setActiveSheet(index)}
                    onClose={onTabClose}
                />
            </AppBar>
            <SwipeableViews index={activeSheet}>
                {sheets.map((sheet, index) => (
                    <div key={index} hidden={index !== activeSheet}>
                        <ZanderSheet
                            key={index}
                            sheet={sheet}
                            onSheetChanged={(newSheet) =>
                                setSheets(sheets.map((sheet, i) => (index === i ? newSheet : sheet)))
                            }
                        />
                    </div>
                ))}
            </SwipeableViews>
            <input
                type="text"
                className={classes.honeypot}
                autoComplete="off"
                onChange={(e) => setHoneypot(e.target.value)}
            />
            <Divider />
            <div className={classes.zanderDialogActions}>
                <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={onZanderRequest}
                    className={classes.spacing}
                    disabled={loading || !connectionId || !validateSheets(sheets)}
                >
                    Generate excel file
                </Button>
                <Button size="medium" variant="text" color="primary" onClick={onDialogClose}>
                    Cancel
                </Button>
            </div>
        </Dialog>
    );
};

export default ZanderModal;
