import { AnySourceData } from "mapbox-gl";
import { FC, memo, useMemo, useRef } from "react";
import { Layer, useMap } from "@emblautec/react-map-gl";
import { StyleType } from "../../../../model/enums/StyleType";
import { MapLayer as LayerType } from "../../../../model/map/MapLayer";
import PolygonLabelLayer from "../PolygonLabelLayer/PolygonLabelLayer";
import { MapFilter } from "model/map/MapFilterType";
import { toMapboxFilters } from "components/map/utils/mapFilterUtils";

type HOCProps = LayerType & {
    paintProperties?: Object[];
    layoutProperties?: Object[];
    minZoom: number;
    maxZoom: number;
    source?: AnySourceData | string; // Can be passed by the Source wrapper
    polygonLayerId?: string; // This is passed for symbol styles, in case they need to be shown as polygon labels
    filters?: MapFilter[];
};

// A small HOC for interacting with the map context. This enables us to use memo on the layer and
// improve performance
const withBeforeIdCheckHOC = (Component: FC<MapLayerProps>) => (props: HOCProps) => {
    const { current } = useMap();
    const { drawUnderId } = props;

    const beforeId = useRef<string | undefined>(undefined);

    if (beforeId.current !== drawUnderId && drawUnderId && current?.getLayer(drawUnderId)) {
        beforeId.current = drawUnderId;
    }

    return <Component {...props} beforeId={beforeId.current} />;
};

type MapLayerProps = Omit<HOCProps, "drawBeforeId"> & { beforeId?: string };

// Wrapper component for the @emblautec/react-map-gl Layer component. This will decide the order of the layers
// and if to render symbols as polygon layers

// Chose to pass individual values instead of the layer object for easier memo comparisons
const MapLayer: FC<MapLayerProps> = ({
    paintProperties,
    layoutProperties,
    minZoom,
    maxZoom,
    source,
    beforeId,
    polygonLayerId,
    filters,
    ...layerProps
}) => {
    const { layerId, resourceId, sourceId, sourceName, type } = layerProps;

    const filter = useMemo(() => toMapboxFilters(filters), [filters]);

    const paint = useMemo(
        () =>
            paintProperties?.reduce((acc: Object, prop: any) => {
                if (prop.title === "Legend") return acc;
                acc[prop.name] = prop.value;
                return acc;
            }, {}),
        [paintProperties]
    );

    const layout = useMemo(
        () =>
            layoutProperties?.reduce((acc: Object, prop: any) => {
                acc[prop.name] = prop.value;
                return acc;
            }, {}),
        [layoutProperties]
    );

    if (type === StyleType.Symbol && polygonLayerId) {
        return (
            <PolygonLabelLayer
                layerId={layerId}
                minZoom={minZoom}
                maxZoom={maxZoom}
                beforeId={beforeId}
                key={layerId}
                polygonLayerId={polygonLayerId}
                layout={layout}
            />
        );
    }

    return (
        <Layer
            // This key is very important since it controls the instance of the component.
            // We have to remove the layer and add a new one when the type changes
            key={type}
            // -------------
            id={layerId}
            type={type}
            paint={paint}
            layout={layout}
            beforeId={beforeId}
            source={source || sourceId}
            minzoom={minZoom}
            maxzoom={maxZoom}
            filter={filter}
            {...(sourceName && { "source-layer": sourceName })}
            metadata={{ resourceId }}
        />
    );
};

export default withBeforeIdCheckHOC(memo(MapLayer));
