import { useMemo, ReactNode } from "react";
import { getDigitizeLayers, getDigitizeLayersVisibilityMapSelector } from "../../../selectors/digitizeSelectors";
import { getLayerGroups, getLayerStylesMap, getLayerVisibilityMap } from "../../../selectors/layerSelector";
import { digitizeLayerToPartialAppLayer, digitizeLayerToStyle } from "../../../utils/digitizeUtils";
import { renderLegendEntry } from "../../../utils/legend/legendUtils";
import clsx from "clsx";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../../store/hooks/useAppSelector";

export type LegendSpacingOptions = {
    singleEntryBreakpointSpacing: number;
    multiLabelEntryBreakpointSpacing: number;
    multiLabelItemBreakpointSpacing: number;
    multiLabelItemVerticalBreakpointSpacing: number;
};

export type LegendClasses = {
    labelsContainer: any;
    singleLabelEntryContainer: any;
    multiLabelEntryContainer: any;
    multiLabelHeaderText: any;
    labelText: any;
    multiLabelText: any;
    dividerSpacing: any;
};

type Props = {
    spacingOptions: LegendSpacingOptions;
    classes: LegendClasses;
};

const LegendEntries = ({ spacingOptions, classes }: Props) => {
    const digitizeLayers = useAppSelector(getDigitizeLayers);
    const layerGroups = useAppSelector(getLayerGroups);
    const stylesMap = useAppSelector(getLayerStylesMap);
    const layerVisibilityMap = useAppSelector(getLayerVisibilityMap);
    const digitizeLayersVisibilityMap = useAppSelector(getDigitizeLayersVisibilityMapSelector);

    const normalLegendEntries = useMemo(() => {
        const renderedLegendEntries: ReactNode[] = [];
        layerGroups.forLayersRecursive((lay) => {
            if (layerVisibilityMap[lay.resourceId] && lay.isShown) {
                renderedLegendEntries.push(renderLegendEntry(lay, stylesMap[lay.resourceId], classes, spacingOptions));
            }
        });

        return renderedLegendEntries;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerGroups, stylesMap, layerVisibilityMap]);

    const digitizeLegendEntries = useMemo(() => {
        const renderedLegendEntries: ReactNode[] = [];
        digitizeLayers.forEach((lay: any) => {
            if (digitizeLayersVisibilityMap[lay.id]) {
                renderedLegendEntries.push(
                    renderLegendEntry(
                        digitizeLayerToPartialAppLayer(lay),
                        [digitizeLayerToStyle(lay, lay.styleCount)],
                        classes,
                        spacingOptions
                    )
                );
            }
        });
        return renderedLegendEntries;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [digitizeLayersVisibilityMap, digitizeLayers]);

    return (
        <Grid container className={clsx(classes.labelsContainer)}>
            {[...normalLegendEntries, ...digitizeLegendEntries]}
        </Grid>
    );
};

export default LegendEntries;
