import makeStyles from "@mui/styles/makeStyles";

const useGroupStyles = makeStyles((theme) => ({
    root: {
        "&:not(:last-child)": {
            marginBottom: theme.spacing(1)
        }
    },
    rootContainer: {
        margin: theme.spacing(1, 2)
    },
    borderBox: {
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(-1)
    },
    layerContainer: {
        paddingInline: theme.spacing(2),
        paddingBlock: theme.spacing(1),
        backgroundColor: "white"
    },
    innerLayerContainer: {
        paddingTop: theme.spacing(1),
        marginLeft: theme.spacing(0.5)
    },
    groupName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        textTransform: "capitalize",
        minWidth: 200,
        fontWeight: "bold"
    },
    subGoupName: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        textTransform: "capitalize",
        minWidth: 200
    },
    layerCount: {
        color: theme.palette.primary.main,
        fontSize: 14
    },
    layerCountSeperator: {
        backgroundColor: theme.customColors.borderColor,
        height: 24,
        width: 1,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1)
    },
    nrOfLayers: {
        borderRadius: 4,
        marginLeft: 8,
        "&:hover": {
            backgroundColor: theme.customColors.appBgColor
        }
    },
    collapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        color: theme.customColors.black,
        marginRight: theme.spacing(2)
    },
    innerCollapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        marginRight: theme.spacing(1),
        color: theme.customColors.black
    },
    grow: {
        flexGrow: 1,
        minWidth: 0
    },
    expandedRight: {
        transform: "rotate(-180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    }
}));

export default useGroupStyles;
