import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useStyles } from "./styles";

import CustomModal from "../../../common/CustomModal/CustomModal";
import { downloadGeotiff } from "../../../../actions/layerSelector";
import { getFetchingGeotiff } from "../../../../selectors/layerSelector";
import toastr from "../../../../utils/customToastr";
import { handleError } from "../../../../utils/networkErrorUtils";
import CustomTypography from "../../../common/CustomTypography/CustomTypography";

import { LinearProgress } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";

const GeotiffDownloader = ({ open, handleClose, layer }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0);

    const dispatch = useDispatch();
    const fetching = useSelector(getFetchingGeotiff);

    const onDownloadGeotiff = () => {
        const config = {
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setDownloadProgressPercentage(percentCompleted);
            }
        };

        const fileName = `${layer.name}.tif`;
        const rasterId = layer.resourceId;

        dispatch(downloadGeotiff({ rasterId, fileName, config }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Geotiff downloaded");
                handleClose();
                setDownloadProgressPercentage(0);
            })
            .catch((err) => {
                handleError(err);
                setDownloadProgressPercentage(0);
            });
    };

    return (
        <CustomModal
            handleClose={handleClose}
            isOpen={open}
            onConfirm={onDownloadGeotiff}
            dialogTitle={"Download"}
            dialogType={"start"}
            disableCancel={fetching}
        >
            {!fetching && (
                <CustomTypography
                    variant="subtitle1"
                    textWeight="semibold"
                    color={theme.customColors.appTextColorLight}
                >
                    {layer.name}.geotiff
                </CustomTypography>
            )}
            {fetching && (
                <div className="progress-page">
                    {downloadProgressPercentage === 0 && (
                        <div>
                            <CustomTypography variant="h6" id="transition-modal-title" className="text">
                                Generating File
                            </CustomTypography>
                            <LinearProgress className={classes.progressBar} variant="indeterminate" />
                        </div>
                    )}
                    {downloadProgressPercentage > 0 && (
                        <div className={classes.downloadContainer}>
                            <CustomTypography
                                variant="h2"
                                textWeight="bold"
                                color={theme.customColors.appTextColorLight}
                            >
                                Downloading {layer.name}
                            </CustomTypography>
                            <CustomTypography variant="body1" className={classes.percentage}>
                                {downloadProgressPercentage}%
                            </CustomTypography>
                            <LinearProgress
                                className={classes.progressBar}
                                variant="determinate"
                                value={downloadProgressPercentage}
                            />
                        </div>
                    )}
                </div>
            )}
        </CustomModal>
    );
};

export default GeotiffDownloader;
