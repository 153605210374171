import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
    tagList: {
        display: "flex",
        flexWrap: "wrap"
    },
    tag: {
        padding: "0 10px",
        borderRadius: "15px",
        display: "flex",
        alignItems: "center",
        marginRight: "4px",
        marginBottom: "4px",
        height: "30px",
        fontSize: "13px"
    }
}));

export default useStyles;
