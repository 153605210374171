import React, { useEffect, useState } from "react";
import { Typography, LinearProgress, Select, InputLabel, FormControl, MenuItem, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import { downloadDataset } from "../../../actions/layerSelector";
import { handleError } from "../../../utils/networkErrorUtils";
import Download from "downloadjs";
import CustomModal from "../../common/CustomModal/CustomModal";
import { unwrapResult } from "@reduxjs/toolkit";

const dataTypes = [
    { name: "GeoJSON", prettyName: "GeoJson" },
    { name: "ESRI Shapefile", prettyName: "Shapefile" },
    { name: "KML", prettyName: "Kml" },
    { name: "GPKG", prettyName: "GeoPackage" },
    { name: "CSV", prettyName: "CSV" },
    { name: "XLSX", prettyName: "MS Excel" }
];

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 450,
        height: 220,
        outline: "none"
    },
    modalHeader: {
        backgroundColor: "#024F79",
        padding: theme.spacing(2),
        color: "#fff",
        textAlign: "center"
    },
    headerText: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff"
    },
    layerName: {
        padding: 5,
        fontSize: "1.25rem",
        color: "#e0e0e0"
    },
    downloadDialogSelectContainer: {
        padding: 20
    },
    downloadDialogSelect: {
        width: "40%"
    },
    modalText: {
        textAlign: "center",
        padding: 20
    },
    layerNameDownloadModal: {
        width: "100%",
        display: "flex",
        fontWeight: "bold"
    }
}));

const DownloadLayer = ({ open, handleClose, layer, featureIds }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [downloading, setDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [dataType, setDataType] = useState(dataTypes[0].name);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        return () => {
            setErrorMessage(null);
        };
    }, [dataType]);

    const onDownload = () => {
        setDownloading(true);

        let config = {
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setDownloadProgress(percentCompleted);
            }
        };
        let fileName = makeFileName(layer.name, dataType);

        dispatch(downloadDataset({ datasetId: layer.resourceId, format: dataType, featureIds, config }))
            .then(unwrapResult)
            .then((res) => {
                Download(res.data, fileName);
                handleClose();
            })
            .catch((err) => {
                handleError(err);
                setErrorMessage(err.message);
            })
            .finally(() => {
                setDownloading(false);
                setDownloadProgress(0);
            });
    };

    const makeFileName = (name, format) => {
        switch (format) {
            case "GeoJSON":
            case "KML":
            case "ESRI Shapefile":
            case "GPKG":
            case "CSV":
            case "XLSX":
                return `${name}.zip`;
            default:
                console.error("Invalid file type");
        }
    };

    const handleCloseFromDownloadLayer = () => {
        handleClose();
        setErrorMessage(null);
    };

    const getDataTestId = (itemName) => {
        const name = itemName.toLocaleLowerCase().replaceAll(" ", "-");
        return downloadItemTestId + name;
    };

    return (
        <CustomModal
            handleClose={handleCloseFromDownloadLayer}
            isOpen={open}
            onConfirm={onDownload}
            dialogTitle={"Download Layer"}
            dialogType={"start"}
            disabled={downloading}
        >
            <Grid container>
                {downloading && (
                    <Grid item xs={12}>
                        {downloadProgress === 0 ? (
                            <LinearProgress variant="indeterminate" />
                        ) : (
                            <LinearProgress variant="determinate" value={downloadProgress} />
                        )}
                    </Grid>
                )}
                {!downloading && (
                    <Grid item xs={12}>
                        <Typography className={classes.layerNameDownloadModal} gutterBottom component="div">
                            {layer.name}
                        </Typography>
                        <FormControl variant="filled" className="section" fullWidth>
                            <InputLabel id="select-type-of-layer">Type of layer</InputLabel>
                            <Select
                                labelId="select-type-of-layer"
                                value={dataType}
                                onChange={(e) => setDataType(e.target.value)}
                                label="Type of layer"
                            >
                                {dataTypes.map((dataType) => (
                                    <MenuItem
                                        key={dataType.name}
                                        value={dataType.name}
                                        data-testid={getDataTestId(dataType.name)}
                                    >
                                        {dataType.prettyName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                {downloading && (
                    <Grid item xs={12}>
                        {downloadProgress === 0 ? (
                            <Typography className={classes.modalText} variant="h6" color="primary">
                                Generating File
                            </Typography>
                        ) : (
                            <Typography className={classes.modalText} variant="h6" color="primary">
                                Downloading... {downloadProgress}%
                            </Typography>
                        )}
                    </Grid>
                )}
                {errorMessage && (
                    <Grid item xs={12}>
                        <Typography className={classes.errorModalText} color="error">
                            {errorMessage}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </CustomModal>
    );
};

export default DownloadLayer;

const downloadItemTestId = "qa-download-";
