import * as yup from "yup";
import { MAX_WEATHER_WINDOW_VALUE, MIN_WEATHER_WINDOW_VALUE } from "../constants";
import { duplicateLimitErrorMessage, maxErrorMessage, minErrorMessage, numberTypeErrorMessage } from "./common";

const weatherWindowsSchema = yup
    .array()
    .of(
        yup
            .number()
            .min(MIN_WEATHER_WINDOW_VALUE, minErrorMessage(MIN_WEATHER_WINDOW_VALUE))
            .max(MAX_WEATHER_WINDOW_VALUE, maxErrorMessage(MAX_WEATHER_WINDOW_VALUE))
            .typeError(numberTypeErrorMessage)
    )
    .unique(duplicateLimitErrorMessage);

export default weatherWindowsSchema;
