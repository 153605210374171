import { useEffect } from "react";
import { useMap } from "@emblautec/react-map-gl";

type Props = {};

const DefaultPermaHandlers = (props: Props) => {
    const { current } = useMap();

    useEffect(() => {
        if (current) {
            current.on("mousedown", (e) => {
                e.target.getCanvas().style.cursor = "move";
            });

            current.on("mousemove", (e) => {
                e.target.getCanvas().style.cursor = "auto";
            });
        }
    }, [current]);

    return null;
};

export default DefaultPermaHandlers;
