import { CircleMode, DragCircleMode, DirectMode, SimpleSelectMode } from "mapbox-gl-draw-circle";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { mapboxDrawStyles } from "../../../utils/mapboxDrawStyles";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";

export const createDraw = () => {
    var modes = {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
        static: StaticMode
    };

    return new MapboxDraw({
        userProperties: true,
        controls: {
            point: false,
            line_string: false,
            polygon: false,
            combine_features: false,
            uncombine_features: false,
            trash: false
        },
        styles: mapboxDrawStyles,
        modes: modes
    });
};
