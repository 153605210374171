import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: "#fff",
        overflow: "auto"
    },
    title: {
        padding: "8px 16px",
        backgroundColor: theme.customColors.appBgColor
    },
    section: {
        margin: "8px 20px",
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)"
    },
    cardTitleText: {
        cursor: "pointer",
        flexGrow: 1
    },
    cardTitleContent: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        marginRight: theme.spacing(1)
    },
    cardBody: {
        gap: 16,
        display: "flex",
        flexDirection: "column"
    },
    legend: {
        padding: "16px 32px"
    },
    legendItem: {
        display: "flex",
        marginBottom: 8,
        alignItems: "center"
    },
    legendText: {
        flexGrow: 1
    },
    legendIcon: {
        height: 24
    }
}));
