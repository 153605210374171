import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    formControl: {
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: theme.spacing(1.5),
        borderLeft: `1px solid ${theme.customColors.borderColor}`

    },
    appsMenu: {
        height: '100%',

        '& .MuiSelect-select': {
            height: '100%',
            paddingRight: theme.spacing(4),

            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },

            '& > div': { height: '100%' },
        },
        '& .MuiSelect-icon': { right: theme.spacing(1) }
    },
    appName: {
        fontSize: "0.785rem"
    },
    apps: {
        overflow: "auto",
        maxHeight: 400
    },
    appAndProject: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: theme.spacing(1),
        padding: "0 8px"
    },
    appAndProjectNames: {
        maxWidth: "85%",
        minWidth: 0,
        marginTop: 2
    },
    overflowText: {
        display: "flex",
        flexGrow: 1,
        marginRight: 20,
        maxWidth: 300
    },
    link: {
        display: "flex",
        width: "100%",
    },
    blueChip: {
        color: theme.customColors.primary3,
        fill: theme.customColors.primary3,
        backgroundColor: "inherit",
        marginRight: -10
    },
    project: {
        padding: "12px 8px 12px 16px",
        display: "flex",
        justifyContent: "space-between",
        fontSize: 13,
        maxWidth: 340,
        gap: 32
    },
    switchProject: {
        color: theme.customColors.primaryColor,
        display: "flex",
        gap: 4
    }
}));
