import { useState, useEffect } from "react";
import { Grid, Typography, Button, Divider, LinearProgress, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import PrintOutput from "./printOutput";
import PrintOptions from "./printOptions";
import MapPreview from "./MapPreview/MapPreview";
import { onPrint } from "../../../utils/printUtils";
import { Close } from "@mui/icons-material";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { usePrintPreviewStyles } from "./styles/printPreviewStyles";
import { useMap } from "@emblautec/react-map-gl";

const PrintPreview = ({ handleClose }) => {
    const classes = usePrintPreviewStyles();
    const { printMap } = useMap();
    const paperSizes = {
        A3: {
            height: 16.5,
            width: 11.7
        },
        A4: {
            height: 11.69291,
            width: 8.26772
        }
    };
    const dpiOptions = [72, 150, 300];
    const printFeatures = useSelector(getPrintFeatures);
    const [printing, setPrinting] = useState(false);
    const [outputType, setOutputType] = useState("image");
    const [dpi, setDpi] = useState(300);
    const [outputSize, setOutputSize] = useState("A4");
    const [orientation, setOrientation] = useState("landscape");
    const [unitType, setUnitType] = useState("inch");
    const [width, setWidth] = useState(paperSizes.A4.width);
    const [height, setHeight] = useState(paperSizes.A4.height);

    useEffect(() => {
        let paperSize = paperSizes[outputSize];
        let newPaperSize = { ...paperSize };

        if (orientation === "landscape") {
            newPaperSize.height = paperSize.width;
            newPaperSize.width = paperSize.height;
        }
        setWidth(newPaperSize.width);
        setHeight(newPaperSize.height);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orientation, outputSize]);

    const onPrintScrn = () => {
        setPrinting(true);
        onPrint(unitType, height, width, dpi, printMap, printFeatures, outputType, orientation, setPrinting);
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleClose}
                className={
                    orientation === "landscape" && printFeatures.showMapLegend
                        ? classes.closeButtonLandscape
                        : classes.closeButton
                }
                size="small"
            >
                <Close />
            </IconButton>
            <div className={classes.settingsContainer}>
                <Grid container>
                    <Grid item xs={12} className={classes.header}>
                        <Grid container className={classes.section}>
                            <Grid item xs={9}>
                                <Grid container justifyContent="flex-start">
                                    <Typography variant="h6">Print Map</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" color="primary" disabled={printing} onClick={onPrintScrn}>
                                    Print
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {printing && <LinearProgress />}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <PrintOutput outputType={outputType} setOutputType={setOutputType} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <PrintOptions
                                dpiOptions={dpiOptions}
                                width={width}
                                setWidth={setWidth}
                                height={height}
                                setHeight={setHeight}
                                unitType={unitType}
                                setUnitType={setUnitType}
                                dpi={dpi}
                                setDpi={setDpi}
                                outputSize={outputSize}
                                setOutputSize={setOutputSize}
                                orientation={orientation}
                                setOrientation={setOrientation}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.printPreviewContainer}>
                <div
                    id="print-preview-container"
                    className={`${
                        orientation === "portrait"
                            ? classes.mapPreviewPortraitContainer
                            : classes.mapPreviewLandscapeContainer
                    }`}
                >
                    <MapPreview orientation={orientation} dpi={dpi} />
                </div>
            </div>
        </div>
    );
};

export default PrintPreview;
