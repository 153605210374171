import { makeStyles } from "@mui/styles";

export const useLegendEntryStyles = makeStyles(() => ({
    labelsContainer: {
        padding: 8,
        overflow: "none"
    },
    iconContainerClass: {
        marginLeft: 10
    },
    singleLabelEntryContainer: {
        display: "flex",
        marginLeft: 8
    },
    multiLabelHeaderText: {
        fontSize: 10,
        textTransform: "capitalize",
        marginLeft: 16
    },
    labelText: {
        flexGrow: 1,
        fontSize: 10,
        maxWidth: "18vw",
        minWidth: 0,
        textTransform: "capitalize"
    },
    multiLabelText: {
        flexGrow: 1,
        fontSize: 10,
        minWidth: 0,
        textTransform: "capitalize",
        lineHeight: "15px"
    },
    dividerSpacing: {
        margin: 8
    },
    multiLabelEntryContainer: {
        display: "flex",
        paddingLeft: 8,
        height: "100%",
        alignItems: "center"
    }
}));
