import MapboxLanguage from "@emblautec/mapbox-gl-language";
import { useEffect, useRef } from "react";
import { useControl, useMap } from "@emblautec/react-map-gl";
import customToastr from "../../../../utils/customToastr";
import LanguageSelector from "../../mapTools/languageSelector";

const MapLanguageControl = ({
    defaultLanguage,
    mapLanguage,
    languages,
    onLanguageChanged,
    setMapStyle,
    position,
    hidden = false
}) => {
    const { current } = useMap();

    const langControl = useControl(() => new MapboxLanguage({ defaultLanguage }), { position });

    const languageOfCurrentStyle = useRef(mapLanguage);

    // Use effect is needed for intercepting changes from logic other than
    // the one inside this component
    useEffect(() => {
        if (mapLanguage !== languageOfCurrentStyle.current) {
            if (current.isStyleLoaded()) {
                changeMapLanguage(mapLanguage);
            } else {
                current.getMap().once("style.load", () => changeMapLanguage(mapLanguage));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapLanguage]);

    const changeMapLanguage = (lang) => {
        const style = current.getStyle();
        const isV8Style = langControl.isVersion8Style(style);

        if (!isV8Style) {
            customToastr.warning("Changing language is not supported for current basemap");
            return;
        }

        const styleWithLanguage = langControl.setLanguage(style, lang);
        langControl._defaultLanguage = lang; // this is a hack needed to make the language stay the same when changing the basemap
        languageOfCurrentStyle.current = lang;
        setMapStyle(styleWithLanguage);
    };

    const changeLanguage = (lang) => {
        changeMapLanguage(lang);
        onLanguageChanged(lang);
    };

    if (!languages) {
        console.error("The languages property in the app config was undefined");
        return null;
    }

    if (languages?.length <= 1 || hidden) return null;

    return (
        <LanguageSelector
            value={mapLanguage}
            languages={languages}
            onChange={changeLanguage}
            fullScreenEl={document.body}
        />
    );
};

export default MapLanguageControl;
