import makeStyles from "@mui/styles/makeStyles";

const useAttributeTableTableHeadStyles = makeStyles((theme: any) => ({
    tableCell: {
        borderRight: "1px solid lightgrey"
    },
    tableCellLabel: {
        fontWeight: "bold"
    }
}));

export default useAttributeTableTableHeadStyles;
