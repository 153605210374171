import { makeStyles } from "tss-react/mui";

export const useErrorPlaceholderStyles = makeStyles()((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "80%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    bottomMargin: {
        marginBottom: 16
    }
}));
