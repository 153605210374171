import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(() => ({
    root: {
        height: "calc(100% - 50px)",
        position: "relative",
        background: "white"
    },
    appBar: {
        boxShadow: "none"
    },
    content: {
        fontSize: 20
    },
    logo: {
        height: 23
    },
    toolbar: {
        backgroundColor: "white",
        alignItems: "center",
        display: "flex",
        paddingLeft: 16,
        paddingRight: 0,
        minHeight: 50,
        height: 50
    }
}));
