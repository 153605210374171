import { Grid } from "@mui/material";
import ZoomLevelWidget from "./ZoomLevelWidget/ZoomLevelWidget";
import { useMapToolsStyles } from "./styles/mapToolsStyles";
import CoordinatesWidget from "features/mapTools/components/CoordinatesWidget/CoordinatesWidget";

const MapTools = () => {
    const classes = useMapToolsStyles();
    return (
        <Grid container alignItems="center" justifyContent="flex-end" spacing={1} className={classes.root}>
            <Grid item>
                <CoordinatesWidget />
            </Grid>
            <Grid item>
                <ZoomLevelWidget />
            </Grid>
        </Grid>
    );
};

export default MapTools;
