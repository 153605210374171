import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import { Basemap } from "model/app/Basemap";
import { Language } from "model/app/Language";
import { Projection } from "model/app/Projection";
import { DEFAULT_PROJECTION } from "./constants";
import { Widget } from "model/tools/Widget";
import { Tool } from "./models/Tool";
import { getApp } from "actions/apps";

type MapToolsState = {
    tools: Tool[];
    widgets: Widget[];
    toggledWidgets: Record<string, any>;
    basemaps: Basemap[];
    languages: Language[];
    projections: Projection[];
    selectedProjection: Projection;
};

const initialState: MapToolsState = {
    tools: [],
    widgets: [],
    toggledWidgets: {},
    basemaps: [],
    languages: [],
    projections: [],
    selectedProjection: DEFAULT_PROJECTION
};

const mapToolsSlice = createSlice({
    name: "mapTools",
    initialState: initialState,
    reducers: {
        setConfig: (state, { payload }: PayloadAction<Partial<MapToolsState>>) => ({ ...state, ...payload }),
        setSelectedProjection: (state, { payload }: PayloadAction<Projection>) => {
            state.selectedProjection = payload;
        },
        toggleWidget: (state, { payload: widgetName }: PayloadAction<string>) => {
            if (state.toggledWidgets.hasOwnProperty(widgetName)) {
                delete state.toggledWidgets[widgetName];
            } else {
                state.toggledWidgets[widgetName] = null;
            }
        },
        toggleWidgetWithArgs: (
            state,
            { payload: { widgetName, args } }: PayloadAction<{ widgetName: string; args: any }>
        ) => {
            if (state.toggledWidgets.hasOwnProperty(widgetName)) {
                delete state.toggledWidgets[widgetName];
            } else {
                state.toggledWidgets[widgetName] = args;
            }
        },
        closeWidgets: (state) => {
            state.toggledWidgets = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetProjectData, () => initialState)
            .addCase(getApp.fulfilled, (state, { payload }) => {
                state.tools = [...payload.tools];
                state.widgets = [...payload.widgets];
            });
    }
});

export const { setConfig, setSelectedProjection, toggleWidget, toggleWidgetWithArgs, closeWidgets } =
    mapToolsSlice.actions;

export default mapToolsSlice.reducer;
