import { makeStyles } from "@mui/styles";

export const useDefaultInfoboxStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: "white",
        zIndex: 4,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        [theme.breakpoints.down("md")]: {
            left: 8,
            bottom: 8,
            right: 8
        }
    },
    features: {
        paddingBottom: theme.spacing(1),
        backgroundColor: "white",
        minWidth: 300,
        minHeight: 100,
        overflowY: "scroll",
        overflowX: "hidden",

        "&::-webkit-scrollbar-track": {
            marginTop: 40,
            borderLeft: "1px solid" + theme.customColors.borderColor + " !important"
        },
    },
    featureHeaderContainer: {
        position: "sticky",
        top: 0,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        backgroundColor: "white",
    },
    featureHeader: {
        display: "flex",
        padding: theme.spacing(1, 0, 1, 2),
        boxSizing: "border-box",
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: "100%",
        overflow: "hidden"
    },
    featureButtonsSingleFeature: {
        flex: "0 0 auto",
        paddingRight: theme.spacing(3.8)
    },
    featureButtonsMultipleFeatures: {
        flex: "0 0 auto",
        paddingRight: theme.spacing(2.5)
    },
    propertyKey: {
        paddingLeft: 0,
        padding: theme.spacing(1),
        textTransform: "capitalize",
        textAlign: "left",
        borderBottom: "1px solid" + theme.customColors.borderColor
    },
    propertyValue: {
        padding: theme.spacing(1, 0),
        wordBreak: "break-word",
        textAlign: "right",
        borderBottom: "1px solid" + theme.customColors.borderColor
    },
    table: {
        tableLayout: "fixed",
        width: `calc(100% - ${theme.spacing(4.5)} )`,
        borderCollapse: "collapse",
        backgroundColor: "white",
        marginInline: theme.spacing(2),

        "& tr:last-of-type td": {
            borderBottom: 0
        }
    },
    squareBtn: {
        paddingTop: 0,
        paddingBottom: 0,
        color: "black",
        backgroundColor: theme.customColors.secondaryColor,
        width: 27,
        height: 30,
        borderRadius: 3,
        marginLeft: theme.spacing(1),

        "&:hover": {
            backgroundColor: "rgba(211, 226, 234, 0.6)"
        }
    },
    noData: {
        paddingLeft: theme.spacing(2),
        padding: theme.spacing(1),
        textTransform: "capitalize",
        fontWeight: "bold",
        textAlign: "left",
        fontSize: 14,

        "&:not(:first-of-type)": {
            display: "none"
        }
    },
    innerCollapseBtn: {
        borderRadius: 0,
        height: 24,
        width: 24,
        marginRight: theme.spacing(1),
        color: theme.customColors.black
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    borderBox: {
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        marginLeft: theme.spacing(3.5),
        marginTop: theme.spacing(1)
    },
    collapseButton: {
        paddingLeft: theme.spacing(2),
        color: theme.customColors.primaryColor
    },
    collapseBtnContainer: {
        color: theme.customColors.primaryColor
    }
}));

export default useDefaultInfoboxStyles;
