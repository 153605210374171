import { makeStyles } from "@mui/styles";

export const useLegendEntryStyles = makeStyles(() => ({
    labelsContainer: {
        marginTop: 4,
        maxHeight: "45vh",
        overflow: "auto"
    },
    singleLabelEntryContainer: {
        display: "flex",
        alignItems: "center",
        padding: 16,
        paddingTop: 12,
        paddingBottom: 8
    },
    multiLabelEntryContainer: {
        display: "flex",
        paddingLeft: 8,
        height: "100%",
        alignItems: "center"
    },
    multiLabelHeaderText: {
        paddingBottom: 4,
        marginLeft: 16,
        fontSize: 14
    },
    labelText: {
        flexGrow: 1,
        marginLeft: 8,
        fontSize: 14,
        textTransform: "capitalize"
    },
    multiLabelText: {
        flexGrow: 1,
        fontSize: 14,
        lineHeight: "14px",
        textTransform: "capitalize"
    },
    dividerSpacing: {
        marginTop: 8,
        marginBottom: 8
    }
}));
