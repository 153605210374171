import config from "config";
import { APPS_VIEW_PATH, ROOT_MAP_VIEW_PATH } from "constants/routes";
import AISDataLoader from "features/ais/components/AISDataLoader/AISDataLoader";
import { BufferWWContextProvider } from "features/buffer/components/BufferWWContextProvider";
import { Redirect, Route, Switch } from "react-router-dom";
import { getFeatureFlags } from "selectors/featureFlagsSelectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import AppsView from "views/AppsView";
import MapView from "views/mapView";

const MainView = () => {
    const featureFlags = useAppSelector(getFeatureFlags);

    return (
        <>
            {featureFlags.AIS && <AISDataLoader />}
            <Switch>
                <Route exact path={APPS_VIEW_PATH} component={AppsView} />
                <Route
                    path={ROOT_MAP_VIEW_PATH}
                    render={(matchProps) => (
                        <BufferWWContextProvider
                            maxNumberOfWorkers={config.bufferOptions.maxNumberOfWebWorkers}
                            appId={matchProps.match.params.appId}
                        >
                            <MapView {...matchProps} />
                        </BufferWWContextProvider>
                    )}
                ></Route>
                <Redirect to={APPS_VIEW_PATH} />
            </Switch>
        </>
    );
};

export default MainView;
