import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import MapIcon from "@mui/icons-material/Map";
import { Radio, FormControlLabel, Typography, Popover, Paper, ListSubheader, List, RadioGroup } from "@mui/material";
import { useBasemapSelectorStyles } from "./styles/basemapSelectorStyles";

const BasemapSelector = ({ basemaps, value, onChange, fullScreenEl }) => {
    const classes = useBasemapSelectorStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "basemap-selector-menu" : undefined;
    const basemapTypes = ["Vector", "Raster"];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onBasemapChanged = (basemap) => {
        onChange(basemap);
    };

    const getBasemapTestId = (basemapTitle) => {
        return basemapRadioButtonTestId + basemapTitle.toLocaleLowerCase().replaceAll(" ", "-");
    };
    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Basemaps">
                <div className={classes.iconContainer} onClick={handleClick} data-testid={basemapButtonTestId}>
                    <MapIcon className={classes.icon} />
                </div>
            </Tooltip>
            <Popover
                id={id}
                open={open}
                container={fullScreenEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                <Paper data-testid={basemapContainerTestId}>
                    <div className={classes.listTitle}>
                        <Typography variant="h6">Basemap</Typography>
                    </div>
                    <List className={classes.list} subheader={<li />}>
                        {basemapTypes.map((basemapType) => (
                            <li key={`section-${basemapType}`} className={classes.sectionContainer}>
                                <ul className={classes.basemapsContainer}>
                                    <ListSubheader>{basemapType}</ListSubheader>
                                    <RadioGroup aria-label="basement selector" name="basement selector" value={value}>
                                        <FormControlLabel
                                            className={classes.radioText}
                                            value="none"
                                            control={
                                                <Radio
                                                    onClick={() => onBasemapChanged({ type: "none" })}
                                                    checked={value.type === "none"}
                                                    data-testid={getBasemapTestId("none")}
                                                />
                                            }
                                            label="None"
                                        />
                                        {basemaps
                                            .filter((basemap) => basemap.type === basemapType.toLocaleLowerCase())
                                            .map((basemap) => (
                                                <FormControlLabel
                                                    key={basemap.title}
                                                    className={classes.radioText}
                                                    value={basemap.title}
                                                    control={
                                                        <Radio
                                                            onClick={() => onBasemapChanged(basemap)}
                                                            checked={basemap.title === value.title}
                                                            data-testid={getBasemapTestId(basemap.title)}
                                                        />
                                                    }
                                                    label={basemap.title}
                                                />
                                            ))}
                                    </RadioGroup>
                                </ul>
                            </li>
                        ))}
                    </List>
                </Paper>
            </Popover>
        </div>
    );
};

export default BasemapSelector;

const basemapButtonTestId = "qa-basemap-selector-basemap-button";
const basemapContainerTestId = "qa-basemap-selector-basemap-container";
const basemapRadioButtonTestId = "qa-basemap-selector-basemap-radio-button-";
