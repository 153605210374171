import { Grid, Divider, Box } from "@mui/material";
import { ReactNode } from "react";
import CustomTypography from "../../common/CustomTypography/CustomTypography";

type Props = {
    xsSpacing: number;
    icon: ReactNode;
    title: string;
    divider: boolean;
    classes: {
        dividerClass: any;
        entryContainerClass: any;
        typographyClass: any;
        iconContainerClass: any;
    };
    typographyProps: any;
};

const LegendEntry = ({ xsSpacing, icon, title, divider, classes, typographyProps }: Props) => {
    return (
        <Grid item xs={xsSpacing}>
            <Box className={classes.entryContainerClass}>
                <Box pr={1} display="flex" alignItems="center" className={classes.iconContainerClass}>
                    {icon}
                </Box>
                <CustomTypography
                    textOverflow="ellipsis"
                    overflow="hidden"
                    variant="subtitle1"
                    textWeight="semibold"
                    className={classes.typographyClass}
                    {...typographyProps}
                >
                    {title}
                </CustomTypography>
            </Box>

            {divider && <Divider className={classes.dividerClass} />}
        </Grid>
    );
};

export default LegendEntry;
