import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import Bootstrap from "Bootstrap";
import PublicBootstrap from "PublicBootstrap";
import { MsalErrorComponent } from "app/components/MsalComponents/MsalErrorComponent";
import { MsalLoaderComponent } from "app/components/MsalComponents/MsalLoaderComponent";
import config from "config";
import "@emblautec/mapbox-gl/dist/mapbox-gl.css";
import { FC } from "react";
import ReduxToastr from "react-redux-toastr";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorView from "views/error/ErrorView";
import LandingView from "views/landing/LandingView";
import MainView from "views/main/MainView";
import MapView from "views/mapView";
import "./App.css";
import "./scss/main.scss";
import * as yup from "yup";
import SignOutView from "views/SignOutView/SignOutView";
require("@emblautec/rescursive-array-extensions");

const App: FC = () => (
    <div className="App">
        <Switch>
            <Route exact path="/:clientId/:projectId/error">
                <ErrorView />
            </Route>
            <Route
                path="/:clientId/:projectId/public/:appId/map"
                render={(matchProps) => (
                    <PublicBootstrap>
                        <MapView {...matchProps} />
                    </PublicBootstrap>
                )}
            ></Route>
            <Route path="/:clientId/:projectId">
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={config.loginRequest}
                    errorComponent={MsalErrorComponent}
                    loadingComponent={MsalLoaderComponent}
                >
                    <Bootstrap>
                        <MainView />
                    </Bootstrap>
                </MsalAuthenticationTemplate>
            </Route>
            <Route exact path="/sign-out">
                <SignOutView />
            </Route>
            <Route exact path="/">
                <LandingView />
            </Route>
            <Redirect to="/" />
        </Switch>
        <ReduxToastr
            timeOut={4000}
            newestOnTop={true}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
            progressBar
        />
    </div>
);

export default App;
