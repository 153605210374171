import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";
import { fromNowWithCheck } from "../../../utils/timeUtils";
import ReadOnlyField from "../readOnlyField/readOnlyField";

export const FromNow = ({ interval, label, date, tooltip, tooltipDateFormat = "LLL" }) => {
    const [text, setText] = useState(fromNowWithCheck(dayjs(date)));

    useEffect(() => {
        const intervalId = setInterval(() => {
            setText(fromNowWithCheck(dayjs(date)));
        }, interval);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    return (
        <ReadOnlyField
            label={label}
            text={text}
            tooltip={tooltip}
            tooltipTitle={dayjs(date).format(tooltipDateFormat)}
        />
    );
};
