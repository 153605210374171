import AddIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Chip, TextField } from "@mui/material";
import { MAX_LIMITS } from "features/zander/constants";
import { useState } from "react";
import toastr from "utils/customToastr";
import { useLimitsCreatorStyles } from "./styles";

type Limit = {
    value: number;
    error: boolean;
};

type Props = {
    limits: Limit[];
    onLimitsChanged: (limits: number[]) => void;
    validationSchema: any;
    text: string;
    displayDecimals?: boolean;
};

const LimitsCreator = ({ limits, onLimitsChanged, validationSchema, text, displayDecimals = false }: Props) => {
    const [limitValue, setLimitValue] = useState<number | null>(null);
    const [error, setError] = useState(false);
    const classes = useLimitsCreatorStyles();

    const shouldRenderInputField = limits.length < MAX_LIMITS;

    const onLimitRemove = (limit: Limit) =>
        onLimitsChanged(limits.filter((x) => x.value !== limit.value).map((x) => x.value));

    const onAddLimit = () => {
        if (!limitValue) return;

        const newLimits = [...limits.map((l) => l.value), limitValue];
        newLimits.sort((a, b) => a - b);

        validationSchema
            .validate(newLimits)
            .then((newLimits: number[]) => {
                onLimitsChanged(newLimits);
            })
            .catch((error: any) => {
                setError(true);
                toastr.error(error.errors.join("\n"));
            });
    };

    const onLimitKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            onAddLimit();
        }
    };

    const onLimitValueChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLimitValue(parseFloat(e.target.value));
        setError(false);
    };

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const limitToChip = (limit: Limit, index: number) => {
        const label = displayDecimals ? limit.value.toFixed(2) : limit.value;

        return (
            <Chip
                key={index}
                label={label}
                variant="outlined"
                className={`${classes.chip} ${limit.error ? classes.chipError : ""}`}
                size="small"
                onDelete={() => onLimitRemove(limit)}
                deleteIcon={<CloseIcon />}
            />
        );
    };

    const chipTextFieldInputProps = {
        classes: {
            input: classes.percentilesInput
        }
    };

    return (
        <div className={classes.limitCreator}>
            {limits.map((limit, index) => limitToChip(limit, index))}
            <div className={classes.grow}></div>
            {shouldRenderInputField && (
                <>
                    <TextField
                        draggable
                        variant="outlined"
                        size="small"
                        className={classes.inputChipTextField}
                        InputProps={chipTextFieldInputProps}
                        error={error}
                        onDragStart={onDragStart}
                        onChange={onLimitValueChanged}
                        onKeyUp={onLimitKeyUp}
                    />
                    <Button variant="text" color="primary" disabled={limitValue === null} onClick={onAddLimit}>
                        <AddIcon className={classes.icon} fontSize="small" />
                        {text}
                    </Button>
                </>
            )}
        </div>
    );
};

export default LimitsCreator;
