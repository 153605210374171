import axiosClient from "./apiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSubscriptions } from "./ais";
import { fetchInfoboxes } from "features/infobox/actions";

export const getFeatureFlags = createAsyncThunk("getFeatureFlags", async () => {
    return axiosClient.get(`feature`).then((res) => res.data);
});

export const initFlaggedFeatures = createAsyncThunk<any, boolean | undefined>(
    "initFlaggedFeatures",
    async (isPublic = false, { dispatch }) => {
        const featureFlags = (await dispatch(getFeatureFlags())).payload;

        if (featureFlags.AIS) {
            dispatch(getSubscriptions());
        }

        if (featureFlags.INFOBOX) {
            dispatch(fetchInfoboxes(isPublic));
        }
    }
);
