import React from "react";
import Popover from "@mui/material/Popover";
import { ChromePicker } from "react-color";

function SimplePopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [color, setColor] = React.useState(props.color);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : null;

    return (
        <div>
            <div className="color-container" style={{ backgroundColor: props.color }} onClick={handleClick}></div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: { marginLeft: 8, overflowY: "hidden", userSelect: "none" }
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left"
                }}
            >
                <ChromePicker
                    color={color}
                    onChangeComplete={props.onColorChanged}
                    onChange={(color) => setColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)}
                />
            </Popover>
        </div>
    );
}

export default SimplePopover;
