import { useState } from "react";
import { useFetchDatasetPropertiesQuery, useFetchDatasetRowCountQuery } from "../../../api";

const useAttributeTableData = (datasetId: string) => {
    const [text, setText] = useState("");
    const [rows, setRows] = useState(10);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState("ogc_fid");
    const [desc, setDesc] = useState(false);

    const { data, isFetching, isError, error, refetch } = useFetchDatasetPropertiesQuery({
        datasetId,
        searchText: text,
        page,
        rowsPerPage: rows,
        orderBy: order,
        orderByDescending: desc
    });

    const {
        data: totalRowCount,
        isFetching: isCountFetching,
        isError: isCountError,
        error: countError,
        refetch: refetchRowCount
    } = useFetchDatasetRowCountQuery({ datasetId, searchText: text });

    const maxPage = Math.ceil((totalRowCount ?? 0) / rows) - 1;
    const hasNextPage = page < maxPage;
    const hasPrevPage = page > 0;

    // keep next page in cache
    useFetchDatasetPropertiesQuery(
        {
            datasetId,
            searchText: text,
            page: page + 1,
            rowsPerPage: rows,
            orderBy: order,
            orderByDescending: desc
        },
        { skip: !hasNextPage }
    );

    // keep previous page in cache
    useFetchDatasetPropertiesQuery(
        {
            datasetId,
            searchText: text,
            page: page - 1,
            rowsPerPage: rows,
            orderBy: order,
            orderByDescending: desc
        },
        { skip: !hasPrevPage }
    );

    const onOrderBy = (column: string) => {
        if (column === order) {
            setPage(0);
            setDesc(!desc);
        } else {
            setOrder(column);
            setPage(0);
            setDesc(false);
        }
    };

    const onSearch = (searchText: string) => {
        setText(searchText);
        setPage(0);
    };

    return {
        searchText: text,
        setSearchText: onSearch,
        rowsPerPage: rows,
        setRowsPerPage: (rowsPerPage: number) => setRows(rowsPerPage),
        tablePage: page,
        setTablePage: (tablePage: number) => setPage(tablePage),
        orderBy: order,
        setOrderBy: onOrderBy,
        orderByDesc: desc,
        data,
        totalRowCount,
        isFetching: isFetching || isCountFetching,
        isError: isError || isCountError,
        error: error ?? countError,
        refetch: () => {
            refetch();
            refetchRowCount();
        }
    };
};

export default useAttributeTableData;
