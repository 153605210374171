import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    logo: {
        height: 23
    },
    link: {
        display: "flex"
    }
}));
