import makeStyles from "@mui/styles/makeStyles";

export const useCoordinatesSearchStyles = makeStyles((theme: any) => ({
    root: {
        position: "absolute",
        top: 16,
        left: 16,
        zIndex: 2,
        minWidth: 300,
        borderRadius: 4,
        backgroundColor: "#fff"
    },
    header: {
        padding: 8,
        paddingLeft: 16,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f7f8",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    headerText: {
        flexGrow: 1
    },
    content: {
        padding: 16
    },
    searchText: {
        padding: 16
    },
    searchError: {
        fontSize: 10,
        color: "red"
    },
    searchContainer: {
        padding: "8px 16px"
    },
    squareBtn: {
        borderRadius: 0,
        padding: 0
    },
    "squareBtn:active:focus": {
        borderRadius: 0,
        padding: 0
    },
    closeBtn: {
        background: "#F3F3F3"
    }
}));
