import { makeStyles } from "@mui/styles";

const useZanderSheetStyles = makeStyles((theme: any) => ({
    spacingBottom: {
        paddingBottom: 16
    },
    spacingTop: {
        paddingTop: 16
    },
    percentiles: {
        justifyContent: "center"
    },
    sliderContainer: {
        display: "flex",
        justifyContent: "center"
    },
    slider: {
        width: "95%",
        paddingBottom: 20
    },
    sheet: {
        padding: 20
    },
    sectionCard: {
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",
        padding: 20,
        marginBottom: 16,
        borderRadius: 8
    }
}));

export default useZanderSheetStyles;
