import { DatasetProperties } from "../../../models/DatasetProperties";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Checkbox } from "@mui/material";
import useAttributeRowStyles from "./styles";
import Linkify from "react-linkify";
import OverflowTip from "../../../../../components/common/OverflowTip/OverflowTip";
import React, { MouseEvent } from "react";
import toastr from "../../../../../utils/customToastr";

type Props = {
    feature: Omit<DatasetProperties, "bounding_box">;
    isDownloadable: boolean;
    onFeatureCheckToggle: () => void;
    onRowClick: () => void;
    isChecked: boolean;
    key: string;
    label: string;
};

const AttributeRow = ({ feature, isDownloadable, isChecked, onFeatureCheckToggle, onRowClick, label }: Props) => {
    const classes = useAttributeRowStyles();

    const onUrlClick = (e: React.MouseEvent<HTMLAnchorElement> | undefined, url: string | number) => {
        if (typeof url === "string" && url.includes("http")) {
            e?.stopPropagation();
            toastr.info("URL copied to clipboard.");
            navigator.clipboard.writeText(url);
        }
    };

    const onClick = (e: MouseEvent<HTMLTableRowElement>) => {
        e.stopPropagation();
        onRowClick();
    };

    const onCheckBoxClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onFeatureCheckToggle();
    };

    return (
        <TableRow
            onClick={onClick}
            hover
            aria-checked={isChecked}
            tabIndex={-1}
            selected={isChecked}
            data-testid={attributeRowTestId}
        >
            <TableCell padding="checkbox">
                <Checkbox
                    onClick={onCheckBoxClick}
                    disabled={!isDownloadable}
                    checked={isChecked}
                    inputProps={{
                        "aria-labelledby": label
                    }}
                    data-testid={checkboxTestId}
                />
            </TableCell>
            {Object.keys(feature).map((featureName) => (
                <TableCell
                    align={typeof feature[featureName] === "number" ? "right" : "left"}
                    key={featureName}
                    className={classes.cell}
                >
                    <OverflowTip variant="body1">
                        <Linkify
                            componentDecorator={(_, decoratedText, key) => (
                                <a
                                    onClick={(e) => onUrlClick(e, feature[featureName])}
                                    href="javascript:;"
                                    key={key}
                                    data-testid={linkTestId}
                                >
                                    {decoratedText}
                                </a>
                            )}
                        >
                            {feature[featureName]}
                        </Linkify>
                    </OverflowTip>
                </TableCell>
            ))}
        </TableRow>
    );
};

export default AttributeRow;

const attributeRowTestId = "qa-attribute-table-row";
const checkboxTestId = "qa-attribute-table-checkbox";
const linkTestId = "qa-attribute-table-link";
