import useAttributeTableTableHeadStyles from "./styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

type Props = {
    isDownloadable: boolean;
    areAllRowsSelected: boolean;
    isAtLeastOneRowSelected: boolean;
    onToggleSelectAll: () => void;
    featureNames: { name: string; isNumeric: boolean }[];
    orderBy: string;
    orderDesc: boolean;
    onSortBy: (column: string) => void;
};

const AttributeTableTableHead = ({
    isDownloadable,
    areAllRowsSelected,
    isAtLeastOneRowSelected,
    onToggleSelectAll,
    featureNames,
    orderBy,
    orderDesc,
    onSortBy
}: Props) => {
    const classes = useAttributeTableTableHeadStyles();
    const orderName = orderDesc ? "desc" : "asc";

    const getDataTestId = (column: string) => {
        const columnName = column.toLocaleLowerCase().replaceAll(" ", "-");

        return columnTestId + columnName;
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={!isDownloadable}
                        indeterminate={isAtLeastOneRowSelected && !areAllRowsSelected}
                        checked={areAllRowsSelected}
                        onClick={onToggleSelectAll}
                        inputProps={
                            { "aria-label": "select all", "data-testid": checkboxAllTestId } as DataTestIdCheckboxProps
                        }
                    />
                </TableCell>
                {featureNames.map((feature) => (
                    <TableCell
                        className={classes.tableCell}
                        key={feature.name}
                        align={"center"}
                        padding="normal"
                        sortDirection={orderBy === feature.name ? orderName : false}
                    >
                        <TableSortLabel
                            active={orderBy === feature.name}
                            direction={orderBy === feature.name ? orderName : "asc"}
                            onClick={() => onSortBy(feature.name)}
                            className={classes.tableCellLabel}
                            data-testid={getDataTestId(feature.name)}
                        >
                            {feature.name}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default AttributeTableTableHead;

const columnTestId = "qa-attribute-table-head-column-";
const checkboxAllTestId = "qa-attribute-table-checkbox-all";

type DataTestIdCheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
    "data-testid"?: string;
};
