import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import { getSubscriptions } from "../actions/ais";
import { clearMap, resetProjectData } from "../actions/globalActions";
import { AIS } from "../components/sidebar/ais/utils";
import { Boat } from "../model/ais/Boat";
import { Subscription } from "../model/ais/Subscription";

export type SubscriptionDataEntry = {
    boats: Boat[];
    visibility: "none" | "visible";
};

type SliceState = {
    subscriptionData: Record<string, SubscriptionDataEntry>;
    subscriptionInfo: Subscription[];
    loadingInfo: boolean;
};

const initialState: SliceState = {
    subscriptionData: {},
    subscriptionInfo: [],
    loadingInfo: false
};

export const aisSlice = createSlice({
    name: AIS,
    initialState,
    reducers: {
        setBoats: (state, { payload }) => {
            state.subscriptionData[payload.subscriptionId] = {
                ...state.subscriptionData[payload.subscriptionId],
                boats: payload.boats
            };
            state.subscriptionInfo = state.subscriptionInfo.map((sub) => {
                if (sub.id !== payload.subscriptionId) return sub;
                if (!payload.latestUpdateUtc) return sub;
                return {
                    ...sub,
                    latestUpdateUtc: payload.latestUpdateUtc[0]
                };
            });
        },
        setSubVisibility: (state, { payload: { subscriptionId, isVisible } }) => {
            state.subscriptionData[subscriptionId].visibility = isVisible ? "visible" : "none";
        },

        hideAllSubs: (state) => {
            hideAll(state);
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(getSubscriptions.fulfilled, (state, { payload: subscriptions }) => {
                state.subscriptionInfo = subscriptions;
                state.loadingInfo = false;
            })
            .addCase(getSubscriptions.pending, (state) => {
                state.loadingInfo = true;
            })
            .addCase(clearMap.type, (state) => {
                hideAll(state);
            })
            .addCase(resetProjectData, () => initialState)
});

const hideAll = (state: WritableDraft<SliceState>) => {
    Object.keys(state.subscriptionData).forEach((subId) => {
        state.subscriptionData[subId].visibility = "none";
    });
};

export const { setBoats, setSubVisibility, hideAllSubs } = aisSlice.actions;

export default aisSlice.reducer;
