import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton } from "@mui/material";
import { FilterList, KeyboardBackspace, Palette } from "@mui/icons-material";
import * as datasetActions from "../../../actions/atlasClient";
import * as layerSelectorActions from "../../../reducers/layerSelector";
import * as mapActions from "../../../reducers/map";
import * as GeometryUtils from "../../../utils/GeometryUtils";
import { v4 as generateUuid } from "uuid";
import { getFiltersByLayerIdWithExpectedType } from "../../../selectors/mapSelectors";

import { LayerStyleEditor, LayerFilterEditor } from "@emblautec/layer-styler-component";

import { getSourceMinZoom } from "../../../selectors/mapSelectors";
import { getLayerForStyling, getLayerStyles, getLayerVisibility } from "../../../selectors/layerSelector";
import { StyledTab, StyledTabs } from "../../common/customTabs/customTabs";
import OverflowTip from "../../common/OverflowTip/OverflowTip";
import { getStyleConfig } from "../../../selectors/stylesSelectors";
import { STYLE_TYPES } from "../../../constants/layers/styleTypes";
import { makeVisibilityProperty } from "../../../utils/creators/styleProperties/visibilityPropertyCreator";
import { Operators } from "model/map/LayerFilterType";
import { getShouldFilter } from "../../../selectors/layerSelector";
import { getFeatureFlags } from "selectors/featureFlagsSelectors";
import { SourceType } from "model/enums/SourceType";
import { setLayerIsFiltered } from "../../../reducers/layerSelector";
import { useLayerSelectorStylerStyle } from "./styles";
import { useFetchDprDatasetQuery } from "features/dprIntegration/api";

const CHARACTER_VARYING = "character varying";
const DOUBLE_PRECISION = "double precision";
const STYLE_TAB = "style";
const FILTER_TAB = "filter";

const LayerEditor = ({ isDigitizeLayer }) => {
    const classes = useLayerSelectorStylerStyle();

    const [datasetColumns, setDatasetColumns] = useState([]);
    const [selectedDatasetColumn, setSelectedDatasetColumn] = useState(null);
    const [distinctColumnValues, setDistinctColumnValues] = useState([]);
    const [minMaxColumnValues, setMinMaxColumnValues] = useState([]); //[min,max]

    const shouldFilter = useSelector(getShouldFilter);

    const [tab, setTab] = useState(shouldFilter ? FILTER_TAB : STYLE_TAB);

    const layer = useSelector(getLayerForStyling);
    const styles = useSelector(getLayerStyles(layer.resourceId));
    const sourceMinZoomLimit = useSelector(getSourceMinZoom(layer.sourceId));
    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const styleConfig = useSelector(getStyleConfig);

    const featureFlags = useSelector(getFeatureFlags);

    const filters = useSelector(getFiltersByLayerIdWithExpectedType(layer.resourceId));

    const { data: dprDataset = null } = useFetchDprDatasetQuery(layer.resourceId, {
        skip: !featureFlags.DPR || layer.type !== "vector" || layer.isBuffer
    });

    const dispatch = useDispatch();

    useEffect(() => {
        //Based on the selected layer we bring the dataset columns and by default select the first column
        if (Object.keys(layer).length === 0) {
            setDatasetColumns([]);
            setSelectedDatasetColumn(null);
            setDistinctColumnValues([]);
            setMinMaxColumnValues([]);
        }

        if (layer && layer.resourceId && layer.type !== SourceType.Raster && !layer.isBuffer) {
            dispatch(datasetActions.getDatasetColumns(layer.resourceId)).then((res) => {
                setDatasetColumns(res.result);
                setSelectedDatasetColumn(res.result[0]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer.resourceId]);

    useEffect(() => {
        //Based on the selected column we bring the distinct values and min,max pair
        if (selectedDatasetColumn) {
            const columnName = selectedDatasetColumn.name;
            const selectedDatasetId = layer.resourceId;
            if (selectedDatasetColumn.type !== DOUBLE_PRECISION) {
                dispatch(datasetActions.getDistinctColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setDistinctColumnValues(res.result);
                });
            }
            if (selectedDatasetColumn.type !== CHARACTER_VARYING) {
                dispatch(datasetActions.getMinMaxColumnValues(selectedDatasetId, columnName)).then((res) => {
                    setMinMaxColumnValues([res.result.minValue, res.result.maxValue]);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDatasetColumn]);

    const clearEditView = () => {
        dispatch(layerSelectorActions.setStylerLayerId(null));
    };

    const initializeNewStyle = (layerId, properties) => {
        const paintProperties = properties.filter((x) => x.type === "paint");

        const layoutProperties = properties.filter((x) => x.type === "layout");
        layoutProperties.push(makeVisibilityProperty(visible));

        dispatch(mapActions.addMapPaint({ layerId, properties: paintProperties }));
        dispatch(mapActions.addMapLayout({ layerId, properties: layoutProperties }));
    };

    const getStyleConfigWithAutoSelectDprMilestones = (type) => {
        if (!dprDataset) return styleConfig;

        if (type !== "box-chart" && type !== "pie-chart") return styleConfig;

        const milestoneNames = dprDataset.milestones.map((x) => x.displayName);

        const getValue = (name, value) => {
            switch (name) {
                case `${type}-labels`:
                    return milestoneNames;
                case `${type}-colors`:
                    return value.slice(0, milestoneNames.length);
                case `${type}-mask`:
                    return ["get", dprDataset.progressColumn.name];
                default:
                    return value;
            }
        };

        return {
            ...styleConfig,
            [type]: styleConfig[type].map((x) => ({
                ...x,
                value: getValue(x.name, x.value)
            }))
        };
    };

    const onStyleTypeChanged = (type, style) => {
        const styleConfig = getStyleConfigWithAutoSelectDprMilestones(type);

        const properties = JSON.parse(JSON.stringify(styleConfig[type]));
        dispatch(
            mapActions.updateMapLayer({
                sourceId: layer.sourceId,
                layerId: style.styleId,
                resourceId: layer.resourceId,
                sourceName: layer.sourceName,
                type
            })
        );
        initializeNewStyle(style.styleId, properties);

        dispatch(
            layerSelectorActions.changeTypeOfLayerStyle({
                layerId: layer.resourceId,
                styleId: style.styleId,
                type,
                properties
            })
        );
    };

    const onPropertyChanged = (styleId, property) => {
        dispatch(
            mapActions.updateMapProperty({
                layerId: styleId,
                property
            })
        );

        dispatch(
            layerSelectorActions.changePropertyOfLayerStyle({
                layerId: layer.resourceId,
                styleId,
                property
            })
        );
    };

    const onPropertiesChanged = (style, newProperties) => {
        //A more efficient implementeation will take into account this layer prop when searching for the style in the reducer.
        const layerId = style.styleId;

        dispatch(
            mapActions.updateMapPaint({
                layerId,
                properties: newProperties.filter((x) => x.type === "paint")
            })
        );

        dispatch(
            mapActions.updateMapLayout({
                layerId,
                properties: [...newProperties.filter((x) => x.type === "layout"), makeVisibilityProperty(visible)]
            })
        );

        dispatch(
            layerSelectorActions.changePropertiesOfLayerStyle({
                layerId: layer.resourceId,
                styleId: style.styleId,
                properties: newProperties
            })
        );
    };

    const onAddStyleToLayer = (symbolStyle = false) => {
        if (layer.type === STYLE_TYPES.raster) addStyleToRasterLayer();
        else addStyleToVectorLayer(symbolStyle);
    };

    const addStyleToVectorLayer = (symbolStyle) => {
        const styleType = symbolStyle ? STYLE_TYPES.symbol : GeometryUtils.geometryTypeToMapboxType(layer.geometryType);

        const properties = styleConfig[styleType];
        const layerId = generateUuid();

        initializeNewStyle(layerId, properties);
        dispatch(mapActions.addMapZoomRange({ layerId, minZoom: sourceMinZoomLimit, maxZoom: 24 }));

        dispatch(
            mapActions.addMapLayer({
                newLayer: {
                    sourceId: layer.sourceId,
                    layerId: layerId,
                    resourceId: layer.resourceId,
                    sourceName: layer.sourceName,
                    type: styleType
                },
                targetResourceId: layer.resourceId
            })
        );

        const style = {
            styleId: layerId,
            properties,
            type: styleType,
            minZoom: 0,
            maxZoom: 24
        };
        dispatch(layerSelectorActions.addStyleToLayer({ layerId: layer.resourceId, style }));
    };

    const addStyleToRasterLayer = () => {
        const layerId = generateUuid();
        const properties = styleConfig[STYLE_TYPES.raster];

        dispatch(
            mapActions.addMapLayer({
                newLayer: {
                    sourceId: layer.sourceId,
                    layerId: layerId,
                    resourceId: layer.resourceId,
                    sourceName: layer.sourceName,
                    type: SourceType.Raster
                },
                targetResourceId: layer.resourceId
            })
        );
        initializeNewStyle(layerId, properties);
        dispatch(mapActions.addMapZoomRange({ layerId, minZoom: 0, maxZoom: 24 }));

        const style = {
            styleId: layerId,
            properties,
            type: SourceType.Raster,
            minZoom: 0,
            maxZoom: 24
        };
        dispatch(layerSelectorActions.addStyleToLayer({ layerId: layer.resourceId, style }));
    };

    const onZoomSliderChange = (style, zoom) => {
        dispatch(mapActions.updateMapZoomRange({ layerId: style.styleId, minZoom: zoom[0], maxZoom: zoom[1] }));
        dispatch(
            layerSelectorActions.updateStyleZoomRange({
                layerId: layer.resourceId,
                styleId: style.styleId,
                minZoom: zoom[0],
                maxZoom: zoom[1]
            })
        );
    };

    const onRemoveStyleFromLayer = (styleLayer) => {
        dispatch(mapActions.removeMapLayer(styleLayer.styleId));
        dispatch(layerSelectorActions.removeStyleFromLayer({ layerId: layer.resourceId, styleId: styleLayer.styleId }));
    };

    const onStyleDrop = (styleId, beforeStyleId) => {
        dispatch(
            layerSelectorActions.changeStyleOrder({
                layerId: layer.resourceId,
                styleId,
                beforeStyleId
            })
        );
        dispatch(
            mapActions.moveLayer({
                layerId: styleId,
                beforeLayerId: beforeStyleId
            })
        );
    };

    const onFiltersUpdate = (filters) => {
        const formattedFilters = filters.map((filter) => {
            if (filter.operatorName === Operators.Between) {
                return {
                    type: filter.type,
                    operatorName: filter.operatorName,
                    fieldName: filter.fieldName,
                    value: filter.value,
                    secondValue: filter.secondValue,
                    applied: true
                };
            } else {
                return {
                    type: filter.type,
                    operatorName: filter.operatorName,
                    fieldName: filter.fieldName,
                    value: filter.value,
                    applied: true
                };
            }
        });
        dispatch(mapActions.setFilters({ filters: formattedFilters, layerId: layer.resourceId }));
        dispatch(setLayerIsFiltered({ layerResourceId: layer.resourceId, value: filters.length > 0 }));
    };

    return (
        <div className="edit-view-container">
            <Grid className={classes.header} container wrap="nowrap" alignItems="center">
                <IconButton onClick={clearEditView} size="large">
                    <KeyboardBackspace className={classes.backIcon} />
                </IconButton>
                <OverflowTip variant="h2">{layer.name}</OverflowTip>
            </Grid>

            <div className={classes.stylerTabs}>
                <StyledTabs value={tab} TabIndicatorProps={<div />}>
                    <StyledTab
                        label={<div>Style</div>}
                        icon={<Palette />}
                        value={STYLE_TAB}
                        onClick={() => setTab(STYLE_TAB)}
                    />
                    {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && !layer.isBuffer && (
                        <StyledTab
                            label={<div>Filter</div>}
                            icon={<FilterList />}
                            value={FILTER_TAB}
                            onClick={() => setTab(FILTER_TAB)}
                        />
                    )}
                </StyledTabs>
            </div>

            {tab === STYLE_TAB && (
                <div className={classes.stylerDivWrapper}>
                    <LayerStyleEditor
                        layer={{ ...layer, styles: styles.map((style, index) => ({ ...style, index })) }}
                        isDigitizeLayer={isDigitizeLayer}
                        styleConfig={styleConfig}
                        //column stuff
                        minZoomLimit={sourceMinZoomLimit ?? 0}
                        columns={datasetColumns}
                        selectedDatasetColumn={selectedDatasetColumn}
                        setSelectedDatasetColumn={setSelectedDatasetColumn}
                        distinctColumnValues={distinctColumnValues}
                        minMaxColumnValues={minMaxColumnValues}
                        //onHandlers
                        onPropertyChanged={onPropertyChanged}
                        onPropertiesChanged={onPropertiesChanged}
                        onPropertyExpressionTypeChanged={onStyleTypeChanged}
                        onTypeChanged={onStyleTypeChanged}
                        onZoomSliderChange={onZoomSliderChange}
                        onAddStyleToLayer={onAddStyleToLayer}
                        onRemoveStyleFromLayer={onRemoveStyleFromLayer}
                        onStyleDrop={onStyleDrop}
                    />
                </div>
            )}
            {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && tab === FILTER_TAB && (
                <div className={classes.stylerDivWrapper}>
                    <LayerFilterEditor
                        columns={datasetColumns}
                        initialFiltersList={filters}
                        onFiltersUpdate={onFiltersUpdate}
                    />
                </div>
            )}
        </div>
    );
};

export default LayerEditor;
