import { makeStyles } from "@mui/styles";

const useZanderTabsStyles = makeStyles((theme: any) => ({
    closeTabIcon: {
        display: "none",
        position: "absolute",
        right: -2,
        marginTop: -8,
        cursor: "pointer",
        color: theme.customColors.mutedColor,
        "&:hover": {
            color: theme.customColors.appTextColor
        }
    },
    activeTab: {
        cursor: "unset"
    },
    tabLabel: {
        display: "flex",
        fontSize: 600
    },
    closeIconVisible: {
        display: "initial"
    }
}));

export default useZanderTabsStyles;
