import { DefaultTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

const desiredLegendSize = 24;
const lineSize = 3;

// Since the border width also contributes to the actual width we need to substract it
const polygonSize = desiredLegendSize - lineSize * 2;

const pointSize = 6.5;

const pointCornerOffset = -lineSize - 2.5; // In order to avoid weird overlaps

export const useStyles = makeStyles<DefaultTheme, { fillColor?: string; lineColor?: string; circleColor?: string }>(
    () => ({
        legend: {
            width: polygonSize,
            height: polygonSize,
            position: "relative"
        },
        fill: (props) => ({
            backgroundColor: props.fillColor,
            borderColor: props.fillColor,
            borderStyle: "solid",
            borderWidth: lineSize
        }),
        fillBorder: (props) => ({
            borderColor: props.fillColor,
            borderStyle: "solid",
            borderWidth: lineSize
        }),
        line: (props) => ({
            borderColor: props.lineColor,
            borderStyle: "solid",
            borderWidth: lineSize
        }),
        circle: (props) => ({
            height: pointSize,
            width: pointSize,
            position: "absolute",
            backgroundColor: props.circleColor,
            borderRadius: 100
        }),
        rt: {
            top: pointCornerOffset,
            right: pointCornerOffset
        },
        rb: {
            bottom: pointCornerOffset,
            right: pointCornerOffset
        },
        lb: {
            bottom: pointCornerOffset,
            left: pointCornerOffset
        },
        lt: {
            top: pointCornerOffset,
            left: pointCornerOffset
        }
    })
);
