import Config from '../config';
import CryptoJS from 'crypto-js';

export const decryptId = (encryptedId: string) => {
    return decryptByDES(encryptedId.replaceAll("_", "/").replaceAll("-", "=").replaceAll(".", "+"), Config.cryptoUtilsPass);
};

function decryptByDES(ciphertext: string, key: string) {
    
    var keyHex = CryptoJS.enc.Latin1.parse(key);
    var params = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(ciphertext) })

    // direct decrypt ciphertext
    var decrypted = CryptoJS.DES.decrypt(params, keyHex, {
        mode: CryptoJS.mode.CBC,
        iv:keyHex,
    });

    const result = decrypted.toString(CryptoJS.enc.Utf8);

    return parseInt(result);
}
