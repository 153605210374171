import makeStyles from "@mui/styles/makeStyles";

const useContextMenuStyles = makeStyles((theme) => ({
    customMenuStyle: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            paddingTop: 24,
            marginTop: -32,
            marginLeft: -8
        },
        "& .MuiList-root": {
            backgroundColor: "white",
            margin: 8,
            borderRadius: 4,
            boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0 , 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)"
        }
    }
}));

export default useContextMenuStyles;
