import { isGroup } from "@emblautec/rescursive-array-extensions";
import React, { useCallback, useEffect, useRef } from "react";
import { List, AutoSizer } from "react-virtualized";

import Group from "../components/sidebar/layerSelector/group";
import Layer from "../components/sidebar/layerSelector/layer";

const ITEM_HEIGHT = 24;
const GROUP_HEIGHT = 48;
const ITEM_BLOCK_MARGIN = 8; // top+bottom
const ITEM_BLOCK_PADDING = 16; // top+bottom
const GROUP_CONTENTS_BLOCK_PADDING = 16; // top+bottom

const VirtualizedTree = ({ data, layerHandlers }) => {
    const list = useRef(null);

    useEffect(() => {
        list.current.recomputeRowHeights(0);
    }, [data]);

    const cellRenderer = ({ index, key, parent, style }) => {
        const renderedCell = renderItem(data[index], index);

        return (
            <div key={key} style={style} className="virtual-item">
                {renderedCell}
            </div>
        );
    };

    const renderItem = (item, index) => {
        if (isGroup(item)) {
            return (
                <Group
                    group={item}
                    depth={0}
                    layerHandlers={layerHandlers}
                    key={index}
                    index={index}
                    onCollapsedToggled={onGroupCollapsedToggled}
                />
            );
        } else {
            return <Layer key={index} layer={item} depth={0} layerHandlers={layerHandlers} />;
        }
    };

    const onGroupCollapsedToggled = useCallback((index) => {
        list.current.recomputeRowHeights(index);
    }, []);

    const rowHeight = (params) => {
        return getItemHeight(data[params.index]);
    };

    const getItemHeight = (item) => {
        if (isGroup(item)) {
            return getItemHeightRecursive(item) + GROUP_CONTENTS_BLOCK_PADDING;
        }
        return ITEM_HEIGHT + ITEM_BLOCK_MARGIN + ITEM_BLOCK_PADDING;
    };

    const getItemHeightRecursive = (item) => {
        //Initial height for a group
        let heightObj = { height: GROUP_HEIGHT };

        let isEmpty = item.layers.length === 0;

        //if the group is open then we show an additional divider that takes up 1 px
        if (!item.options.collapsed && !isEmpty) {
            heightObj.height++;
        }

        recurseItemHeight(item, heightObj);
        return heightObj.height;
    };

    function recurseItemHeight(layerGroup, heightObj, depth = 0) {
        //stop counting if group is collapsed
        if (layerGroup.options.collapsed || !layerGroup.layers.length) {
            return;
        }

        let isNested = depth > 0;

        heightObj.height += isNested ? 0 : 16;

        for (let i = 0; i < layerGroup.layers.length; i++) {
            let layer = layerGroup.layers[i];

            if (isGroup(layer)) {
                depth++;
                recurseItemHeight(layer, heightObj, depth);
            }

            heightObj.height += ITEM_HEIGHT + ITEM_BLOCK_PADDING;
        }
    }

    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    height={height}
                    ref={list}
                    overscanRowCount={0}
                    rowHeight={rowHeight}
                    rowRenderer={cellRenderer}
                    rowCount={data.length}
                    width={width}
                ></List>
            )}
        </AutoSizer>
    );
};

export default VirtualizedTree;
