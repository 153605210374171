import { CoreClient } from "features/core/models/CoreClient";
import { createParameterizedSelector, forwardArg } from "selectors/common";
import { RootState } from "store/models/RootState";
import { StatusType } from "./models/StatusType";

export const getActiveCoreClients = (state: RootState) =>
    state.core.coreClients.filter((x) => x.status === StatusType.Active);
export const getCoreClientsAndProjectsFetching = (state: RootState) => state.core.fetchingClientsAndProjects;
export const getClientId = (state: RootState) => state.core.clientId;
export const getProjectId = (state: RootState) => state.core.projectId;
export const getModuleId = (state: RootState) => state.core.moduleId;
export const getIdsRetreived = (state: RootState) => !!state.core.clientId && !!state.core.projectId;
export const getModules = (state: RootState) => state.core.modules;
export const getCoreAccountPermissionsFetching = (state: RootState) => state.core.fetchingAccountPermissions;
export const getCoreAccountPermissions = (state: RootState) => state.core.accountPermissions;

export const getClientById = createParameterizedSelector(
    [getActiveCoreClients, forwardArg],
    (clients: CoreClient[], clientId: string) => clients.find((client) => client.id === clientId)
);

type Ids = {
    clientId: string;
    projectId: string;
};
export const getProjectForClient = createParameterizedSelector(
    [getActiveCoreClients, forwardArg],
    (clients: CoreClient[], { clientId, projectId }: Ids) =>
        clients.find((client) => client.id === clientId)?.projects?.find((x) => x.id === projectId)
);
