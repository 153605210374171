import { FC, useState, useRef, useEffect, useMemo } from "react";
import { getSelectedApp } from "selectors/appsSelectors";
import { getFeatureFlags } from "selectors/featureFlagsSelectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import EmailNotificationsSection from "./components/EmailNotificationsSection";
import CollapsibleCard from "components/common/CollapsibleCard/CollapsibleCard";
import useStyles from "./styles";
import { Divider, Grid } from "@mui/material";
import CustomTypography from "components/common/CustomTypography/CustomTypography";

const enum SectionId {
    emailNotifications
}

export type ComponentProps = {
    disabled: boolean;
};

type Section = {
    id: SectionId;
    title: string;
    Component: FC<ComponentProps>;
    visible: boolean;
    scrollable: boolean;
    disabled: boolean;
    unmountOnExit?: boolean; // default true
};

const LAST_TOGGLED_SECTION_KEY = "LAST_TOGGLED_SECTION";

const Settings: FC = () => {
    const classes = useStyles();

    const [toggledSection, setToggledSection] = useState<SectionId | null>(null);

    const scrollToRef = useRef<null | HTMLDivElement>(null);

    const app = useAppSelector(getSelectedApp);
    const featureFlags = useAppSelector(getFeatureFlags);

    useEffect(() => {
        const lastToggledSection = parseInt(localStorage.getItem(LAST_TOGGLED_SECTION_KEY) ?? "");
        handleSectionToggle(!lastToggledSection ? SectionId.emailNotifications : lastToggledSection);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(
            () => scrollToRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }),
            500
        );
        return () => clearTimeout(timeout);
    }, [toggledSection]);

    const handleSectionToggle = (sectionId: SectionId) => {
        localStorage.setItem(LAST_TOGGLED_SECTION_KEY, sectionId.toString());
        setToggledSection(toggledSection === sectionId ? null : sectionId);
    };

    //  TODO: REMOVE THE CONDITIONAL RENDER FROM toolsMenu.js WHEN OTHER SETTINGS BESIDE NOTIFICATIONS ARE ADDED
    const sections: Section[] = useMemo(
        () => [
            {
                id: SectionId.emailNotifications,
                title: "Email Notifications",
                Component: EmailNotificationsSection,
                visible: featureFlags.NOTIFICATIONS,
                scrollable: true,
                disabled: false,
                unmountOnExit: false
            }
        ],
        [app.enabled, app.configJson.projections.length, app.languages.length, app.basemaps.length]
    );

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Divider />
            <Grid container justifyContent="flex-start" className={classes.title}>
                <CustomTypography variant="h6">Settings</CustomTypography>
            </Grid>
            <Divider />
            {sections.map(
                (section) =>
                    section.visible && (
                        <div
                            key={section.id}
                            className={classes.section}
                            ref={toggledSection === section.id ? scrollToRef : null}
                        >
                            <CollapsibleCard
                                onToggleClick={() => handleSectionToggle(section.id)}
                                title={section.title}
                                isToggled={toggledSection === section.id}
                                unmountOnExit={section.unmountOnExit ?? true}
                                maxHeight={section.scrollable ? 600 : undefined}
                            >
                                <section.Component disabled={section.disabled} />
                            </CollapsibleCard>
                        </div>
                    )
            )}
        </Grid>
    );
};

export default Settings;
