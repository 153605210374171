import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    content: {
        position: "absolute",
        top: 49,
        right: 45,
        zIndex: 2,
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
        }
    },
    textField: {
        backgroundColor: "white",
        borderRadius: "10px !important",
        minWidth: 300
    },
    input: {
        padding: "2px 32px 2px 6px !important"
    },
    circularProgress: {
        marginRight: -16
    }
}));
