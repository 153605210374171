import { FC } from "react";
import { useMapHandlers } from "../customHooks/map/useMapHandlers";
import { MapIds } from "../../model/enums/MapIds";

type WithMapHandlersParams = {
    mapId: MapIds;
};

export const withMapHandlers =
    <P extends object>(WrappedComponent: FC<P>, { mapId }: WithMapHandlersParams) =>
    (props: P) => {
        const handlers = useMapHandlers({ mapId });

        return <WrappedComponent {...handlers} {...props} />;
    };
