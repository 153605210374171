import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import useAuthentication from "app/hooks/useAuthentication";
import { getClientId, getProjectId } from "features/core/selectors";
import { useCallback, useEffect, useRef } from "react";
import { setBoats } from "reducers/ais";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import useSignalR from "utils/customHooks/useSignalR";

const AISDataLoader = () => {
    const protocol = useRef(new MessagePackHubProtocol());
    const clientId = useAppSelector(getClientId);
    const projectId = useAppSelector(getProjectId);

    const dispatch = useAppDispatch();

    const { getAccessToken } = useAuthentication();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tokenFactory = useCallback(() => getAccessToken(), []);

    const path = `ais/aishub?ClientId=${clientId}&ProjectId=${projectId}`;
    const { addEvent, removeEvent } = useSignalR(path, tokenFactory, protocol.current);

    useEffect(() => {
        addEvent("aisResponse", (data) => {
            // Since union types don't get deserialized, we get an array instead of an object
            // index 0 is the union type, index 1 is the data

            dispatch(setBoats(data));
        });

        return () => {
            removeEvent("aisResponse");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default AISDataLoader;
