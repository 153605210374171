import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    chip: {
        fontWeight: "bold",
        padding: "0px 3px"
    },
    chipExpanded: {
        "& .MuiChip-label": {
            visibility: "visible",
            width: "100%",
            paddingLeft: 0,
            paddingRight: 8
        }
    },
    chipShrinked: {
        "& .MuiChip-label": {
            visibility: "hidden",
            width: 0,
            padding: 0
        }
    },
    chipIcon: {
        width: 18,
        height: 18,
        fill: "inherit",
        margin: "0px 5px !important"
    },
    smallChip: {
        height: "fit-content",
        fontWeight: 600,
        fontSize: 11,
        lineHeight: 1,
        padding: "4px 3px"
    },
    smallChipIcon: {
        width: 15,
        height: 15
    }
}));
