import { Divider, Grid } from "@mui/material";
import LineIcon from "../../components/legend/LineIcon/LineIcon";
import PointIcon from "../../components/legend/PointIcon/PointIcon";
import PolygonIcon from "../../components/legend/PolygonIcon/PolygonIcon";
import RasterIcon from "../../components/legend/RasterIcon/RasterIcon";
import { Fragment } from "react";
import SymbolIcon from "../../components/legend/Symbolcon/SymbolIcon";
import LegendEntry from "../../components/legend/LegendEntry/LegendEntry";
import CustomTypography from "../../components/common/CustomTypography/CustomTypography";
import { BoxChartWithLabelsCanvas, PieChartWithLabelsCanvas } from "@emblautec/layer-styler-component";

const defaultSpacing = {
    singleEntryBreakpointSpacing: 12,
    multiLabelEntryBreakpointSpacing: 12,
    multiLabelItemBreakpointSpacing: 6,
    multiLabelItemVerticalBreakpointSpacing: 12
};

export const renderLegendEntry = (layer, styles, styleClasses, spacingOptions = defaultSpacing) => {
    const colorProperties = styles.map((s) => ({
        ...s.properties.find((p) => p.propertyType.includes("color")),
        styleType: s.type
    }));

    const layerGeometry = layer.geometryType;

    if (!layerGeometry) return;

    const styling = buildStyling(styles);

    const expressionProperty = colorProperties.find((prop) => prop.expressionType !== "none");
    const hasExpression = !!expressionProperty;
    
    if (hasExpression) {
        return (
            <Fragment key={layer.resourceId}>
                <Grid
                    item
                    xs={spacingOptions.multiLabelEntryBreakpointSpacing}
                    className={styleClasses.multiLabelHeaderTextGridContainer}
                >
                    <CustomTypography
                        variant="subtitle1"
                        textWeight="semibold"
                        className={styleClasses.multiLabelHeaderText}
                    >
                        {layer.name}
                    </CustomTypography>
                </Grid>
                {renderExpressionValues(
                    expressionProperty,
                    styling,
                    styleClasses,
                    spacingOptions,
                    layerGeometry === "raster",
                    layerGeometry === "raster"
                )}
                <Grid item xs={spacingOptions.multiLabelEntryBreakpointSpacing}>
                    <Divider className={styleClasses.dividerSpacing} />
                </Grid>
            </Fragment>
        );
    }

    if (styling.type === "pie-chart" || styling.type === "box-chart") {
        return (
            <Fragment key={layer.resourceId}>
                <Grid
                    item
                    xs={spacingOptions.multiLabelEntryBreakpointSpacing}
                    className={styleClasses.multiLabelHeaderTextGridContainer}
                >
                    <CustomTypography
                        variant="subtitle1"
                        textWeight="semibold"
                        className={styleClasses.multiLabelHeaderText}
                    >
                        {layer.name}
                    </CustomTypography>
                </Grid>
                {renderIcon(styling)}
                <Grid item xs={spacingOptions.multiLabelEntryBreakpointSpacing}>
                    <Divider className={styleClasses.dividerSpacing} />
                </Grid>
            </Fragment>
        );
    }
    return (
        <LegendEntry
            key={layer.resourceId}
            icon={renderIcon(styling)}
            title={layer.name}
            xsSpacing={spacingOptions.singleEntryBreakpointSpacing}
            classes={{
                dividerClass: styleClasses.dividerSpacing,
                entryContainerClass: styleClasses.singleLabelEntryContainer,
                typographyClass: styleClasses.labelText,
                iconContainerClass: styleClasses.iconContainerClass
            }}
            divider
        />
    );
};

const renderExpressionValues = (
    expressionProperty,
    styling,
    styleClasses,
    spacingOptions,
    verticalDirection = false,
    rightAlign = false
) => {
    const renderedEntries = [];
    let expressionPropertyCopy = { ...expressionProperty, value: [...expressionProperty.value] };

    const valuesOffset = expressionPropertyCopy.expressionType === "match" ? 2 : 3;

    if (rightAlign) {
        let longestNumberOfDecimals = 0;

        for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
            const expressionStringSplitted = expressionPropertyCopy.value[i].toString().split(".");

            if (!expressionStringSplitted[1] || expressionStringSplitted[1]?.length <= longestNumberOfDecimals)
                continue;

            longestNumberOfDecimals = expressionStringSplitted[1]?.length;
        }
        var longestValueLength = 0;

        for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
            const expressionVal = Number(expressionPropertyCopy.value[i]);
            expressionPropertyCopy.value[i] = !!expressionVal
                ? expressionVal.toFixed(longestNumberOfDecimals)
                : expressionPropertyCopy.value[i];
            if (expressionPropertyCopy.value[i].length <= longestValueLength) continue;

            longestValueLength = expressionPropertyCopy.value[i].toString().length;
        }
    }

    for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
        const props = {
            icon: null,
            title: "",
            typographyProps: null
        };

        // This means we are on the default case of a match expression
        if (expressionPropertyCopy.value[i + 1] === undefined) {
            //We don't want to render the default value of rasters
            if (styling.type === "raster") {
                continue;
            }
            const renderIconProps = {
                ...styling,
                [styling.type + "Color"]: styling[styling.type + "Color"][i]
            };

            if (styling.type === "fill" && Object.hasOwn(styling, "lineColor")) {
                if (typeof styling.lineColor === "string") {
                    renderIconProps.lineColor = styling.lineColor;
                } else {
                    renderIconProps.lineColor = styling.lineColor[i];
                }
            }

            props.icon = renderIcon(renderIconProps);
            props.title = "Others";
        } else {
            const index = styling.type === "raster" ? i - 1 + valuesOffset : i + 1;
            
            const renderIconProps = {
                ...styling,
                [styling.type + "Color"]: styling[styling.type + "Color"][index]
            };

            if (styling.type === "fill" && Object.hasOwn(styling, "lineColor")) {
                if (typeof styling.lineColor === "string") {
                    renderIconProps.lineColor = styling.lineColor;
                } else {
                    renderIconProps.lineColor = styling.lineColor[index];
                }
            }

            if (styling.type === "fill" && Object.hasOwn(styling, "circleColor")) {
                if (typeof styling.circleColor === "string") {
                    renderIconProps.circleColor = styling.circleColor;
                } else {
                    renderIconProps.circleColor = styling.circleColor[index];
                }
            }
            props.icon = renderIcon(renderIconProps);
            props.title = expressionPropertyCopy.value[i];
        }

        if (rightAlign) {
            props.typographyProps = {
                textAlign: "right",
                maxWidth: `${longestValueLength}ch`
            };
        }

        renderedEntries.push(
            <LegendEntry
                key={expressionPropertyCopy.value[i]}
                xsSpacing={
                    verticalDirection
                        ? spacingOptions.multiLabelItemVerticalBreakpointSpacing
                        : spacingOptions.multiLabelItemBreakpointSpacing
                }
                classes={{
                    dividerClass: styleClasses.dividerSpacing,
                    entryContainerClass: styleClasses.multiLabelEntryContainer,
                    typographyClass: styleClasses.multiLabelText,
                    iconContainerClass: styleClasses.iconContainerClass
                }}
                {...props}
            />
        );
    }
    return renderedEntries;
};

const renderIcon = (styling) => {
    switch (styling.type) {
        case "symbol":
            return <SymbolIcon {...styling} />;
        case "circle":
            return <PointIcon {...styling} />;
        case "line":
            return <LineIcon {...styling} />;

        case "fill":
            return <PolygonIcon {...styling} />;
        case "pie-chart":
            return (
                <PieChartWithLabelsCanvas
                    colors={styling["pie-chartColors"]}
                    labels={styling["pie-chartLabels"]}
                    centerFillPercentage={styling["pie-chartCenterSize"] / styling["pie-chartSize"]}
                    width={370}
                    height={200}
                    style={{
                        fontSize: 12,
                        strokeWidth: styling["pie-chartStrokeWidth"],
                        strokeColor: styling["pie-chartStrokeColor"]
                    }}
                />
            );
        case "box-chart":
            return (
                <div style={{ paddingLeft: 12 }}>
                    <BoxChartWithLabelsCanvas
                        colors={styling["box-chartColors"]}
                        labels={styling["box-chartLabels"]}
                        width={360}
                        height={50 * styling["box-chartRows"]}
                        columns={styling["box-chartColumns"]}
                        rows={styling["box-chartRows"]}
                        style={{
                            strokeWidth: styling["box-chartStrokeWidth"],
                            strokeColor: styling["box-chartStrokeColor"]
                        }}
                    />
                </div>
            );

        case "raster":
            return <RasterIcon {...styling} />;

        default:
            console.error(`Styling type not supported: ${styling.type}`);
            return null;
    }
};

const styleOrder = {
    symbol: 0,
    circle: 1,
    line: 2,
    fill: 3,
    raster: 4,
    "box-chart": 5,
    "pie-chart": 6
};

const propertiesMapping = {
    symbol: {
        "text-color": "Color",
        "icon-image": "Image",
        "icon-color": "IconColor"
    },
    circle: {
        "circle-color": "Color"
    },
    line: {
        "line-color": "Color",
        "line-dasharray": "DashArray"
    },
    fill: {
        "fill-color": "Color"
    },
    raster: {
        "fill-color": "Color"
    },
    "box-chart": {
        "box-chart-colors": "Colors",
        "box-chart-labels": "Labels",
        "box-chart-columns": "Columns",
        "box-chart-rows": "Rows",
        "box-chart-stroke-width": "StrokeWidth",
        "box-chart-stroke-color": "StrokeColor"
    },
    "pie-chart": {
        "pie-chart-colors": "Colors",
        "pie-chart-labels": "Labels",
        "pie-chart-size": "Size",
        "pie-chart-center-size": "CenterSize",
        "pie-chart-stroke-width": "StrokeWidth",
        "pie-chart-stroke-color": "StrokeColor"
    }
};

const buildStyling = (styles) => {
    let styling = { type: "symbol" };

    for (let i = 0; i < styles.length; i++) {
        let style = styles[i];
        let propertyMapping = propertiesMapping[style.type];

        if (!propertyMapping) continue;
        //Set the style type to the highest style type among the styles.
        //As an example: for a layer with a circle and fill style, select fill
        if (styleOrder[style.type] > styleOrder[styling.type]) {
            styling.type = style.type;
        }

        for (let j = 0; j < style.properties.length; j++) {
            let property = style.properties[j];
            let styleName = style.type + propertyMapping[property.name];

            if (propertyMapping[property.name] && !styling.hasOwnProperty(styleName)) {
                styling[styleName] = property.value;
            }
        }
    }

    return styling;
};
