import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    infoIcon: {
        marginRight: 8,
        color: theme.customColors.appTextColorLight
    },
    extraSmall: {
        fontSize: 16
    },
    infoContainer: {
        display: "flex"
    }
}));
