import makeStyles from "@mui/styles/makeStyles";

export const useBufferStyles = makeStyles((theme: any) => ({
    root: {
        position: "absolute",
        top: 16,
        left: 16,
        zIndex: 2,
        minWidth: 300,
        borderRadius: 4,
        backgroundColor: "#fff"
    },
    header: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f7f8",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    headerText: {
        flexGrow: 1
    },
    searchContainer: {
        padding: theme.spacing(1, 2)
    }
}));
