import { Divider, Grid, Typography } from "@mui/material";
import { useStyles } from "./styles";
import LegendEntries from "../../legend/LegendEntries/LegendEntries";
import { useLegendEntryStyles } from "./legendEntryStyles";

const LegendTool = () => {
    const classes = useStyles();
    const legendEntryClasses = useLegendEntryStyles();

    const spacingOptions = {
        singleEntryBreakpointSpacing: 12,
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 4,
        multiLabelItemVerticalBreakpointSpacing: 12
    };

    return (
        <Grid container direction="column" wrap="nowrap" className={classes.root}>
            <Divider />
            <Grid container justifyContent="flex-start" className={classes.title}>
                <Typography variant="h6">Legend</Typography>
            </Grid>
            <Divider />
            <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
        </Grid>
    );
};

export default LegendTool;
