import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useMap } from "@emblautec/react-map-gl";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        top: 20,
        right: 20,
        zIndex: 1,
        backgroundColor: "rgba(0,0,0,0)",
        pointerEvents:"none"
    },
    image: {
        maxHeight: 40
    }
}));

const MapNorthArrow = ({ modal = false }) => {
    const { current } = useMap();
    const classes = useStyles();
    const [north, setNorth] = useState(current.getBearing());

    useEffect(() => {
        let arrow = document.getElementById("north-arrow-icon");
        current.on("rotate", () => {
            setNorth(current.getBearing());
            arrow.style.transform = `rotate(${-north}deg)`;
        });
    });
    return (
        <div id="map-north-arrow" className={classes.root}>
            <img
                id="north-arrow-icon"
                src={process.env.PUBLIC_URL + "/media/arrow.png"}
                alt="map-north-arrow"
                className={classes.image}
            />
        </div>
    );
};

export default MapNorthArrow;
