import { FC, useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const SignOutView: FC = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.logoutRedirect({
            account: instance.getActiveAccount(),
            onRedirectNavigate: () => false
        });
    }, [instance]);

    return <div>Successfully Logged Out</div>;
};

export default SignOutView;
