import { useMap } from "@emblautec/react-map-gl";
import React, { FCWC } from "react";

type Props = {};

//To be used as a temporary solution when dealing with class components
const MapInjector: FCWC<Props> = ({ children }) => {
    const { current } = useMap();
    const childrenWithProps = React.Children.map(children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
            return React.cloneElement<any>(child, { map: current });
        }
        return child;
    });

    return <>{childrenWithProps}</>;
};

export default MapInjector;
