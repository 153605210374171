import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Checkbox } from "@mui/material";

function BooleanProperty(props) {
    function handleChange(e) {
        props.onPropertyChanged(e.target.checked);
    }

    return (
        <div className="property">
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />
                )}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                <div className="checkbox-container">
                    <FormControl variant="filled" className="property-boolean">
                        <Checkbox value={props.value} onChange={handleChange} checked={props.value}></Checkbox>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default BooleanProperty;
