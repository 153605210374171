import { createSelector } from "reselect";
import { RootState } from "../store/models/RootState";

type Selector<T> = (state: RootState) => T;

// Used to pass arguments to selectors
export const forwardArg: <T>(_: any, arg: T) => T = (_, arg) => arg;

// This returns a function that take a key as arg and return the corresponding value from the dict
export const dictGetterHOF =
    <T>(dictSelector: Selector<T>) =>
    (key: keyof T) =>
    (state: RootState) =>
        dictSelector(state)[key];

// This returns a function that takes the state and checks a condition, thus returning a boolean when used in useSelector
// Not sure how to type this one but since we don't really use it, it should be revisited when it's the case
export const mappingHOF =
    <T>(inputSelectors: Selector<any>[] | Selector<any>, mappingFunc: (...parameters: any[]) => T) =>
    (state: RootState) => {
        if (Array.isArray(inputSelectors)) {
            const parameters = inputSelectors.map((selector) => selector(state));
            return mappingFunc(...parameters);
        }
        return mappingFunc(inputSelectors(state));
    };

// No idea how to properly type this one
export const createParameterizedSelector = <T>(inputSelectors: any[], mappingFunc: (...args: any[]) => T) => {
    const selector = createSelector(inputSelectors, mappingFunc) as (state: RootState, param: any) => T;

    return (param: any) => (state: RootState) => selector(state, param);
};
