import { RootState } from "../store/models/RootState";
import { createParameterizedSelector, forwardArg } from "./common";

export const getStyles = (state: RootState) => (state.style as any).styles;

export const getAppId = (state: RootState) => state.style.appId;

export const getDigitizeStyles = (state: RootState) => state.style.digitizeStyles;

export const getStylesForDigitizeLayerIdSelector = createParameterizedSelector(
    [getDigitizeStyles, forwardArg],
    (digitizeStyles: any[], digitizeLayerId: string) => {
        if (digitizeLayerId === undefined) return undefined;

        const digitizeLayerDatasetId = digitizeLayerId.split(":")[0];
        return digitizeStyles.filter((style) => style.layerId.split(":")[0] === digitizeLayerDatasetId);
    }
);

export const getStyleConfig = (state: RootState) => state.style.config;
