export enum StyleType {
    Symbol = "symbol",
    Background = "background",
    Fill = "fill",
    Line = "line",
    Raster = "raster",
    Circle = "circle",
    FillExtrusion = "fill-extrusion",
    Heatmap = "heatmap",
    HillShade = "hillshade",
    Sky = "sky"
}
