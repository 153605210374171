import { Paper, Typography } from "@mui/material";
import useAttributeTableContainerStyles from "./styles";
import SearchField from "../SearchField/SearchField";
import useAttributeTableData from "../hooks/useAttributeTableData";
import useSelectedFeaturesManager from "../hooks/useSelectedFeaturesManager";
import AttributeTable from "../AttributeTable/AttributeTable";
import LoadingPlaceholder from "../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import AttributeTablePagination from "../AttributeTablePagination/AttributeTablePagination";
import AttributeTableHeader from "../AttributeTableHeader/AttributeTableHeader";
import useOnFlyToFeature from "../hooks/useOnFlyToFeature";
import ErrorPlaceholder from "../../../../../components/common/ErrorPlaceholder/ErrorPlaceholder";
import { useRef } from "react";
import useVerticalResizableMouseEvent from "../../../../../app/hooks/useVerticalResizableMouseEvent";

type Props = {
    onClose: () => void;
    datasetId: string;
    layerName: string;
    isDownloadable: boolean;
    onDownloadClick: (ids: number[]) => void;
};

const MIN_ATTRIBUTE_TABLE_HEIGHT = 53;

const AttributeTableContainer = ({ onClose, datasetId, isDownloadable, layerName, onDownloadClick }: Props) => {
    const classes = useAttributeTableContainerStyles();
    const resizableRef = useRef<HTMLDivElement>(null);
    const onMouseDown = useVerticalResizableMouseEvent({ ref: resizableRef, minHeight: MIN_ATTRIBUTE_TABLE_HEIGHT });
    const onFlyToFeature = useOnFlyToFeature();

    const {
        searchText,
        setSearchText,
        rowsPerPage,
        setRowsPerPage,
        tablePage,
        setTablePage,
        orderBy,
        setOrderBy,
        orderByDesc,
        data,
        totalRowCount,
        isFetching,
        isError,
        error,
        refetch
    } = useAttributeTableData(datasetId);

    const {
        isFeatureSelected,
        toggleFeature,
        toggleAllVisibleFeatures,
        areAllVisibleFeaturesSelected,
        isAtLeastOneVisibleFeatureSelected,
        numberOfSelectedFeatures,
        selectedFeatures
    } = useSelectedFeaturesManager(data?.properties);

    return (
        <Paper className={classes.paperContainer} ref={resizableRef}>
            <div id="map-table-dragbar" onMouseDown={onMouseDown} />
            <div className={classes.container}>
                <AttributeTableHeader
                    onClose={onClose}
                    layerName={layerName}
                    isDownloadable={isDownloadable}
                    numberOfSelectedFeatures={numberOfSelectedFeatures}
                    onDownloadClick={() => onDownloadClick(selectedFeatures)}
                />
                <SearchField searchText={searchText} startSearch={setSearchText} />
                <div className={classes.root}>
                    <div className={classes.tableContainer}>
                        <ErrorPlaceholder error={isError} onTryAgain={() => refetch()} message={error?.message}>
                            <LoadingPlaceholder loading={isFetching}>
                                {data?.properties && data.properties.length > 0 ? (
                                    <AttributeTable
                                        features={data.properties}
                                        onToggleFeature={toggleFeature}
                                        onToggleSelectAllFeatures={toggleAllVisibleFeatures}
                                        isFeatureSelected={isFeatureSelected}
                                        areAllFeaturesSelected={areAllVisibleFeaturesSelected}
                                        isAtLeastOneFeatureSelected={isAtLeastOneVisibleFeatureSelected}
                                        orderBy={orderBy}
                                        orderDesc={orderByDesc}
                                        onSortBy={setOrderBy}
                                        isDownloadable={isDownloadable}
                                        onFlyToFeature={onFlyToFeature}
                                    />
                                ) : (
                                    <div className={classes.noData}>
                                        <Typography variant="body2">No data</Typography>
                                    </div>
                                )}
                            </LoadingPlaceholder>
                        </ErrorPlaceholder>
                    </div>
                    <AttributeTablePagination
                        count={totalRowCount}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={setRowsPerPage}
                        page={tablePage}
                        onPageChange={setTablePage}
                    />
                </div>
            </div>
        </Paper>
    );
};

export default AttributeTableContainer;
