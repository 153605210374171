import { memo, useEffect, useRef, useState } from "react";
import { Layer, Source, useMap } from "@emblautec/react-map-gl";
import { STYLE_TYPES } from "../../../../constants/layers/styleTypes";
import { SOURCE_TYPES } from "../../../../constants/sources/sourceTypes";
import PolygonLabelGenerator from "../../utils/polygonLabelGenerator";

const PolygonLabelLayer = (props) => {
    const { layerId, minZoom, maxZoom, polygonLayerId } = props;

    const { current } = useMap();
    const polygonGenerator = useRef(new PolygonLabelGenerator(current));
    const [features, setFeatures] = useState([]);

    const sourceId = layerId + "_centroid";

    const isZoomValid = () => {
        const currentZoom = current.getZoom();
        return minZoom <= currentZoom && currentZoom <= maxZoom;
    };

    const generateLabels = () => {
        if (!isZoomValid) return; //We don't generate features if the layer isn't visible
        const generatedFeatures = polygonGenerator.current.generateLabels(polygonLayerId);
        setFeatures(generatedFeatures);
    };

    const onMapLoad = () => {
        generateLabels();
        current.on("moveend", generateLabels);
    };

    useEffect(() => {
        current.once("load", onMapLoad);
        return () => {
            current.off("moveend", generateLabels);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MemoGeoJsonSymbolLayer {...props} sourceId={sourceId} features={features} />;
};

const GeoJsonSymbolLayer = ({ layerId, minZoom, maxZoom, layout, beforeId, sourceId, features }) => {
    return (
        <Source
            id={sourceId}
            type={SOURCE_TYPES.geojson}
            data={{
                type: "FeatureCollection",
                features
            }}
        >
            <Layer
                id={layerId}
                minZoom={minZoom}
                maxZoom={maxZoom}
                beforeId={beforeId}
                type={STYLE_TYPES.symbol}
                layout={{ ...layout, "text-font": ["Open Sans Regular"] }}
            />
        </Source>
    );
};

const MemoGeoJsonSymbolLayer = memo(GeoJsonSymbolLayer);

export default PolygonLabelLayer;
