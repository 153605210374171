import axiosClient from "actions/apiClient";
import { coreApiClient } from "actions/coreApiClient";
import { AxiosRequestConfig } from "axios";
import { getClientId, getProjectId } from "features/core/selectors";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks/useAppSelector";

type Params = {
    clientId: string;
    projectId: string;
};

export const useIdsRequestInterceptor = () => {
    const [loading, setLoading] = useState(true);
    const atlasInterceptorId = useRef(0);
    const coreInterceptorId = useRef(0);

    const { clientId, projectId } = useParams<Params>();
    const clientIdRef = useRef(clientId);
    const projectIdRef = useRef(projectId);

    const decryptedClientId = useAppSelector(getClientId);
    const decryptedProjectId = useAppSelector(getProjectId);
    const decryptedClientIdRef = useRef(decryptedClientId);
    const decryptedProjectIdRef = useRef(decryptedProjectId);

    const atlasInterceptor = async (cfg: AxiosRequestConfig) => {
        cfg.headers.common["ClientId"] = decryptedClientIdRef.current;
        cfg.headers.common["ProjectId"] = decryptedProjectIdRef.current;

        return cfg;
    };

    const coreInterceptor = async (cfg: AxiosRequestConfig) => {
        cfg.headers.common["ClientId"] = clientIdRef.current;
        cfg.headers.common["ProjectId"] = projectIdRef.current;

        return cfg;
    };

    // register interceptors, this needs to be done only once
    useEffect(() => {
        atlasInterceptorId.current = axiosClient.interceptors.request.use(atlasInterceptor);
        coreInterceptorId.current = coreApiClient.interceptors.request.use(coreInterceptor);
        setLoading(false);

        return () => {
            axiosClient.interceptors.request.eject(atlasInterceptorId.current);
            coreApiClient.interceptors.request.eject(coreInterceptorId.current);
            setLoading(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // update refs
    useEffect(() => {
        decryptedClientIdRef.current = decryptedClientId;
        decryptedProjectIdRef.current = decryptedProjectId;
    }, [decryptedClientId, decryptedProjectId]);

    useEffect(() => {
        clientIdRef.current = clientId;
        projectIdRef.current = projectId;
    }, [clientId, projectId]);

    return loading;
};
