import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import useSearchFieldStyles from "./styles";

type Props = {
    startSearch: (searchText: string) => void;
    searchText: string;
};
const SearchField: FC<Props> = ({ startSearch, searchText }) => {
    const classes = useSearchFieldStyles();

    const [text, setText] = useState(searchText);

    useEffect(() => {
        setText(searchText);
    }, [searchText]);

    const onChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            startSearch(text);
        }
    };

    return (
        <TextField
            placeholder="Search..."
            onChange={onChangeSearchText}
            variant="filled"
            onKeyDown={onKeyDown}
            value={text}
            fullWidth
            className={classes.searchInput}
            hiddenLabel
            inputProps={{ autoComplete: "off", "data-testid": searchfieldTestId }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton onClick={() => startSearch(text)} data-testid={searchButtonTestId}>
                            <SearchIcon className={classes.searchIcon} />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};

export default SearchField;

const searchfieldTestId = "qa-attribute-table-search-field";
const searchButtonTestId = "qa-attribute-table-search-button";
