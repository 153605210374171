import { FCWC, createContext, useEffect, useRef } from "react";
import { BufferWWMessage } from "../models/BufferWWMessage";
import { BufferWWResponse } from "../models/BufferWWResponse";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { addBufferLayer, removeBufferLayer, updateBufferLayer } from "reducers/layerSelector";
import { AppLayer } from "model/app/AppLayer";
import { FeatureCollection, Units } from "@turf/helpers";
import { v4 as generateUuid } from "uuid";
import {
    allowedBufferUnits,
    getAppLayerStyle,
    getBufferLayer,
    getBufferLayoutProperties,
    getBufferPaintProperties,
    getBufferSource,
    getInitialBufferAppLayer
} from "./utils";
import { addMapLayer, addMapLayout, addMapPaint, addMapZoomRange, addSources } from "reducers/map";
import customToastr from "utils/customToastr";

type BufferWWContextValue = {
    generateBuffer: (
        originalLayer: AppLayer,
        features: FeatureCollection,
        radius: number,
        units: Units,
        dissolveResult: boolean
    ) => void;
    cancelGenerate: (layerResourceId: string) => void;
};

type Props = {
    maxNumberOfWorkers: number;
    appId: string;
};

export const BufferWWContext = createContext<BufferWWContextValue>({
    generateBuffer: () => {},
    cancelGenerate: () => {}
});

export const BufferWWContextProvider: FCWC<Props> = ({ children, maxNumberOfWorkers, appId }) => {
    const messageQueue = useRef<BufferWWMessage[]>([]);
    const workers = useRef<Record<string, Worker>>({});

    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            Object.keys(workers.current).forEach((layerResourceId) => workers.current[layerResourceId].terminate());
        };
    }, [appId]);

    const generateBuffer = (
        originalLayer: AppLayer,
        features: FeatureCollection,
        radius: number,
        units: Units,
        dissolveResult: boolean
    ) => {
        const resourceId = generateUuid();
        addLoadingBufferLayer(originalLayer, resourceId, radius, units);

        const pendingMessage: BufferWWMessage = {
            targetLayerResourceId: originalLayer.resourceId,
            layerResourceId: resourceId,
            features,
            radius,
            units,
            dissolveResult
        };

        messageQueue.current.push(pendingMessage);

        assignMessageToWorker();
    };

    const cancelGenerate = (layerResourceId: string) => {
        removeBuffer(layerResourceId);

        if (!workers.current.hasOwnProperty(layerResourceId)) {
            messageQueue.current = messageQueue.current.filter((x) => x.layerResourceId !== layerResourceId);
            return;
        }

        onWorkerFinished(layerResourceId);

        assignMessageToWorker();
    };

    const onmessage = (event: MessageEvent<BufferWWResponse>) => {
        switch (event.data.errorSeverity) {
            case "Error":
                customToastr.error(event.data.errorMessage);
                removeBuffer(event.data.layerResourceId);
                break;
            case "Warning":
                customToastr.warning(event.data.errorMessage);
                addBufferToMap(event);
                break;
            default:
                addBufferToMap(event);
        }

        onWorkerFinished(event.data.layerResourceId);
        assignMessageToWorker();
    };

    const onWorkerFinished = (layerResourceId: string) => {
        workers.current[layerResourceId].terminate();

        delete workers.current[layerResourceId];
    };

    const assignMessageToWorker = () => {
        if (Object.keys(workers.current).length >= maxNumberOfWorkers || !messageQueue.current[0]) {
            return;
        }

        const pendingMessage = messageQueue.current.shift()!;

        const worker = createWorker(pendingMessage.layerResourceId);
        worker.postMessage(pendingMessage);
    };

    const createWorker = (layerResourceId: string) => {
        /**
         * VERY IMPORTANT: the name of the file MUST be inline.
         * It does NOT work if we pass the name as a parameter.
         *
         * "Warning
         * Using a variable in the Worker constructor is not supported by webpack.
         * For example, the following code will not work:
         * const url = new URL('./path/to/worker.ts', import.meta.url);
         * const worker = new Worker(url);.
         * This is because webpack cannot analyse the syntax statically.
         * It is important to be aware of this limitation when using Worker syntax with webpack."
         *
         * Source: https://webpack.js.org/guides/web-workers/
         */
        const myWorker = new Worker(new URL("./buffer-ww.ts", import.meta.url), { type: "module" });
        myWorker.onmessage = onmessage;

        workers.current[layerResourceId] = myWorker;

        return myWorker;
    };

    const addLoadingBufferLayer = (originalLayer: AppLayer, resourceId: string, radius: number, units: Units) => {
        dispatch(
            addBufferLayer({
                layer: getInitialBufferAppLayer(
                    resourceId,
                    `${originalLayer.name} ${radius}${allowedBufferUnits[units].key} buffer`,
                    originalLayer
                ),
                targetLayerResourceId: originalLayer.resourceId
            })
        );
    };

    const addBufferToMap = (event: MessageEvent<BufferWWResponse>) => {
        const sourceId = generateUuid();
        const layerId = generateUuid();

        if (!event.data.bufferFeatures) {
            removeBuffer(event.data.layerResourceId);
            return;
        }

        const geometryType = event.data.bufferFeatures.features[0].geometry.type.toUpperCase();

        dispatch(
            updateBufferLayer({
                layerResourceId: event.data.layerResourceId,
                bounds: event.data.bounds,
                sourceId,
                geometryType,
                layerStyle: getAppLayerStyle(layerId)
            })
        );

        dispatch(addSources([getBufferSource(sourceId, event.data.bufferFeatures)]));

        dispatch(addMapPaint({ layerId, properties: getBufferPaintProperties() }));
        dispatch(addMapLayout({ layerId, properties: getBufferLayoutProperties() }));
        dispatch(addMapZoomRange({ layerId, minZoom: 0, maxZoom: 24 }));

        dispatch(
            addMapLayer({
                newLayer: getBufferLayer(sourceId, layerId, event.data.layerResourceId),
                targetResourceId: event.data.targetLayerResourceId
            })
        );
    };

    const removeBuffer = (layerResourceId: string) => {
        dispatch(removeBufferLayer(layerResourceId));
    };

    const contextValue: BufferWWContextValue = {
        generateBuffer,
        cancelGenerate
    };

    return <BufferWWContext.Provider value={contextValue}>{children}</BufferWWContext.Provider>;
};
