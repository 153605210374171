import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import * as sidebarAndDrawerActions from "../../../actions/sidebarAndDrawer";
import * as layerSelectorActions from "../../../reducers/layerSelector";
import { measureIcon } from "../../../utils/mapIcons";
import { toggleWidget } from "features/mapTools/slice";
import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import OpenNav from "@mui/icons-material/Menu";
import CloseNav from "@mui/icons-material/DoubleArrow";
import LayerIcon from "@mui/icons-material/Layers";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import DigitizeIcon from "@mui/icons-material/Timeline";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import PrintIcon from "@mui/icons-material/Print";
import { List } from "@mui/icons-material";
import { toolsMenuJss } from "./jss/toolsMenuJss";
import { AIS_PATH_SUFFIX, LAYER_SELECTOR_PATH_SUFFIX, SETTINGS_PATH_SUFFIX } from "../../../constants/routes";
import acceptedTools from "../../../constants/acceptedTools";
import { AIS_TITLE } from "../ais/utils";
import toolsDict from "../../../constants/tools";

import NavButton from "./components/NavButton/NavButton";
import { getFeatureFlags } from "../../../selectors/featureFlagsSelectors";
import NavLink from "./components/NavLink/NavLink";
import clsx from "clsx";
import { getIsDrawerOpen, getIsSidebarOpen } from "selectors/sidebarAndDrawer";

class ToolsMenu extends Component {
    state = {
        icons: {
            search: <SearchIcon fontSize="medium" />,
            print: <PrintIcon fontSize="medium" />,
            measure: (
                <SvgIcon fontSize="medium" className={this.props.classes.measureIcon}>
                    {measureIcon}
                </SvgIcon>
            ),
            digitize: <DigitizeIcon fontSize="medium" />,
            legend: <List fontSize="medium" />
        }
    };

    onClickNavLink = () => {
        this.props.setStylerLayerId(null);
        if (!this.props.sidebarState) this.props.toggleSidebarOpen();
    };

    toggle = (widget) => {
        let openWidget = this.props.widgets.filter((x) => x.toggled)[0];
        if (!openWidget) {
            this.props.toggleWidget(widget.name);
        } else {
            this.props.toggleWidget(openWidget.name);
            this.props.toggleWidget(widget.name);
        }
    };

    render() {
        const { classes, location, tools, widgets, isDrawerOpen } = this.props;
        const locationSplit = location.pathname.split("/");
        const currentLocation = locationSplit[locationSplit.length - 1];

        const toolsSidebar = tools.map((tool) => {
            if (!acceptedTools[tool.name]) return null;

            const toolIcon = this.state.icons[tool.name];
            const toolRoute = { pathname: toolsDict[tool.name].routeSuffix, search: location.search };

            return (
                <NavLink
                    key={tool.namePretty}
                    name={tool.namePretty}
                    to={toolRoute}
                    active={currentLocation === tool.name}
                    icon={toolIcon}
                    onClick={this.onClickNavLink}
                />
            );
        });

        const widgetsSidebar = widgets.map((widget) => {
            const widgetIcon = this.state.icons[widget.name];
            return (
                <NavButton
                    key={widget.namePretty}
                    name={widget.namePretty}
                    onClick={() => this.toggle(widget)}
                    icon={widgetIcon}
                    active={widget.toggled}
                />
            );
        });

        return (
            <div className={`toolbar ${isDrawerOpen && `open`}`}>
                <div className={"endAlign " + classes.padding12}>
                    <Button
                        className={clsx(
                            isDrawerOpen && classes.navOpen,
                            classes.closeButton,
                            this.props.sidebarState && classes.closedSidebar
                        )}
                        onClick={this.props.toggleDrawerOpen}
                        aria-label="Close"
                    >
                        {!isDrawerOpen ? (
                            <OpenNav />
                        ) : (
                            <div className={classes.headerItem}>
                                {/* We apply the tool class on a wrapper since rotate also inverts the left and right padding*/}
                                <div className={classes.toolIcon}>
                                    <CloseNav style={{ transform: "rotate(180deg)" }} />
                                </div>
                                <span className={classes.headerLabel}>Navigation</span>
                            </div>
                        )}
                    </Button>
                </div>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <NavLink
                            name="Layer selector"
                            to={{ pathname: LAYER_SELECTOR_PATH_SUFFIX, search: location.search }}
                            icon={<LayerIcon fontSize="medium" />}
                            active={currentLocation === LAYER_SELECTOR_PATH_SUFFIX}
                            onClick={this.onClickNavLink}
                        />
                        {toolsSidebar}
                        {this.props.featureFlags.AIS && (
                            <NavLink
                                name={AIS_TITLE}
                                to={{ pathname: AIS_PATH_SUFFIX, search: location.search }}
                                icon={<DirectionsBoatIcon fontSize="medium" />}
                                active={currentLocation === AIS_PATH_SUFFIX}
                                onClick={this.onClickNavLink}
                            />
                        )}
                        {widgetsSidebar}

                        {/* TODO: REMOVE THE CONDITIONAL RENDER WHEN OTHER SETTINGS BESIDE NOTIFICATIONS ARE ADDED */}
                        {this.props.featureFlags.NOTIFICATIONS && (
                            <NavLink
                                name="Settings"
                                to={{ pathname: SETTINGS_PATH_SUFFIX, search: location.search }}
                                icon={<SettingsIcon fontSize="medium" />}
                                active={currentLocation === SETTINGS_PATH_SUFFIX}
                                onClick={this.onClickNavLink}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sidebarState: getIsSidebarOpen(state),
        isDrawerOpen: getIsDrawerOpen(state),
        tools: state.mapTools.tools,
        widgets: state.mapTools.widgets,
        featureFlags: getFeatureFlags(state)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebarOpen: () => dispatch(sidebarAndDrawerActions.toggleSidebarOpen()),
        toggleDrawerOpen: () => dispatch(sidebarAndDrawerActions.toggleDrawerOpen()),
        toggleWidget: (widget) => dispatch(toggleWidget(widget)),
        setStylerLayerId: (id) => dispatch(layerSelectorActions.setStylerLayerId(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(toolsMenuJss)(withRouter(ToolsMenu)));
