import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@mui/material";
import { FileCopy } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getBasemapTitle, getMapLanguage } from "../../../selectors/mapSelectors";
import toastr from "../../../utils/customToastr";
import {
    boundsQueryParamName,
    basemapQueryParamName,
    languageQueryParamName,
    visibleLayersQueryParamName,
    bearingQueryParamName,
    pitchQueryParamName,
    terrainExaggerationParamName,
    sidebarStatusQueryParamName,
    zoomQueryParamName,
    centerQueryParamName
} from "../../../constants/map/queryParams";
import { getLayerVisibilityMap } from "../../../selectors/layerSelector";
import { useMap } from "@emblautec/react-map-gl";
import { iframeCopyToClipboardUrl } from "../utils/iframeCopyToClipboardUrl";
import { getIsSidebarOpen } from "selectors/sidebarAndDrawer";

const useStyles = makeStyles(() => ({
    iconRoot: {
        position: "absolute",
        top: 304,
        right: 10,
        zIndex: 1,
        borderRadius: 4,
        backgroundColor: "white",
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)"
    },
    iconContainer: {
        width: 29,
        height: 29,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke",
            borderRadius: 4
        }
    },
    icon: {
        color: "#484848"
    }
}));

const MapCopyState = () => {
    const classes = useStyles();

    const { current } = useMap();
    const basemapTitle = useSelector(getBasemapTitle);
    const mapLanguage = useSelector(getMapLanguage);
    const layerVisibilityMap = useSelector(getLayerVisibilityMap);

    const sidebarState = useSelector(getIsSidebarOpen);

    const handleCopyToClipboard = () => {
        const queryParams = new URLSearchParams();

        const terrainExaggeration = current.getTerrain()?.exaggeration;

        queryParams.append(boundsQueryParamName, getMapBoundsQueryParam());
        queryParams.append(bearingQueryParamName, current.getBearing());
        queryParams.append(pitchQueryParamName, current.getPitch());
        terrainExaggeration && queryParams.append(terrainExaggerationParamName, terrainExaggeration);
        queryParams.append(basemapQueryParamName, basemapTitle);
        queryParams.append(languageQueryParamName, mapLanguage);
        queryParams.append(visibleLayersQueryParamName, getVisibleLayersQueryParam());
        queryParams.append(sidebarStatusQueryParamName, sidebarState);
        queryParams.append(zoomQueryParamName, current.getZoom());
        queryParams.append(centerQueryParamName, JSON.stringify([current.getCenter().lng, current.getCenter().lat]));

        iframeCopyToClipboardUrl(queryParams.toString());

        toastr.success("Map state copied to clipboard.");
    };

    const getMapBoundsQueryParam = () => {
        const bounds = current.getBounds();
        const boundsArray = [
            [bounds._sw.lng, bounds._sw.lat],
            [bounds._ne.lng, bounds._ne.lat]
        ];

        return JSON.stringify(boundsArray);
    };

    const getVisibleLayersQueryParam = () => {
        return Object.keys(layerVisibilityMap)
            .filter((x) => layerVisibilityMap[x])
            .reduce((a, b) => (a += `,${b}`), "");
    };

    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Copy map state">
                <div className={classes.iconContainer} onClick={handleCopyToClipboard}>
                    <FileCopy className={classes.icon} />
                </div>
            </Tooltip>
        </div>
    );
};

export default MapCopyState;
