import makeStyles from '@mui/styles/makeStyles';

export const useDigitizeLayerStyles = makeStyles(() => ({
    title: {
        padding: "8px 16px"
    },
    editFieldContainer: {
        paddingLeft: 16
    },
    textField: {
        width: 180
    },
    buttonIcon: {
        display: "flex",
        height: 20,
        width: 16,
        paddingRight: 6,
        color: "rgba(51, 51, 51, 1)"
    },
    unselectedLayer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "#fff",
        cursor: "pointer",
        borderBottom: "1px solid whitesmoke"
    },
    selectedLayer: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "lightgray",
        cursor: "pointer",
        borderBottom: "1px solid whitesmoke"
    },
    layerName: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    layerMoreButton: {
        borderRadius: 0
    },
    digitizeStyleMenuIcon: {
        minWidth: 32,
        "& .MuiListItemIcon-root": {
            fontSize: "0.865rem"
        }
    },
    digitizeStyleMenuText: {
        "& .MuiListItemText-primary": {
            fontSize: "0.865rem"
        }
    }
}));
