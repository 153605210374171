import { createSlice } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import config from "config";
import { CoreClient } from "features/core/models/CoreClient";
import { fetchAccountPermissions, fetchCoreClientsWithProjects, fetchModules } from "./actions";
import { CoreAccountPermissions } from "./models/CoreAccountPermissions";
import { CoreModule } from "./models/CoreModule";

type SliceState = {
    coreClients: CoreClient[];
    fetchingClientsAndProjects: boolean;
    fetchingModules: boolean;
    fetchingAccountPermissions: boolean;
    clientId: number | null;
    projectId: number | null;
    moduleId: string;
    modules: CoreModule[];
    accountPermissions: CoreAccountPermissions;
};

const initialState: SliceState = {
    coreClients: [],
    fetchingClientsAndProjects: false,
    fetchingModules: false,
    fetchingAccountPermissions: false,
    clientId: null,
    projectId: null,
    moduleId: config.moduleId,
    modules: [],
    accountPermissions: { accountProjectPermissions: [], accountReportPermissions: [] }
};

const coreSlice = createSlice({
    name: "core",
    initialState,
    reducers: {
        setIds: (state, { payload: { clientId, projectId } }) => {
            state.clientId = clientId;
            state.projectId = projectId;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCoreClientsWithProjects.fulfilled, (state, { payload }) => {
                state.coreClients = payload;
                state.fetchingClientsAndProjects = false;
            })
            .addCase(fetchCoreClientsWithProjects.pending, (state) => {
                state.fetchingClientsAndProjects = true;
            })
            .addCase(fetchCoreClientsWithProjects.rejected, (state) => {
                state.fetchingClientsAndProjects = false;
            })
            .addCase(resetProjectData, (state) => {
                state.clientId = null;
                state.projectId = null;
            })
            .addCase(fetchModules.fulfilled, (state, { payload: modules }) => {
                state.modules = [...modules, ...initialState.modules];
                state.fetchingModules = false;
            })
            .addCase(fetchModules.pending, (state) => {
                state.fetchingModules = true;
            })
            .addCase(fetchModules.rejected, (state) => {
                state.fetchingModules = false;
            })
            .addCase(fetchAccountPermissions.fulfilled, (state, { payload }) => {
                state.accountPermissions = payload;
                state.fetchingAccountPermissions = false;
            })
            .addCase(fetchAccountPermissions.pending, (state) => {
                state.fetchingAccountPermissions = true;
            })
            .addCase(fetchAccountPermissions.rejected, (state) => {
                state.fetchingAccountPermissions = false;
            });
    }
});

export const { setIds } = coreSlice.actions;

export default coreSlice.reducer;
