import { createSelector } from "reselect";
import { Source } from "../model/map/Source";
import { RootState } from "../store/models/RootState";
import { createParameterizedSelector, forwardArg } from "./common";
import { MapFilter } from "model/map/MapFilterType";
import { MapLayer } from "model/map/MapLayer";

export const getMapZoomLevel = (state: RootState) => state.map.zoom;
export const getMapLoaded = (state: RootState) => state.map.loaded;
export const getPaints = (state: RootState) => state.map.paints;
export const getLayouts = (state: RootState) => state.map.layouts;
export const getZoomRanges = (state: RootState) => state.map.zoomRanges;

export const getAisPaints = (state: RootState) => state.map.aisPaints;
export const getAisLayouts = (state: RootState) => state.map.aisLayouts;
export const getAisZoomRanges = (state: RootState) => state.map.aisZoomRanges;

export const getLayers = (state: RootState) => state.map.layers;
export const getAisLayers = (state: RootState) => state.map.aisLayers;

export const getMapLanguage = (state: RootState) => state.map.language;

export const getBasemapTitle = (state: RootState) => {
    const basemap = state.map.basemap;
    if (basemap.type !== "none") return basemap.title;
    else return "None";
};

export const getMapClickPos = (state: RootState) => state.map.clickPos;

export const getBasemap = (state: RootState) => state.map.basemap;

export const getSources = (state: RootState) => state.map.sources;

export const getFilters = (state: RootState) => state.map.filters;

export const getSourceMinZoom = createParameterizedSelector<number | undefined>(
    [getSources, forwardArg],
    (sources: Source[], sourceId: string) => {
        const source = sources.find((source) => source.id === sourceId);
        if (source && "minZoom" in source) {
            return source.minZoom;
        }
    }
);

export const getRestrictedView = (state: RootState) => state.map.restrictedView;

export const getLayersGroupedBySources = createSelector([getLayers], (layers) => {
    const sourcesDict = layers.reduce((acc, layer) => {
        if (acc[layer.sourceId]) {
            acc[layer.sourceId].push(layer);
        } else {
            acc[layer.sourceId] = [layer];
        }

        return acc;
    }, {});

    return sourcesDict;
});

export const getFiltersByLayerIdWithExpectedType =
    createParameterizedSelector<string>([getFilters, forwardArg], (filters, layerId) =>
        (filters[layerId] || []).map(
            ({ type, operatorName, fieldName, value, secondValue = undefined, applied }: MapFilter) => ({
                type,
                operatorName,
                fieldName,
                value,
                secondValue,
                applied
            })
        )
    ) ?? [];

export const getLayerByResourceId = createParameterizedSelector<MapLayer | undefined>(
    [getLayers, forwardArg],
    (layers: MapLayer[], resourceId: string) => {
        return layers.find((layer) => layer.resourceId === resourceId);
    }
);

export const getSource = createParameterizedSelector<Source | undefined>(
    [getSources, forwardArg],
    (sources: Source[], sourceId: string) => {
        return sources.find((source) => source.id === sourceId);
    }
);
