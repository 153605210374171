import { MetadataPropertyValue } from "./../model/metadata/MetadataPropertyValue";
import axiosClient from "./apiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import { AxiosResponse, CancelToken } from "axios";
import { RejectValue } from "../store/models/RejectValue";
import { LayerData } from "model/app/FeatureCollection";
import { featureIdsParam } from "constants/map/queryParams";

type DownloadDatasetArg = { datasetId: string; format: string; featureIds: number[]; config: any };
export const downloadDataset = createAsyncThunk<AxiosResponse<any>, DownloadDatasetArg, RejectValue>(
    "downloadDataset",
    async ({ datasetId, format, featureIds, config }: DownloadDatasetArg, { rejectWithValue }) => {
        const queryParams = new URLSearchParams();
        featureIds?.forEach((x) => queryParams.append(featureIdsParam, x.toString()));

        return axiosClient
            .get(`dataset/${datasetId}/download/${format}?${queryParams.toString()}`, {
                ...config,
                responseType: "blob"
            })
            .then((res) => res)
            .catch((err) => {
                return rejectWithValue(err);
            });
    }
);

export const getLayerMetadata = createAsyncThunk<MetadataPropertyValue[], string>(
    "getLayerMetadata",
    (datasetId: string) => {
        return axiosClient.get(`dataset/meta/${datasetId}`).then((res) => res.data.metadata);
    }
);

type DownloadGeotiffArg = { rasterId: string; fileName: string; config: any };
export const downloadGeotiff = createAsyncThunk<void, DownloadGeotiffArg, RejectValue>(
    "downloadGeotiff",
    ({ rasterId, fileName, config }, { rejectWithValue }) => {
        return axiosClient
            .get(`raster/${rasterId}/download/`, {
                ...config,
                responseType: "blob"
            })
            .then((res) => {
                fileDownload(res.data, fileName);
            })
            .catch((err) => rejectWithValue(err));
    }
);
