import makeStyles from '@mui/styles/makeStyles';

export const useMapToolsStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        bottom: 25,
        right: 112,
        width: "auto",
        zIndex: 5
    }
}));
