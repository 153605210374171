import React, { useEffect, useState } from "react";
import { Grid, IconButton, Typography, Divider, Fab, Fade, Popper, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add, AddCircle, ArrowBack, Cancel } from "@mui/icons-material";
import DigitizeFeatures from "./digitizeFeatures";
import { useDispatch, useSelector } from "react-redux";
import { displayInfoHelp } from "../../../actions/digitize";
import { getDigitizeEditingStatus, getDigitizeStateInfoHelp } from "../../../selectors/digitizeSelectors";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "#f5f5f5"
    },
    header: {
        height: 48,
        paddingLeft: 16,
        paddingRight: 16
    },
    selectedLayerName: {
        paddingLeft: 6
    },
    infoPanel: {
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}));

const LayerFeatures = ({
    selectedLayer,
    setSelectedLayer,
    onDraw,
    renderDistanceMarkers,
    setIsStylerOpen,
    deselectAllFeaturesAndLabels
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorInfo, setAnchorInfo] = useState(null);
    const digitizeInfoHelp = useSelector(getDigitizeStateInfoHelp);
    const editing = useSelector(getDigitizeEditingStatus);
    const [backButtonAttempt, setBackButtonAttempt] = useState(false);

    const handleDrawFeature = () => {
        onDraw(selectedLayer);
        setAnchorInfo(null);
        dispatch(displayInfoHelp(false));
    };

    const handleCloseInfo = () => {
        setAnchorInfo(null);
    };

    const handleBackButton = () => {
        if (editing) {
            let backButton = document.getElementById("digitize-button-back");
            setAnchorInfo(backButton);
            setBackButtonAttempt(true);
        } else {
            setIsStylerOpen(false);
            setSelectedLayer({});
            setBackButtonAttempt(false);
            setAnchorInfo(null);
        }
        deselectAllFeaturesAndLabels();
    };

    useEffect(() => {
        if (digitizeInfoHelp && selectedLayer.type === "point") {
            let infoButton = document.getElementById("digitize-button-add-data-info");
            setAnchorInfo(infoButton);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item xs={12}>
                <Grid container alignItems="center" className={classes.header}>
                    <Grid item xs={11}>
                        <Grid container direction="row" alignItems="center">
                            <IconButton size="small" onClick={handleBackButton} id="digitize-button-back">
                                <ArrowBack />
                            </IconButton>
                            <Typography variant="body1" className={classes.selectedLayerName}>
                                {selectedLayer.namePretty}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {!digitizeInfoHelp ? (
                            <IconButton
                                size="medium"
                                color="primary"
                                aria-label="add-feature"
                                onClick={handleDrawFeature}
                            >
                                <AddCircle />
                            </IconButton>
                        ) : (
                            <Fab
                                id="digitize-button-add-data-info"
                                size="small"
                                color="primary"
                                aria-label="add-feature-info"
                                onClick={handleDrawFeature}
                            >
                                <Add />
                            </Fab>
                        )}
                    </Grid>
                    {digitizeInfoHelp && (
                        <Popper open={Boolean(anchorInfo)} anchorEl={anchorInfo} placement="right-end" transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper className={classes.infoPanel}>
                                        <Typography>To add a new feature, click on the '+' button.</Typography>
                                        <IconButton size="small" onClick={handleCloseInfo}>
                                            <Cancel />
                                        </IconButton>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    )}
                </Grid>
                {backButtonAttempt && (
                    <Popper open={Boolean(anchorInfo)} anchorEl={anchorInfo} placement="right-end" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.infoPanel}>
                                    <Typography>Finish adding the feature before going back.</Typography>
                                    <IconButton size="small" onClick={handleCloseInfo}>
                                        <Cancel />
                                    </IconButton>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                )}
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <DigitizeFeatures
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    renderDistanceMarkers={renderDistanceMarkers}
                    deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                />
            </Grid>
        </Grid>
    );
};

export default LayerFeatures;
