import makeStyles from "@mui/styles/makeStyles";

const useLayerStyles = makeStyles((theme) => ({
    contextMenuBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        backgroundColor: "#e3e4e5",
        color: "black",
        marginLeft: "auto"
    },

    cancelBtn: {
        borderRadius: 3,
        height: 24,
        width: 24,
        backgroundColor: "#fce2e1",
        color: theme.customColors.errorColor,
        marginLeft: "auto"
    },

    grow: {
        flexGrow: 1
    },
    grayIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        marginRight: 8
    },
    switch: {
        marginLeft: -12,
        marginRight: 8
    }
}));

export default useLayerStyles;
