import axios from "axios";
import Config from "../config";

export const axiosClient = axios.create({
    baseURL: Config.apiUrl,
    timeout: 120000,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: true
});

export const generateCancelToken = () => axios.CancelToken.source();

export default axiosClient;
