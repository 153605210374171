import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, Input, InputAdornment, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as layerSelectorActions from "../../../reducers/layerSelector";
import * as mapActions from "../../../reducers/map";
import { getFetchingLayers, getLayerGroups } from "../../../selectors/layerSelector";
import { PortalComponent } from "../../../utils/portal";
import VirtualizedTree from "../../../utils/virtualized-tree";
import DownloadLayer from "./downloadLayer";
import FiltersRegion from "./filtersRegion";
// import LayerMetadata from "./layerMetadata";
import { isGroup } from "@emblautec/rescursive-array-extensions";
import { toggleAppLayer } from "../../../actions/globalActions";
import { hideAllSubs } from "../../../reducers/ais";
import { getSelectedApp } from "../../../selectors/appsSelectors";
import { useStyles } from "./layerSelectorStyles";
import MetadataModal from "./Metadata/MetadataModal/MetadataModal";
import { AttributeTable } from "features/datasets/components/AttributeTable";
import { setLayerIsFiltered } from "../../../reducers/layerSelector";

const LayerSelector = () => {
    const [search, setSearch] = useState("");
    //an id
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [openLayerMetadata, setOpenLayerMetadata] = useState(false);
    const [openAttributeTable, setOpenAttributeTable] = useState(false);
    //possible duplicate with an id
    const [layer, setLayer] = useState({});
    const [featureIds, setFeatureIds] = useState([]);

    const layerGroups = useSelector(getLayerGroups);
    const fetching = useSelector(getFetchingLayers);
    const currentApp = useSelector(getSelectedApp);

    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        setOpenAttributeTable(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApp]);

    useEffect(() => {
        if (!!groups && search !== "") {
            setCollapsedToFalse();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const handleOpenLayerMetadata = useCallback((layer) => {
        setOpenLayerMetadata(true);
        setOpenAttributeTable(false);
        setLayer(layer);
    }, []);

    const handleCloseLayerMetadata = () => {
        setOpenLayerMetadata(false);
        setLayer({});
    };

    const handleOpenDownloadModal = useCallback((layer, featureIds) => {
        setOpenDownloadModal(true);
        setLayer(layer);
        setFeatureIds(featureIds);
    }, []);

    const handleCloseDownloadModal = () => {
        setOpenDownloadModal(false);
    };

    const handleOpenAttributeTable = useCallback((layer) => {
        setOpenAttributeTable(true);
        setOpenLayerMetadata(false);
        setLayer(layer);
    }, []);

    const handleCloseAttributeTable = () => {
        setOpenAttributeTable(false);
        setLayer({});
    };

    const handleHideAll = () => {
        dispatch(hideAllSubs());
        dispatch(layerSelectorActions.hideAllLayers());
        dispatch(mapActions.hideAllLayers());
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    };

    const clearSearch = () => {
        setSearch("");
    };

    const onStyleLayer = useCallback((layer) => {
        dispatch(layerSelectorActions.setShouldFilter(false));
        dispatch(layerSelectorActions.setStylerLayerId(layer.resourceId));
        dispatch(toggleAppLayer(layer.resourceId, true));
        setLayer(layer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFilterLayer = useCallback((layer) => {
        dispatch(layerSelectorActions.setShouldFilter(true));
        dispatch(layerSelectorActions.setStylerLayerId(layer.resourceId));
        dispatch(toggleAppLayer(layer.resourceId, true));
        setLayer(layer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClearFilters = useCallback((layer) => {
        dispatch(mapActions.setFilters({ filters: [], layerId: layer.resourceId }));
        dispatch(setLayerIsFiltered({ layerResourceId: layer.resourceId, value: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const layerHandlers = useMemo(
        () => ({
            handleOpenDownloadModal,
            handleOpenLayerMetadata,
            handleOpenAttributeTable,
            onStyleLayer,
            onFilterLayer,
            onClearFilters
        }), // This is done for good practice. These will never get redefined but we are less bug-prone this way
        [handleOpenDownloadModal, handleOpenLayerMetadata, handleOpenAttributeTable, onStyleLayer, onFilterLayer, onClearFilters]
    );

    const getSearchedLayers = () => {
        const filteredData = layerGroups.filterLayersAndGroupsRecursive((layerOrGroup) => layerOrGroup.name.toLowerCase().includes(search.toLowerCase()));
        let cleanData = cleanEmptySubGroups({ layers: filteredData });
        return cleanData.layers;
    };

    const cleanEmptySubGroups = (group) => {
        //This function shouldn't modify the original object
        let mappedData = [];
        group.layers.forEach((layer) => {
            if (isGroup(layer)) {
                const cleanedGroup = cleanEmptySubGroups(layer);
                if (cleanedGroup.layers.length > 0) {
                    mappedData.push(cleanedGroup);
                }
            } else mappedData.push(layer);
        });
        return { ...group, layers: mappedData };
    };

    const setCollapsedToFalse = () => {
        //Still need to make a shallow copy for the nested groups
        groups.forGroupsRecursive((group) => {
            dispatch(layerSelectorActions.toggleGroupCollapse({ groupId: group.resourceId, newCollapseValue: false }));
        });
    };

    const groups = search !== "" ? getSearchedLayers() : layerGroups;

    return (
        <div className={classes.root}>
            <div className={classes.flexContainer}>
                <Divider />

                <FiltersRegion className={classes.container} handleHideAll={handleHideAll} />
                <Divider />
                {fetching && <LinearProgress variant="indeterminate" color="primary" style={{ marginBottom: -4 }} />}

                <div className={classes.searchContainer}>
                    <Input
                        id="full-width"
                        inputProps={{ autoComplete: "off" }}
                        placeholder="Search Layers..."
                        value={search}
                        onChange={onSearch}
                        fullWidth
                        className={classes.layerInput}
                        endAdornment={
                            <InputAdornment position="end">
                                {search !== "" && (
                                    <IconButton
                                        className={classes.noPadButton + " " + classes.squareBtn}
                                        aria-label="toggle password visibility"
                                        onClick={clearSearch}
                                        size="large"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                </div>

                <div className={classes.layers} id="layer-menu">
                    <VirtualizedTree data={groups} layerHandlers={layerHandlers} />
                </div>
            </div>

            <PortalComponent>
                {openAttributeTable && (
                    <AttributeTable
                        onClose={handleCloseAttributeTable}
                        datasetId={layer.resourceId}
                        layerName={layer.name}
                        isDownloadable={layer.options.downloadable}
                        onDownloadClick={(ids) => handleOpenDownloadModal(layer, ids)}
                    />
                )}
            </PortalComponent>
            <DownloadLayer
                open={openDownloadModal}
                layer={layer}
                featureIds={featureIds}
                handleClose={handleCloseDownloadModal}
            />
            <MetadataModal
                open={openLayerMetadata}
                datasetId={layer.resourceId}
                onClose={handleCloseLayerMetadata}
                datasetName={layer.name}
            />
        </div>
    );
};

export default LayerSelector;
