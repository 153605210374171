import { createSlice } from "@reduxjs/toolkit";
import { resetProjectData } from "actions/globalActions";
import { getFeatureFlags } from "../actions/featureFlags";

type SliceState = {
    AIS: boolean;
    NOTIFICATIONS: boolean;
    LAYER_FILTERS: boolean;
    DPR: boolean;
};

const initialState: SliceState = {
    AIS: false,
    NOTIFICATIONS: false,
    LAYER_FILTERS: false,
    DPR: false
};

export const featureFlagsSlice = createSlice({
    name: "featureFlags",
    initialState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(getFeatureFlags.fulfilled, (state, { payload: featureFlags }) => {
                Object.keys(state).forEach((feature) => {
                    // did this to support features that are only defined on the frontend
                    const featureNotDefinedOnBackend = featureFlags[feature] === undefined;
                    if (featureNotDefinedOnBackend) return;

                    state[feature] = featureFlags[feature];
                });
            })
            .addCase(resetProjectData, () => initialState)
});

export const {} = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
