import makeStyles from "@mui/styles/makeStyles";

const useSearchFieldStyles = makeStyles((theme: any) => ({
    searchInput: {
        fontSize: 14,
        padding: 8
    },
    searchIcon: {
        width: 20
    }
}));

export default useSearchFieldStyles;
