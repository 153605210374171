import makeStyles from "@mui/styles/makeStyles";

const PAGINATION_HEIGHT = "170px";

const useAttributeTableContainerStyles = makeStyles((theme: any) => ({
    paperContainer: {
        position: "relative",
        borderRadius: 0,
        width: "100%",
        height: 500,
        outline: "none"
    },
    container: {
        height: "100%"
    },
    root: {
        width: "100%",
        height: "100%"
    },
    tableContainer: {
        overflowY: "auto",
        height: `calc(100% - ${PAGINATION_HEIGHT})`
    },
    noData: {
        paddingLeft: "50%"
    }
}));

export default useAttributeTableContainerStyles;
