import { useEffect, useState } from "react";
import * as digitizeActions from "../../../actions/digitize";
import {
    Checkbox,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SvgIcon,
    TextField,
    Typography
} from "@mui/material";
import { Add, Cancel, Delete, Edit, GetApp, MoreHoriz, Palette, Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { digitizeIcons } from "../../../utils/mapIcons";
import DownloadDialog from "./digitizeDownloadDialog";
import { digitizeLayerToStyle } from "../../../utils/digitizeUtils";
import { getDigitizeFeatures } from "../../../selectors/digitizeSelectors";
import { useDigitizeLayerStyles } from "./styles/digitizeLayerStyles";
import toastr from "../../../utils/customToastr";
import { useMainDraw } from "../../../utils/customHooks/map/useMainDraw";

const LayerMenu = (props) => {
    function removeLayer(e, layer) {
        e.stopPropagation();

        const toastrConfirmOptions = {
            onOk: () => props.handleRemoveLayer(layer),
            onCancel: () => { }
        };
        toastr.confirm(`Are you sure you want to delete layer: ${props.layer.name} ?`, toastrConfirmOptions);
    }

    return (
        <Menu
            id="digitize-layer-menu"
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={() => props.handleClose(props.layer)}
        >
            <MenuItem onClick={() => props.drawingFeature(props.layer)}>
                <ListItemIcon>
                    <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Add feature" />
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.setSelectedLayer(props.layer);
                    props.setIsStylerOpen(true);
                }}
            >
                <ListItemIcon>
                    <Palette fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Change style" />
            </MenuItem>
            <MenuItem onClick={props.handleClickOpenDialog}>
                <ListItemIcon>
                    <GetApp fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Download" />
            </MenuItem>
            <MenuItem onClick={(e) => removeLayer(e, props.layer)}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Delete layer" />
            </MenuItem>
        </Menu>
    );
};

const DigitizeLayer = ({
    layer,
    selectedLayer,
    setSelectedLayer,
    onDraw,
    setIsStylerOpen,
    resetDrawingState,
    deselectAllFeaturesAndLabels
}) => {
    const classes = useDigitizeLayerStyles();

    const draw = useMainDraw();

    const [showEditIcon, setShowEditIcon] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedText, setEditedText] = useState(layer.namePretty);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const digitizeFeatures = useSelector(getDigitizeFeatures);

    const dispatch = useDispatch();

    useEffect(() => {
        deselectAllFeaturesAndLabels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorEl, editMode]);

    const handleRemoveLayer = (layer) => {
        layer.features &&
            layer.features.forEach((feature) => {
                let featureToBeDeleted = digitizeFeatures.find((element) => element.id === feature);
                dispatch(digitizeActions.removeFeature(featureToBeDeleted));
                draw.delete(featureToBeDeleted.id);
            });

        dispatch(digitizeActions.removeLayer(layer));
        dispatch(digitizeActions.removeAllDigitizeStylesForLayer(layer.datasetId));
    };

    const handleSave = (layer) => {
        let newLayer = { ...layer };
        newLayer.namePretty = editedText;
        newLayer.name = editedText.toLowerCase();
        dispatch(digitizeActions.editLayer(newLayer));
        dispatch(digitizeActions.renameDigitizeStylesNameForLayer(digitizeLayerToStyle(newLayer)));
        setEditMode(false);
        setEditedText(newLayer.namePretty);
    };

    const handleEdit = (e) => {
        e.nativeEvent.stopImmediatePropagation();
        setEditMode(true);
    };

    const handleEditMode = (e, layer) => {
        if (e.keyCode === 13) {
            handleSave(layer);
        } else if (e.keyCode === 27) {
            setEditMode(false);
            setEditedText("");
        }
    };

    const handleClick = (e, layer) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectLayer = (e, layer) => {
        // e.nativeEvent.stopImmediatePropagation();
        setEditMode(true);
        setSelectedLayer(layer);
    };

    const drawingFeature = (layer) => {
        handleClose();
        onDraw(layer);
    };

    const handleClickOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleChange = (e, layer) => {
        e.stopPropagation();

        deselectAllFeaturesAndLabels();

        const selected = e.target.checked;
        dispatch(digitizeActions.toggleDigitizeLayer(layer.id, selected));

        layer.features.forEach((featureId) => {
            draw.setFeatureProperty(featureId, "hidden", !selected);
            draw.add(draw.get(featureId));
        });

        resetDrawingState();
    };

    return (
        <Grid
            className={selectedLayer.id === layer.id ? classes.selectedLayer : classes.unselectedLayer}
            item
            xs={12}
            key={layer.id}
            paddingX={2}
        >
            <Grid container alignItems="center">
                <Grid item xs={1}>
                    <Grid container justifyContent="center">
                        <Checkbox
                            checked={layer.selected}
                            onChange={(e) => handleChange(e, layer)}
                            inputProps={{ "aria-label": "show feature in legend checkbox" }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={10}
                    className={classes.editFieldContainer}
                    onMouseEnter={() => setShowEditIcon(true)}
                    onMouseLeave={() => setShowEditIcon(false)}
                >
                    <Grid container alignItems="center" wrap="nowrap">
                        {editMode ? (
                            <TextField
                                id="digitize-edit-layer-name"
                                className={classes.textField}
                                placeholder="Layer name"
                                size="small"
                                value={editedText}
                                onKeyDown={(e) => handleEditMode(e, layer)}
                                onChange={(e) => setEditedText(e.target.value)}
                            />
                        ) : (
                            <div className={classes.layerName} onClick={(e) => handleSelectLayer(e, layer)}>
                                <SvgIcon className={classes.buttonIcon}>{digitizeIcons[layer.icon]}</SvgIcon>
                                <Typography>{layer.namePretty}</Typography>
                            </div>
                        )}
                        {showEditIcon && !editMode && (
                            <IconButton size="small" onClick={(e) => handleEdit(e)}>
                                <Edit />
                            </IconButton>
                        )}
                        {showEditIcon && editMode && (
                            <IconButton size="small" onClick={() => handleSave(layer)}>
                                <Save />
                            </IconButton>
                        )}
                        {showEditIcon && editMode && (
                            <IconButton size="small" onClick={() => setEditMode(false)}>
                                <Cancel />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container justifyContent="flex-end">
                        <IconButton
                            className={classes.layerMoreButton}
                            onClick={(e) => handleClick(e, layer)}
                            size="medium"
                        >
                            <MoreHoriz />
                        </IconButton>
                        <LayerMenu
                            classes={classes}
                            anchorEl={anchorEl}
                            handleClose={handleClose}
                            setIsStylerOpen={setIsStylerOpen}
                            setSelectedLayer={setSelectedLayer}
                            drawingFeature={drawingFeature}
                            handleRemoveLayer={handleRemoveLayer}
                            handleClickOpenDialog={handleClickOpenDialog}
                            layer={layer}
                        />
                        <DownloadDialog open={open} onClose={handleCloseDialog} layer={layer} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DigitizeLayer;
