import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import * as sidebarAndDrawerActions from "../../../actions/sidebarAndDrawer";
import * as layerSelectorActions from "../../../reducers/layerSelector";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import OpenPanel from "@mui/icons-material/NavigateNext";
import { Box, CircularProgress, Typography } from "@mui/material";
import { AIS_PATH_SUFFIX, SETTINGS_PATH_SUFFIX } from "../../../constants/routes";
import AISComponent from "../ais/AIS";
import toolsDict, { layerSelectorTool } from "../../../constants/tools";
import { getTools } from "features/mapTools/selectors";
import { getIsSidebarOpen } from "../../../selectors/sidebarAndDrawer";
import { getFeatureFlags } from "../../../selectors/featureFlagsSelectors";

import LayerEditor from "../styler/layerEditor";
import { getStylerLayerId } from "../../../selectors/layerSelector";
import { getSelectedApp } from "../../../selectors/appsSelectors";
import { useMainDraw } from "../../../utils/customHooks/map/useMainDraw";
import AppsPicker from "../AppsPicker/AppsPicker";
import Logo from "app/components/Logo/Logo";
import { useStyles } from "./styles";
import Settings from "features/settings/Settings";
import { hideLogoQueryParamName } from "constants/map/queryParams";

const SidebarRoot = ({ match }) => {
    const classes = useStyles();

    const sidebarState = useSelector(getIsSidebarOpen);
    const tools = useSelector(getTools);
    const featureFlags = useSelector(getFeatureFlags);
    const stylerLayerId = useSelector(getStylerLayerId);
    const currentApp = useSelector(getSelectedApp);

    const dispatch = useDispatch();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const hideLogo = searchParams.get(hideLogoQueryParamName);

    const draw = useMainDraw();

    useEffect(() => {
        dispatch(layerSelectorActions.setStylerLayerId(null));
        draw?.deleteAll(); // The draw is registered at map level so we need to clear it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApp]);

    return (
        <div className="sidebar-wrapper">
            <div className={sidebarState ? "sidebar" : "sidebar hide"}>
                <div className="collapse-button" onClick={() => dispatch(sidebarAndDrawerActions.toggleSidebarOpen())}>
                    <OpenPanel
                        className={`collapse-button__icon ${sidebarState && `rotate180`}`}
                        style={{ fontSize: "1.7rem" }}
                    />
                </div>
                {currentApp?.public && hideLogo ? (
                    <></>
                ) : (
                    <AppBar position="static" color="primary" className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <Logo isPublic={currentApp?.public} />

                            {!currentApp?.public && <AppsPicker appName={currentApp?.name} />}
                        </Toolbar>
                    </AppBar>
                )}
                <div className={classes.root}>
                    {stylerLayerId !== null && <LayerEditor isDigitizeLayer={false} />}
                    {!currentApp?.name && (
                        <Box paddingTop={15} display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress size={50} />
                            <Typography variant="h6" align="center">
                                Getting your app ready
                            </Typography>
                        </Box>
                    )}
                    {currentApp?.name && (
                        <Switch>
                            <Route
                                exact
                                path={`${match.path}/${layerSelectorTool.routeSuffix}`}
                                component={layerSelectorTool.component}
                            />
                            {tools.map((tool) => {
                                const correspondingTool = toolsDict[tool.name];

                                return correspondingTool ? (
                                    <Route
                                        path={`${match.path}/${correspondingTool.routeSuffix}`}
                                        component={correspondingTool.component}
                                        key={tool.name}
                                    />
                                ) : null;
                            })}
                            {featureFlags.AIS && (
                                <Route path={`${match.path}/${AIS_PATH_SUFFIX}`} component={AISComponent} />
                            )}

                            <Route path={`${match.path}/${SETTINGS_PATH_SUFFIX}`} component={Settings} />

                            <Redirect to={`${match.path}/${layerSelectorTool.routeSuffix}`} />
                        </Switch>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SidebarRoot;
