import { useFetchDprDatasetColumnsQuery, useFetchLocationStatusHistoryQuery } from "features/dprIntegration/api";
import { getInfoboxes } from "features/infobox/selectors";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks/useAppSelector";

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature;
};
const useDprLocationHistory = ({ feature }: Props) => {
    const infoboxes = useAppSelector(getInfoboxes);

    const { appId } = useParams<{ appId: string }>();

    const infoboxToUse = useMemo(() => {
        const datasetId = feature.sourceLayer;

        return (
            infoboxes.find(
                (infobox) =>
                    (!infobox.applicationId || infobox.applicationId === appId) && infobox.datasetId === datasetId
            )?.name ?? "default"
        );
    }, [feature.sourceLayer]);

    const {
        data: dprDatasetColumns = null,
        isLoading: columnsLoading,
        isError: columnsError
    } = useFetchDprDatasetColumnsQuery(feature.sourceLayer, { skip: infoboxToUse !== "dpr" });

    const {
        data: locationHistory = null,
        isLoading: historyLoading,
        isError: historyError
    } = useFetchLocationStatusHistoryQuery(
        {
            datasetId: feature.sourceLayer,
            locationName: feature.properties?.[dprDatasetColumns?.locationColumn.name ?? ""] ?? ""
        },
        { skip: infoboxToUse !== "dpr" || columnsLoading || columnsError || !dprDatasetColumns?.locationColumn }
    );

    return {
        locationHistory,
        isLoading: columnsLoading || historyLoading,
        isError: columnsError || historyError,
        shouldDisplayInfo: infoboxToUse === "dpr"
    };
};

export default useDprLocationHistory;
