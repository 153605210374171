import { Grid, Slider } from "@mui/material";
import DndReorderContainer from "components/DndReorderContainer/DndReorderContainer";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import { MAX_HOUR, MAX_INTERVAL_LENGTH, MIN_HOUR } from "features/zander/constants";
import { ZanderParameter as ParameterType } from "features/zander/models/ZanderParameter";
import { ZanderSheet as SheetType } from "features/zander/models/ZanderSheet";
import percentilesSchema from "features/zander/validators/percentilesSchema";
import weatherWindowsSchema from "features/zander/validators/weatherWindowsSchema";
import { SyntheticEvent, useMemo } from "react";
import toastr from "utils/customToastr";
import LimitsCreator from "../LimitsCreator/LimitsCreator";
import ZanderParameter from "../ZanderParameter/ZanderParameter";
import useZanderSheetStyles from "./styles";

type Props = {
    sheet: SheetType;
    onSheetChanged: (sheet: SheetType) => void;
};

const ZanderSheet = ({ sheet, onSheetChanged }: Props) => {
    const classes = useZanderSheetStyles();

    const onWeatherWindowsChanged = (newWeatherWindows: number[]) => {
        onSheetChanged({ ...sheet, weatherWindowDurations: newWeatherWindows });
    };

    const onParameterChanged = (newParameter: ParameterType, index: number) => {
        const newParameters = sheet.parameters.map((parameter, i) => (index === i ? newParameter : parameter));

        onSheetChanged({ ...sheet, parameters: newParameters });
    };

    const onPercentilesChanged = (newPercentiles: number[]) =>
        onSheetChanged({ ...sheet, percentiles: newPercentiles });

    const generateWeatherWindowsLimits = () => {
        if (sheet.startHour === MIN_HOUR && sheet.endHour === MAX_HOUR) {
            return sheet.weatherWindowDurations.map((duration) => ({
                value: duration,
                error: false
            }));
        }

        const intervalLength = sheet.endHour - sheet.startHour;

        return sheet.weatherWindowDurations.map((duration) => ({
            value: duration,
            error: duration > intervalLength
        }));
    };

    const onHourIntervalChanged = (_: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
        const intervalLength = sheet.endHour - sheet.startHour;
        const newIntervalLength = newValue[1] - newValue[0];

        if (intervalLength === MAX_INTERVAL_LENGTH && newIntervalLength !== MAX_INTERVAL_LENGTH) {
            toastr.info("Setting a time of day interval will limit the possible weather windows duration values");
        }

        onSheetChanged({
            ...sheet,
            startHour: newValue[0],
            endHour: newValue[1]
        });
    };

    const onParameterOrderChange = (index: number, newIndex: number, moveBelow: boolean) => {
        const parameter = sheet.parameters[index];
        const newParameters = sheet.parameters.filter((_, paramIndex) => index !== paramIndex);
        const insertPosition = newIndex - Number(index < newIndex) + Number(moveBelow);
        newParameters.splice(insertPosition, 0, parameter);
        onSheetChanged({ ...sheet, parameters: newParameters });
    };

    const marks = useMemo(
        () =>
            [...Array(25).keys()].map((i) => ({
                value: i,
                label:
                    i % 8 === 0 ? (
                        <div
                            style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                        >
                            <div style={{ width: 0, height: 10, border: "1px solid" }} />
                            {i}
                        </div>
                    ) : (
                        <div style={{ width: 0, height: 10, border: "1px solid", opacity: "0.3" }}></div>
                    )
            })),
        []
    );

    return (
        <div className={classes.sheet}>
            <Grid container direction="column">
                <CustomTypography variant="h6" fontWeight="bold" gutterBottom>
                    Settings
                </CustomTypography>
                <div className={classes.sectionCard}>
                    <Grid item className={classes.spacingBottom}>
                        <CustomTypography variant="body2" fontWeight="bold">
                            Minimum weather window duration [h]
                        </CustomTypography>
                    </Grid>
                    <Grid item>
                        <LimitsCreator
                            limits={generateWeatherWindowsLimits()}
                            onLimitsChanged={onWeatherWindowsChanged}
                            text="Save"
                            validationSchema={weatherWindowsSchema}
                        />
                    </Grid>
                </div>
                <div className={classes.sectionCard}>
                    <Grid item className={classes.spacingBottom}>
                        <CustomTypography variant="body2" fontWeight="bold">
                            Time of day limitation (UTC)
                        </CustomTypography>
                    </Grid>
                    <Grid item className={`${classes.spacingTop} ${classes.sliderContainer}`}>
                        <div className={classes.slider}>
                            <Slider
                                value={[sheet.startHour, sheet.endHour]}
                                onChangeCommitted={onHourIntervalChanged}
                                valueLabelDisplay="auto"
                                size="medium"
                                step={1}
                                marks={marks}
                                min={0}
                                max={24}
                            />
                        </div>
                    </Grid>
                </div>
                <div className={classes.sectionCard}>
                    <Grid item className={classes.spacingBottom}>
                        <CustomTypography variant="body2" fontWeight="bold">
                            Persistence exceedance percentiles [%]
                        </CustomTypography>
                    </Grid>
                    <Grid item className={classes.percentiles}>
                        <LimitsCreator
                            limits={sheet.percentiles.map((percentile) => ({
                                value: percentile,
                                error: false
                            }))}
                            onLimitsChanged={onPercentilesChanged}
                            text="Save"
                            validationSchema={percentilesSchema}
                        />
                    </Grid>
                </div>
                <CustomTypography variant="h6" fontWeight="bold">
                    Criteria
                </CustomTypography>
                <div>
                    {sheet.parameters.map((parameter, index) => (
                        <DndReorderContainer
                            index={index}
                            type="zanderParameter"
                            onOrderChange={onParameterOrderChange}
                            key={index}
                        >
                            <ZanderParameter
                                parameter={parameter}
                                onParameterChanged={(newParameter) => onParameterChanged(newParameter, index)}
                            />
                        </DndReorderContainer>
                    ))}
                </div>
            </Grid>
        </div>
    );
};

export default ZanderSheet;
