import coreReducer from "features/core/slice";
import infoboxReducer from "features/infobox/slice";
import mapToolsReducer from "features/mapTools/slice";
import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import authReducer from "../features/auth/slice";
import healthReducer from "../features/health/slice";
import aisReducer from "../reducers/ais";
import { appsReducer } from "../reducers/apps";
import { digitizeReducer } from "../reducers/digitize";
import featureFlagsReducer from "../reducers/featureFlags";
import layerSelectorReducer from "../reducers/layerSelector";
import { loginReducer } from "../reducers/login";
import mapReducer from "../reducers/map";
import metadataSchemaReducer from "../reducers/metadataSchema";
import { printReducer } from "../reducers/print";
import { sidebarAndDrawerReducer } from "../reducers/sidebarAndDrawer";
import { styleReducer } from "../reducers/style";
import baseAtlasApi from "./baseAtlasApi";

export const rootReducer = combineReducers({
    map: mapReducer,
    login: loginReducer,
    style: styleReducer,
    layerSelector: layerSelectorReducer,
    sidebarAndDrawer: sidebarAndDrawerReducer,
    mapTools: mapToolsReducer,
    auth: authReducer,
    digitize: digitizeReducer,
    apps: appsReducer,
    print: printReducer,
    toastr: toastrReducer, // <- Mounted at toastr.
    featureFlags: featureFlagsReducer,
    AIS: aisReducer,
    metadataSchema: metadataSchemaReducer,
    core: coreReducer,
    health: healthReducer,
    infobox: infoboxReducer,
    [baseAtlasApi.reducerPath]: baseAtlasApi.reducer
});
