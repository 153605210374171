import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    headerButtons: {
        height: 48,
        display: "flex",
        alignItems: "center",
        zIndex: 1,
        right: 0,
        gap: 12,
        position: "absolute"
    }
}));
