import { DatasetProperties } from "../../../models/DatasetProperties";
import useAttributeTableStyles from "./styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AttributeRow from "../AttributeRow/AttributeRow";
import AttributeTableTableHead from "../AttributeTableTableHead/AttributeTableTableHead";

type Props = {
    features: DatasetProperties[];
    onToggleFeature: (id: number) => void;
    onToggleSelectAllFeatures: () => void;
    isFeatureSelected: (id: number) => boolean;
    areAllFeaturesSelected: boolean;
    isAtLeastOneFeatureSelected: boolean;
    orderBy: string;
    orderDesc: boolean;
    onSortBy: (column: string) => void;
    isDownloadable: boolean;
    onFlyToFeature: (boundingBox: string) => void;
};

const AttributeTable = ({
    features,
    onToggleFeature,
    onToggleSelectAllFeatures,
    isFeatureSelected,
    areAllFeaturesSelected,
    isAtLeastOneFeatureSelected,
    isDownloadable,
    orderBy,
    orderDesc,
    onSortBy,
    onFlyToFeature
}: Props) => {
    const classes = useAttributeTableStyles();

    const featureNames = Object.keys(features[0])
        .filter((name) => name !== "bounding_box")
        .map((name) => ({
            name,
            isNumeric: typeof features[0][name] === "number"
        }));

    return (
        <TableContainer className={classes.tableContainer}>
            <Table
                className={classes.table}
                stickyHeader
                aria-labelledby="tableTitle"
                size="small"
                aria-label="attributes table"
            >
                <AttributeTableTableHead
                    isDownloadable={isDownloadable}
                    areAllRowsSelected={areAllFeaturesSelected}
                    isAtLeastOneRowSelected={isAtLeastOneFeatureSelected}
                    onToggleSelectAll={onToggleSelectAllFeatures}
                    featureNames={featureNames}
                    orderBy={orderBy}
                    orderDesc={orderDesc}
                    onSortBy={onSortBy}
                />
                <TableBody>
                    {features.map((feature, index) => {
                        const { bounding_box, ...rest } = feature;

                        return (
                            <AttributeRow
                                feature={rest}
                                isDownloadable={isDownloadable}
                                onFeatureCheckToggle={() => onToggleFeature(feature.ogc_fid)}
                                onRowClick={() => onFlyToFeature(bounding_box)}
                                isChecked={isFeatureSelected(feature.ogc_fid)}
                                key={`row-${index}`}
                                label={`attributes-table-checkbox-${index}`}
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AttributeTable;
