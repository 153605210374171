import axios from "axios";
import config from "../config";

export const coreApiClient = axios.create({
    baseURL: config.coreApiUrl,
    timeout: 300000,
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: false
});
