import { FC } from "react";
import OverflowTip from "../../../common/OverflowTip/OverflowTip";
import useDefaultInfoboxStyles from "features/infobox/components/DefaultInfobox/styles";
import { BuildProperties } from "utils/infoBoxUtils";
import { Collapse, IconButton, Tooltip } from "@mui/material";
import copyButton from "utils/icons/copy-icon.svg";
import { setSidebarOpen } from "actions/sidebarAndDrawer";
import { setStylerLayerId } from "reducers/layerSelector";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { ExpandLess } from "@mui/icons-material";
import DprProperties from "../DprProperties/DprProperties";

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature;
    featureIndex: number;
    onCopyClick: (index: number) => void;
    sourceToNameMapping: Record<string, string>;
    collapsed: boolean;
    hasMultipleFeatures: boolean;
    toggleFeature: (index: number) => void;
};

const VectorFeature: FC<Props> = ({
    feature,
    featureIndex,
    onCopyClick,
    sourceToNameMapping,
    collapsed,
    hasMultipleFeatures,
    toggleFeature
}) => {
    const classes = useDefaultInfoboxStyles();

    const dispatch = useAppDispatch();

    let { featurePropertiesElements, hasPropertiesToDisplay } = BuildProperties(feature, classes);

    const isBoat = () => {
        return !!feature.properties?.MMSI;
    };

    const onEditLayerStyleClick = () => {
        dispatch(setSidebarOpen(true));
        dispatch(setStylerLayerId(feature.layer.metadata.resourceId));
    };

    const table = (
        <table className={classes.table}>
            <tbody>
                {featurePropertiesElements}
                <DprProperties feature={feature} />
            </tbody>
        </table>
    );

    return (
        <div key={featureIndex}>
            <div className={classes.featureHeaderContainer}>
                <div className={classes.featureHeader}>
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <IconButton
                                onClick={(_) => toggleFeature(featureIndex)}
                                className={classes.innerCollapseBtn}
                                size="small"
                            >
                                <ExpandLess className={collapsed ? classes.expandedLeft : classes.collapsed} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <OverflowTip variant="subtitle1" textWeight="bold">
                        {sourceToNameMapping[feature.sourceLayer || feature.source]}
                    </OverflowTip>
                </div>
                <div
                    className={
                        hasMultipleFeatures
                            ? classes.featureButtonsMultipleFeatures
                            : classes.featureButtonsSingleFeature
                    }
                >
                    {hasPropertiesToDisplay && (
                        <Tooltip title="Copy the layer's name and properties" placement="bottom" arrow>
                            <IconButton
                                className={classes.squareBtn}
                                onClick={() => onCopyClick(featureIndex)}
                                size="small"
                            >
                                <img src={copyButton} alt="" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {!isBoat() && (
                        <Tooltip title="Edit layer style" placement="bottom">
                            <IconButton
                                className={classes.squareBtn}
                                onClick={() => onEditLayerStyleClick()}
                                size="small"
                            >
                                <ColorLensIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <div className={classes.borderBox}>{table}</div>
                </Collapse>
            ) : (
                table
            )}
        </div>
    );
};

export default VectorFeature;
