import { useEffect } from "react";
import { useMap } from "@emblautec/react-map-gl";

type Props = {
    mapId: string;
};

const HideAttribution = ({ mapId }: Props) => {
    const { current } = useMap();

    useEffect(() => {
        const mapBoxLogo = document.querySelector(`#${mapId} .mapboxgl-ctrl-logo`);
        if (mapBoxLogo) mapBoxLogo.setAttribute("style", "visibility: hidden");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    return null;
};

export default HideAttribution;
