import { makeStyles } from "@mui/styles";

export const useLegendEntryStyles = makeStyles(() => ({
    singleLabelEntryContainer: {
        display: "flex",
        alignItems: "center",
        textTransform: "capitalize"
    },
    multiLabelEntryContainer: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 21,
        height: "100%"
    },
    multiLabelHeaderText: {
        textTransform: "capitalize"
    },
    labelText: {
        flexGrow: 1,
        textTransform: "capitalize"
    },
    multiLabelText: {
        flexGrow: 1,
        textTransform: "capitalize",
        fontSize: 14,
        lineHeight: "14px"
    },
    labelsContainer: {
        padding: 20,
        overflowY: "auto",
        overflowX: "hidden"
    },
    dividerSpacing: {
        marginTop: 16,
        marginBottom: 16
    }
}));
