import HeaderButtons from "app/components/HeaderButtons/HeaderButtons";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import ProjectsPicker from "features/core/components/ProjectsPicker/ProjectsPicker";
import { HealthEnum } from "features/health/model/HealthEnum";
import { getHealth } from "features/health/selectors";
import { setHealth } from "features/health/slice";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useStyles } from "./styles";
import Header from "app/components/Header/Header";

type Params = {
    clientId: string;
    projectId: string;
};

export enum ErrorTypes {
    Unexpected,
    NoResources,
    NoPermission,
    PendingUser
}

type HistoryState = { errorType?: ErrorTypes };

const errorMessage = {
    [ErrorTypes.Unexpected]: "An unexpected error occurred",
    [ErrorTypes.NoResources]: "Your resources have not yet been created",
    [ErrorTypes.NoPermission]: "You do not have permission to access this project",
    [ErrorTypes.PendingUser]: "You have no role assigned in the WindGIS system"
};

const ErrorView = () => {
    const classes = useStyles();
    const { clientId, projectId } = useParams<Params>();
    const health = useAppSelector(getHealth);
    const dispatch = useAppDispatch();
    const history = useHistory<HistoryState>();

    const errorType = history.location.state?.errorType ?? ErrorTypes.Unexpected;

    useEffect(() => {
        if (health === HealthEnum.Unknown) {
            history.push(`/${clientId}/${projectId}`);
        }

        return () => {
            dispatch(setHealth(HealthEnum.Unknown));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [health]);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Header />
                <HeaderButtons />
            </div>
            <div className={`${classes.errorContainer}`}>
                <CustomTypography variant="h1" textWeight="semibold" color="inherit">
                    Error
                </CustomTypography>
                <CustomTypography variant="h2" textWeight="semibold" color="inherit">
                    {errorMessage[errorType]}
                </CustomTypography>
                <CustomTypography variant="h6" textWeight="semibold" color="inherit">
                    Please contact an administrator
                </CustomTypography>
            </div>
        </div>
    );
};

export default ErrorView;
