import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MapCopyright from "../../../map/printMapExtraInfo/mapCopyright";
import MapTitle from "../../../map/printMapExtraInfo/mapTitle";
import MapDate from "../../../map/printMapExtraInfo/mapDate";
import MapNotes from "../../../map/printMapExtraInfo/mapNotes";
import { getPrintFeatures } from "../../../../selectors/printSelectors";
import widgetTypes from "../../../../utils/tools/widgets";
import LegendEntries from "../../../legend/LegendEntries/LegendEntries";
import { useStyles } from "./styles";
import { useLegendEntryStyles } from "./legendEntryStyles";
import { getToggledWidgetsSet } from "features/mapTools/selectors";

const MapLegendLandscape = () => {
    // eslint-disable-next-line
    const printFeatures = useSelector(getPrintFeatures);
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT);
    const classes = useStyles({ printFeatures });
    const legendEntryClasses = useLegendEntryStyles();

    const spacingOptions = {
        singleEntryBreakpointSpacing: 12,
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 6,
        multiLabelItemVerticalBreakpointSpacing: 12
    };

    return (
        <Grid id="modal-info-landscape" container className={classes.root}>
            <Grid item xs={12} className={classes.innerRoot}>
                <Grid container>
                    <Grid item xs={12} className={classes.sectionStyle}>
                        {printFeatures.showMapTitle && (
                            <MapTitle mapTitle={printFeatures.mapTitle} orientation="landscape" modal={modalOpen} />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center">
                            {/* TEMPORARILY DISABLED */}
                            {/* <Grid item xs={4}>
                                {printFeatures.showMapLogo && <MapLogo modal={modalOpen} />}
                            </Grid> */}
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-around" alignItems="center">
                                    {printFeatures.showMapCopyright && (
                                        <MapCopyright
                                            mapCopyright={printFeatures.mapCopyright}
                                            modal={modalOpen}
                                            showMapLegend={printFeatures.showMapLegend}
                                        />
                                    )}
                                    {printFeatures.showMapDate && (
                                        <MapDate
                                            mapDateFormat={printFeatures.mapDateFormat}
                                            modal={modalOpen}
                                            showMapLegend={printFeatures.showMapLegend}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.titlecontainer}>
                        <Typography className={classes.legendTitle}>Legend</Typography>
                    </Grid>
                    <Grid item xs={12} alignContent="flex-start" className={classes.labelsContainer}>
                        <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
                    </Grid>
                    <Grid item xs={12}>
                        {printFeatures.showMapNotes && (
                            <MapNotes modal={modalOpen} mapNotes={printFeatures.mapNotes} orientation="landscape" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MapLegendLandscape;
