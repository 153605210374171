import { AppBar, Toolbar } from "@mui/material";
import ProjectsPicker from "features/core/components/ProjectsPicker/ProjectsPicker";
import { useStyles } from "./styles";
import Logo from "../Logo/Logo";

const Header = () => {
    const classes = useStyles();
    return (
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <Logo />
                <ProjectsPicker />
            </Toolbar>
        </AppBar>
    );
};

export default Header;
