import { MapLayer } from "../../model/map/MapLayer";
import { StyleProperty } from "../../model/style/StyleProperty";

type MakeMapLayerParams = {
    layer: MapLayer;
    paints: any;
    layouts: any;
    zoomRange: { minZoom: number; maxZoom: number };
};
// takes in a layer from the map, paints, layouts and zoomRange from the  reducer structure
export const makeMapLayer = ({ layer, paints, layouts, zoomRange }: MakeMapLayerParams) => {
    const mapLayer = {
        id: layer.layerId,
        type: layer.type,
        paint: propertiesToLayerPropertiesObj(paints),
        layout: propertiesToLayerPropertiesObj(layouts),
        beforeId: layer.drawUnderId,
        source: layer.sourceId,
        minzoom: zoomRange.minZoom,
        maxzoom: zoomRange.maxZoom,
        metadata: { resourceId: layer.resourceId }
    };

    if (layer.sourceName) mapLayer["source-layer"] = layer.sourceName;

    return mapLayer;
};

const propertiesToLayerPropertiesObj = (properties: StyleProperty[]) => {
    return properties.reduce((acc, prop) => {
        if (prop.title === "Legend") return acc;
        acc[prop.name] = prop.value;
        return acc;
    }, {});
};
