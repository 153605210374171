import useAuthentication from "app/hooks/useAuthentication";
import { FC, MutableRefObject, useEffect, useRef } from "react";

type Props = {
    getAccessTokenRef: MutableRefObject<() => string>;
};

const TokenLoader: FC<Props> = ({ getAccessTokenRef }) => {
    const { getAccessToken } = useAuthentication();
    const accessTokenRef = useRef("");

    getAccessTokenRef.current = () => {
        getAccessToken().then((token) => {
            accessTokenRef.current = token;
        });

        return accessTokenRef.current;
    };

    useEffect(() => {
        getAccessToken().then((token) => (accessTokenRef.current = token));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default TokenLoader;
