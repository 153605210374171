import { makeStyles } from "@mui/styles";

export const useEraInfoboxStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: "white",
        width: 500,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",

        [theme.breakpoints.down("sm")]: {
            left: 8,
            bottom: 8,
            right: 8
        }
    },
    header: {
        paddingBlock: theme.spacing(1),
        paddingInline: theme.spacing(2),
        borderBottom: "1px solid " + theme.customColors.borderColor
    },
    content: {
        paddingInline: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflow: "auto",
    },
    item: {
        height: 44,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        '&:not(:last-child)': {
            borderBottom: "1px solid " + theme.customColors.borderColor,
        },
    },
    features: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        marginBottom: 8,
        padding: "0px 8px 8px 8px",
        backgroundColor: "white",
    },
    featureHeaderContainer: {
        display: "flex",
        position: "sticky",
        top: 0,
        borderTop: "8px white solid",
        borderBottom: "2px solid ",
        marginTop: "-8",
        backgroundColor: "white"
    },
    copyBtn: {
        backgroundColor: "white"
    },
    featureHeader: {
        fontSize: 14,
        fontWeight: "bold",
        padding: 8,
        paddingLeft: 16,
        flexGrow: 1
    },
    propertyValue: {
        overflow: "hidden",
        padding: 8,
        fontSize: 14,
        fontWeight: "normal",
        fontFamily: "Roboto Helvetica Neue sans-serif",
        borderBottom: "whitesmoke 1px solid"
    },
    table: {
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse",
        marginBottom: theme.spacing(0.5),
        backgroundColor: "white"
    },
    squareBtn: {
        width: 32,
        height: 32,
        borderRadius: 3
    }
}));
