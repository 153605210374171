import baseAtlasApi from "../../store/baseAtlasApi";
import { DatasetProperties } from "./models/DatasetProperties";

type GetDatasetPropertiesQuery = {
    datasetId: string;
    page: number;
    searchText?: string;
    rowsPerPage?: number;
    orderBy?: string;
    orderByDescending?: boolean;
};

type GetDatasetPropertiesResult = { properties?: DatasetProperties[] };

type GetDatasetRowCountQuery = {
    datasetId: string,
    searchText?: string,
};

const DATASET_JSON_EXPIRATION_IN_SECONDS = 10;

const datasetsApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchDatasetProperties: build.query<GetDatasetPropertiesResult, GetDatasetPropertiesQuery>({
            query: ({ datasetId, page, rowsPerPage, searchText, orderBy, orderByDescending }) => {
                const params = {
                    ...(!!searchText && { searchText }),
                    ...(!!rowsPerPage && { rowsPerPage }),
                    ...(!!orderBy && { orderBy }),
                    ...(!!orderByDescending && { orderByDescending })
                };

                return {
                    url: `dataset/${datasetId}/json/${page}`,
                    method: "GET",
                    params
                };
            },
            keepUnusedDataFor: DATASET_JSON_EXPIRATION_IN_SECONDS
        }),
        fetchDatasetRowCount: build.query<number, GetDatasetRowCountQuery>({
            query: ({ datasetId, searchText }) => ({ url: `dataset/${datasetId}/json/count`, method: "GET", params: { ...(!!searchText && { searchText }) } })
        })
    })
});

export const {
    useFetchDatasetPropertiesQuery,
    useFetchDatasetRowCountQuery,
    usePrefetch: useDatasetsPrefetch
} = datasetsApi;
