import { HubConnection, HubConnectionBuilder, IHubProtocol, LogLevel } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import config from "config";

type EventType = {
    methodName: string;
    handler: (...args: any[]) => void;
};

type ReturnType = {
    addEvent: (methodName: string, handler: (...args: any[]) => void) => void;
    removeEvent: (methodName: string) => void;
    isConnectionStarted: boolean;
    connectionId: string | null;
};

const logLevel = process.env.NODE_ENV === "production" ? LogLevel.None : LogLevel.Information;

const initHubConnection = (path: string, tokenFactory?: () => string | Promise<string>, protocol?: IHubProtocol) => {
    let connectionBuilder = new HubConnectionBuilder().configureLogging(logLevel).withAutomaticReconnect();

    if (tokenFactory) {
        connectionBuilder = connectionBuilder.withUrl(config.apiUrl + path, {
            accessTokenFactory: tokenFactory
        });
    } else {
        connectionBuilder = connectionBuilder.withUrl(config.apiUrl + path);
    }

    if (protocol) {
        connectionBuilder = connectionBuilder.withHubProtocol(protocol);
    }

    return connectionBuilder.build();
};

const useSignalR = (
    path: string,
    tokenFactory?: () => string | Promise<string>,
    hubProtocol?: IHubProtocol
): ReturnType => {
    const [connection, setConnection] = useState<HubConnection>(() =>
        initHubConnection(path, tokenFactory, hubProtocol)
    );
    const [events, setEvents] = useState<EventType[]>([]);
    const [isConnectionStarted, setIsConnectionStarted] = useState(false);

    useEffect(() => {
        if (!(connection.baseUrl === config.apiUrl + path)) {
            setConnection(initHubConnection(path, tokenFactory));
        }

        connection
            .start()
            .then(() => {
                console.log("websocket connect succesfully to " + path);
                setIsConnectionStarted(true);
            })
            .catch(() => {
                console.error("Can't connect websocket");
                setIsConnectionStarted(false);
            });

        return () => {
            connection.stop();
            setIsConnectionStarted(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, tokenFactory]);

    useEffect(() => {
        events.forEach((e) => connection.on(e.methodName, e.handler));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection]);

    const addEvent = (methodName: string, handler: (...args: any[]) => void) => {
        if (events.some((e) => e.methodName === methodName)) return;
        setEvents([...events, { methodName, handler }]);
        connection.on(methodName, handler);
    };

    const removeEvent = (methodName: string) => {
        setEvents(events.filter((e) => e.methodName !== methodName));
        connection.off(methodName);
    };

    return {
        addEvent,
        removeEvent,
        isConnectionStarted,
        connectionId: connection.connectionId
    };
};

export default useSignalR;
