import { createAction } from "@reduxjs/toolkit";
import { GuidMap } from "../common/types/GuidMap";

export const mapZoomEnd = createAction<number>("global/mapZoomEnd");

type ToggleGroupLayersPayload = { groupId: string; newVisibility: boolean; groupLayersMap: GuidMap<boolean> };
export const toggleGroupLayers = createAction<ToggleGroupLayersPayload>("global/toggleGroupLayers");

export const clearMap = createAction("global/clearMap");

export const toggleAppLayer = createAction<{ resourceId: string; visible: boolean }>("global/toggleAppLayer");

export const resetProjectData = createAction("global/resetProjectData");
