import makeStyles from "@mui/styles/makeStyles";

//Fullscreen is not available on some devices (IOS mainly)
const fullScreenAvailable =
    document.fullscreenEnabled ||
    document.mozFullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled;

export const useBasemapSelectorStyles = makeStyles(() => ({
    iconRoot: {
        position: "absolute",
        top: fullScreenAvailable ? 192 : 229, //if fullscreen button is not show, then the buttons get moved up
        right: 10,
        zIndex: 2,
        borderRadius: 4,
        backgroundColor: "white",
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)"
    },
    iconContainer: {
        width: 29,
        height: 29,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        cursor: "pointer",
        "&:hover": {
            backgroundColor: "whitesmoke",
            borderRadius: 4
        }
    },
    icon: {
        color: "#484848"
    },
    listTitle: {
        padding: 8,
        textAlign: "center"
    },
    list: {
        maxHeight: 280,
        overflowX: "overlay",
        position: "relative",
        overflow: "auto"
    },
    radioText: {
        textTransform: "capitalize",
        paddingLeft: 8
    },
    basemapTypeTitle: {
        textAlign: "center",
        fontWeight: 600
    },
    sectionContainer: {
        backgroundColor: "#fff",
        padding: 0
    },
    basemapsContainer: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        padding: 0
    }
}));
