import { useCallback, useEffect, useState } from "react";
import { useMap, ViewStateChangeEvent } from "@emblautec/react-map-gl";
import { useZoomLevelStyles } from "./styles";

const ZoomLevelWidget = () => {
    const classes = useZoomLevelStyles();
    const { current } = useMap();
    const [zoomLevel, setZoomLevel] = useState(1);

    const onZoomEnd = useCallback(
        (ev: ViewStateChangeEvent) => {
            setZoomLevel(ev.viewState.zoom);
        },
        [setZoomLevel]
    );

    useEffect(() => {
        if (current) {
            const currentZoom = current.getZoom();
            setZoomLevel(currentZoom);
            current.on("zoomend", onZoomEnd);
            return () => {
                current.off("zoomend", onZoomEnd);
            };
        }
    }, [current, onZoomEnd]);

    return (
        <div className={classes.root}>
            <div className={classes.zoomContainer}> Zoom: {zoomLevel.toFixed(2) || "Loading"}</div>
        </div>
    );
};

export default ZoomLevelWidget;
