import makeStyles from "@mui/styles/makeStyles";

export const useMapPreviewStyles = makeStyles(() => ({
    root: {
        height: "90vh",
        display: "grid",
        gridTemplateColumns: ({ orientation }) =>
            orientation === "portrait" ? "100% min-content" : "minmax(80%, 100%) min-content",
        gridTemplateRows: ({ orientation }) =>
            orientation === "portrait" ? "minmax(70%,100%) min-content" : "100% min-content",
        gridTemplateAreas: '"modalMap legendLandscape" "legendPortrait legendLandscape"'
    },
    loaderContainer: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    modalMapContainer: {
        gridArea: "modalMap",
        position: "relative",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        "& .mapboxgl-ctrl-attrib": {
            display: "none"
        },
        "& .mapboxgl-ctrl-scale": {
            marginBottom: 25,
            marginRight: 10,
            fontSize: "0.5rem"
        }
    },
    modalLegendLandscape: {
        backgroundColor: "#fff",
        gridArea: "legendLandscape",
        minWidth: "25vh"
    },
    modalLegendPortrait: {
        backgroundColor: "#fff",
        maxHeight: "27vh",
        gridArea: "legendPortrait"
    }
}));
