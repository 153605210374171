import React from "react";
import { Dialog, Backdrop } from "@mui/material";
import PrintPreview from "./printPreview";
import { useDispatch, useSelector } from "react-redux";
import { getToggledWidgetsSet, getWidgets } from "features/mapTools/selectors";
import { toggleWidget } from "features/mapTools/slice";
import widgetTypes from "../../../utils/tools/widgets";

const PrintModal = () => {
    // getModalStyle is not a pure function, we roll the style only on the first render
    const dispatch = useDispatch();
    const widgets = useSelector(getWidgets);
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT);
    const printWidget = widgets.find((widget) => widget.name === widgetTypes.PRINT);

    const handleClose = () => {
        dispatch(toggleWidget(printWidget.name));
    };

    return (
        <Dialog BackdropComponent={Backdrop} open={modalOpen} onClose={handleClose} maxWidth={false}>
            <PrintPreview handleClose={handleClose} />
        </Dialog>
    );
};

export default PrintModal;
