import { Chip, SvgIconTypeMap } from "@mui/material";
import { FC } from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type Props = {
    label: string;
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
    };
    size?: "small" | "large";
    className?: string;
    expanded: boolean;
};

const CustomChip: FC<Props> = ({ label, Icon, className, size = "small", expanded }) => {
    const classes = useStyles();

    return (
        <Chip
            label={label}
            className={clsx(
                classes.chip,
                size === "small" && classes.smallChip,
                expanded ? classes.chipExpanded : classes.chipShrinked,
                className
            )}
            icon={<Icon className={clsx(classes.chipIcon, size === "small" && classes.smallChipIcon)} />}
        />
    );
};

export default CustomChip;
