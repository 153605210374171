import { Layer } from "@emblautec/react-map-gl";
import { AnySourceData } from "mapbox-gl";
import { FC, memo, useMemo } from "react";
import { AisMapLayer as AisMapLayerType } from "../../../../model/map/AisMapLayer";

type Props = AisMapLayerType & {
    paintProperties?: Object[];
    layoutProperties?: Object[];
    minZoom: number;
    maxZoom: number;
    source?: AnySourceData | string; // Can be passed by the Source wrapper
};

const AisMapLayer: FC<Props> = ({ paintProperties, layoutProperties, minZoom, maxZoom, source, ...layerProps }) => {
    const paint = useMemo(
        () =>
            paintProperties?.reduce((acc: Object, prop: any) => {
                if (prop.title === "Legend") return acc;
                acc[prop.name] = prop.value;
                return acc;
            }, {}),
        [paintProperties]
    );

    const layout = useMemo(
        () =>
            layoutProperties?.reduce((acc: Object, prop: any) => {
                acc[prop.name] = prop.value;
                return acc;
            }, {}),
        [layoutProperties]
    );
    const { layerId, type, sourceId, drawUnderId } = layerProps;

    return (
        <Layer
            // This key is very important since it controls the instance of the component.
            // We have to remove the layer and add a new one when the type changes
            key={type}
            // -------------
            id={layerId}
            type={type}
            paint={paint}
            layout={layout}
            beforeId={drawUnderId}
            source={source || sourceId}
            minzoom={minZoom}
            maxzoom={maxZoom}
        />
    );
};

export default memo(AisMapLayer);
