import { useState } from "react";
import { DatasetProperties } from "../../../models/DatasetProperties";

const useSelectedFeaturesManager = (features: DatasetProperties[] | undefined) => {
    const [selectedFeaturesSet, setSelectedFeaturesSet] = useState(new Set<number>());

    const isFeatureSelected = (id: number) => selectedFeaturesSet.has(id);
    const areAllVisibleFeaturesSelected = features ? features.every((f) => isFeatureSelected(f.ogc_fid)) : false;
    const isAtLeastOneVisibleFeatureSelected = features?.some((f) => isFeatureSelected(f.ogc_fid)) ?? false;

    const toggleFeature = (id: number) => {
        if (selectedFeaturesSet.has(id)) selectedFeaturesSet.delete(id);
        else selectedFeaturesSet.add(id);

        setSelectedFeaturesSet(new Set(selectedFeaturesSet));
    };

    const selectMultipleFeatures = (ids: number[]) => {
        for (const id of ids) if (!selectedFeaturesSet.has(id)) selectedFeaturesSet.add(id);

        setSelectedFeaturesSet(new Set(selectedFeaturesSet));
    };

    const deselectMultipleFeatures = (ids: number[]) => {
        for (const id of ids) if (selectedFeaturesSet.has(id)) selectedFeaturesSet.delete(id);

        setSelectedFeaturesSet(new Set(selectedFeaturesSet));
    };

    const toggleAllVisibleFeatures = () => {
        const ids = features?.map((f) => f.ogc_fid) ?? [];

        if (areAllVisibleFeaturesSelected) {
            deselectMultipleFeatures(ids);
        } else {
            selectMultipleFeatures(ids);
        }
    };

    return {
        isFeatureSelected,
        toggleFeature,
        toggleAllVisibleFeatures,
        areAllVisibleFeaturesSelected,
        isAtLeastOneVisibleFeatureSelected,
        numberOfSelectedFeatures: selectedFeaturesSet.size,
        selectedFeatures: Array.from(selectedFeaturesSet)
    };
};

export default useSelectedFeaturesManager;
