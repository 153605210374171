import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    root: {
        backgroundColor: "#fff",
        overflow: "auto"
    },
    title: {
        padding: "8px 16px",
        backgroundColor: theme.customColors.appBgColor
    },
    section: {
        margin: "8px 20px",
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)"
    }
}));

export default useStyles;
