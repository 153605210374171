import { MetadataPropertyValue } from "./../model/metadata/MetadataPropertyValue";
import { createSlice } from "@reduxjs/toolkit";
import { mapZoomEnd, resetProjectData, toggleAppLayer, toggleGroupLayers } from "../actions/globalActions";
import { downloadDataset, downloadGeotiff, getLayerMetadata } from "../actions/layerSelector";
import { GuidMap } from "../common/types/GuidMap";
import { AppLayer } from "../model/app/AppLayer";
import { Group } from "../model/app/Group";
import { Style } from "../model/style/Style";
import { getApp } from "actions/apps";
import { LayerDataProperties } from "model/app/LayerDataProperties";
import { SourceType } from "model/enums/SourceType";

type LayerDataInfo = {
    data: LayerDataProperties[];
    count: number;
};

type SliceState = {
    layerGroups: Group[];
    loading: boolean;
    fetchingGeotiff: boolean;
    fetchingAttributes: boolean;
    fetchingMetadata: boolean;
    layerMetadata: MetadataPropertyValue[];
    layerDataInfo: LayerDataInfo;
    error: string | null;
    mapZoom: number;
    stylerLayerId: string | null;
    layerVisibilityMap: GuidMap<boolean>; //{layerId: false/true}
    layerStylesMap: GuidMap<Style[]>; //{layerId: [styleOne, ...]}
    shouldFilter: boolean;
};

const initialState: SliceState = {
    layerGroups: [],
    loading: false,
    fetchingGeotiff: false,
    fetchingAttributes: false,
    fetchingMetadata: false,
    layerMetadata: [],
    layerDataInfo: {
        data: [],
        count: 0
    },
    error: null,
    mapZoom: 0,
    stylerLayerId: null,
    layerVisibilityMap: {}, //{layerId: false/true}
    layerStylesMap: {}, //{layerId: [styleOne, ...]}
    shouldFilter: false
};

export const layerSelectorSlice = createSlice({
    name: "layerSelector",
    initialState,
    reducers: {
        setLayerData: (state, { payload: { layerStylesMap, layerVisibilityMap, layerGroups } }) => {
            state.layerStylesMap = layerStylesMap;
            state.layerVisibilityMap = layerVisibilityMap;
            state.layerGroups = layerGroups;
            state.loading = false;
        },
        setMapZoom: (state, { payload: newMapZoom }) => {
            state.mapZoom = newMapZoom;
        },
        setLoading: (state, { payload: loading }) => {
            state.loading = loading;
        },
        setStylerLayerId: (state, { payload: newStylerLayerId }) => {
            state.stylerLayerId = newStylerLayerId;
        },
        setShouldFilter: (state, { payload: shouldFilter }) => {
            state.shouldFilter = shouldFilter;
        },
        hideAllLayers: ({ layerVisibilityMap }) => {
            Object.keys(layerVisibilityMap).forEach((layerId) => {
                layerVisibilityMap[layerId] = false;
            });
        },
        setLayerIsFiltered: (state, { payload: {layerResourceId, value} }) => {
            const layer = state.layerGroups.getRecursive<AppLayer>(layerResourceId);
            layer.isFiltered = value;
        },
        addStyleToLayer: ({ layerGroups, layerStylesMap, mapZoom }, { payload: { layerId, style } }) => {
            layerStylesMap[layerId].splice(0, 0, style);
            const layer = layerGroups.getRecursive<AppLayer>(layerId);
            const newMinZoom = Math.min(layer.minZoom, style.minZoom);
            const newMaxZoom = Math.max(layer.maxZoom, style.maxZoom);
            layer.minZoom = newMinZoom;
            layer.maxZoom = newMaxZoom;
            layer.isShown =
                Math.floor(newMinZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(newMaxZoom);
        },
        removeStyleFromLayer: ({ layerStylesMap, layerGroups, mapZoom }, { payload: { layerId, styleId } }) => {
            layerStylesMap[layerId] = layerStylesMap[layerId].filter((s) => s.styleId !== styleId);

            let minZoom = 24;
            let maxZoom = 0;
            layerStylesMap[layerId].forEach((style) => {
                if (style.minZoom < minZoom) minZoom = style.minZoom;
                if (style.maxZoom > maxZoom) maxZoom = style.maxZoom;
            });

            const layer = layerGroups.getRecursive<AppLayer>(layerId);
            layer.minZoom = minZoom;
            layer.maxZoom = maxZoom;
            layer.isShown = Math.floor(minZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(maxZoom);
        },
        changePropertiesOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, styleId, properties } }) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = properties;
                }
            });
        },
        changePropertyOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, styleId, property } }) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    const propIndex = style.properties.findIndex((p) => p.name === property.name);
                    if (propIndex !== -1) {
                        style.properties[propIndex] = property;
                    } else {
                        console.error("Property index could not be found");
                    }
                }
            });
        },
        changeTypeOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, styleId, properties, type } }) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.properties = properties;
                    style.type = type;
                }
            });
        },
        changeStyleOrder: ({ layerStylesMap }, { payload: { layerId, styleId, beforeStyleId } }) => {
            const styles = layerStylesMap[layerId];

            const movedStyleIndex = styles.findIndex((s) => s.styleId === styleId);
            let destinationIndex = styles.findIndex((s) => s.styleId === beforeStyleId);

            const style = styles.splice(movedStyleIndex, 1)[0];

            styles.splice(destinationIndex, 0, style);
        },
        toggleGroupCollapse: ({ layerGroups }, { payload: { groupId, newCollapseValue } }) => {
            const group = layerGroups.getRecursive(groupId);
            group.options.collapsed = newCollapseValue;
        },
        resetLayerData: (state) => {
            state.layerDataInfo.data = [];
            state.layerDataInfo.count = 0;
        },
        updateStyleZoomRange: (
            { layerStylesMap, mapZoom, layerGroups },
            { payload: { layerId, styleId, minZoom, maxZoom } }
        ) => {
            layerStylesMap[layerId].forEach((style) => {
                if (style.styleId === styleId) {
                    style.minZoom = minZoom;
                    style.maxZoom = maxZoom;
                }
            });

            let newMinZoom = 24;
            let newMaxZoom = 0;
            layerStylesMap[layerId].forEach((style) => {
                if (style.minZoom < newMinZoom) newMinZoom = style.minZoom;
                if (style.maxZoom > newMaxZoom) newMaxZoom = style.maxZoom;
            });

            const layer = layerGroups.getRecursive<AppLayer>(layerId);
            layer.minZoom = newMinZoom;
            layer.maxZoom = newMaxZoom;
            layer.isShown =
                Math.floor(newMinZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(newMaxZoom);
        },
        addBufferLayer: (state, { payload: { layer, targetLayerResourceId } }) => {
            state.layerGroups.addElementRecursive(targetLayerResourceId, layer, false);
            state.layerStylesMap[layer.resourceId] = [];
            state.layerVisibilityMap[layer.resourceId] = true;
        },
        updateBufferLayer: (state, { payload: { layerResourceId, bounds, sourceId, geometryType, layerStyle } }) => {
            state.layerGroups.forLayersRecursive((layer) => {
                if (layer.resourceId !== layerResourceId) return;

                layer.bounds = bounds;
                layer.sourceId = sourceId;
                layer.geometryType = geometryType;
                layer.options.loading = false;
            });

            state.layerStylesMap[layerResourceId] = [layerStyle];
        },
        removeBufferLayer: (state, { payload: layerResourceId }) => {
            state.layerGroups.removeOneRecursive(layerResourceId);
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(
                toggleGroupLayers,
                ({ layerGroups, layerVisibilityMap }, { payload: { groupId, newVisibility } }) => {
                    //Left it like this for when we want to implement the
                    //system with totalLayerCounts and visibleLayerCounts on groups
                    const group = layerGroups.getRecursive(groupId);
                    group.layers.forLayersRecursive((layer: AppLayer) => {
                        layerVisibilityMap[layer.resourceId] = newVisibility;
                    });
                }
            )
            .addCase(toggleAppLayer, ({ layerVisibilityMap }, { payload: { resourceId, visible } }) => {
                layerVisibilityMap[resourceId] = visible;
            })
            .addCase(mapZoomEnd, (state, { payload: newZoom }) => {
                state.mapZoom = newZoom;
                state.layerGroups.forLayersRecursive((layer) => {
                    layer.isShown =
                        Math.floor(layer.minZoom) <= Math.floor(newZoom) &&
                        Math.floor(newZoom) <= Math.floor(layer.maxZoom);
                });
            })
            .addCase(downloadDataset.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(downloadDataset.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(downloadDataset.rejected, (state, { payload: error }) => {
                state.loading = false;
                state.error = error;
            })
            .addCase(getApp.pending, (state) => {
                state.loading = true;
            })
            .addCase(getApp.rejected, (state) => {
                state.loading = false;
            })
            .addCase(downloadGeotiff.pending, (state) => {
                state.fetchingGeotiff = true;
                state.error = null;
            })
            .addCase(downloadGeotiff.fulfilled, (state) => {
                state.fetchingGeotiff = false;
            })
            .addCase(downloadGeotiff.rejected, (state, { payload: error }) => {
                state.fetchingGeotiff = false;
                state.error = error;
            })
            .addCase(getLayerMetadata.pending, (state) => {
                state.fetchingMetadata = true;
            })
            .addCase(getLayerMetadata.fulfilled, (state, { payload }) => {
                state.fetchingMetadata = false;
                state.layerMetadata = payload;
            })
            .addCase(getLayerMetadata.rejected, (state) => {
                state.fetchingMetadata = false;
            })
            .addCase(resetProjectData, () => initialState)
});

export const {
    setLayerData,
    setMapZoom,
    setLoading,
    setStylerLayerId,
    setShouldFilter,
    hideAllLayers,
    addStyleToLayer,
    removeStyleFromLayer,
    changePropertiesOfLayerStyle,
    changePropertyOfLayerStyle,
    changeTypeOfLayerStyle,
    changeStyleOrder,
    toggleGroupCollapse,
    resetLayerData,
    updateStyleZoomRange,
    addBufferLayer,
    updateBufferLayer,
    removeBufferLayer,
    setLayerIsFiltered
} = layerSelectorSlice.actions;

export default layerSelectorSlice.reducer;
