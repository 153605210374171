import config from "../../../config";
import mapboxClient from "../../../actions/mapboxClient";
import { toastr } from "react-redux-toastr";
import blankBasemapStyle from "../../../constants/map/blankBasemapStyle";
import { mapboxAttribution } from "../../../constants/map/attributions";
import { HelperLayers, HelperLayersIds } from "../../../model/enums/HelperLayers";
import { makeMapSource } from "../../../utils/creators/MapSourceCreators";
import { makeMapLayer } from "../../../utils/creators/MapLayerCreators";
import { validate } from "@emblautec/mapbox-gl/dist/style-spec/index.es";
export class MapStyleService {
    #extraData;

    constructor(extraData) {
        this.#extraData = extraData;
    }

    getBasemapStyle = (basemap) => {
        return new Promise((resolve) => {
            switch (basemap.type) {
                case "vector":
                    resolve(this.#getVectorBaseMap(basemap));
                    break;
                case "raster":
                    resolve(this.#getRasterBasemap(basemap));
                    break;
                case "none":
                    resolve(this.#getEmptyBaseMap(basemap));
                    break;
                default:
                    throw new Error("Invalid basemap type");
            }
        });
    };

    #getStyle = (stylePath) =>
        mapboxClient.get("styles/v1/" + stylePath + "?access_token=" + config.mapboxApiKey).then((res) => res.data);

    #getMapboxVectorStyleConfig = async (path) => {
        const mapboxPath = path.split("styles/")[1];
        const response = await this.#getStyle(mapboxPath);
        return response;
    };

    #getEmptyBaseMap = (basemap) => {
        const newStyle = JSON.parse(JSON.stringify(blankBasemapStyle));
        const addHelperLayers = false;
        const basemapStyle = this.#initBasemapStyle(newStyle, basemap, addHelperLayers);
        return basemapStyle;
    };

    #getVectorBaseMap = (basemap) => {
        const basemapUrlSplit = basemap.url.split("://");
        const protocol = basemapUrlSplit[0];
        const path = basemapUrlSplit[1];

        if (protocol !== "mapbox") {
            toastr.error("Unsupported map type");
            return;
        }

        return this.#getMapboxVectorStyleConfig(path).then((style) => {
            const basemapStyle = this.#initBasemapStyle(style, basemap);
            return basemapStyle;
        });
    };

    #getRasterBasemap = (basemap) => {
        const newStyle = JSON.parse(JSON.stringify(blankBasemapStyle));

        newStyle.layers = [
            {
                id: "raster-basemap-layer",
                type: basemap.type,
                source: basemap.title,
                paint: {}
            }
        ];

        const basemapStyle = this.#initBasemapStyle(newStyle, basemap);
        return basemapStyle;
    };

    #initBasemapStyle = (newStyle, basemap, addHelperLayers = true) => {
        delete newStyle.zoom;
        delete newStyle.center;

        if (basemap.type === "raster") {
            newStyle.sources[basemap.title] = {
                type: basemap.type === "raster" ? "raster" : "vector",
                tiles: [basemap.url],
                tileSize: 256,
                attribution: mapboxAttribution
            };
        }

        newStyle.sources["mapbox-dem"] = {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14
        };

        newStyle.sprite = config.apiUrl + "sprite/";

        addHelperLayers && this.#addHelperLayers(newStyle);

        if (this.#extraData) {
            this.#addCustomSources(newStyle);
            this.#addCustomLayers(newStyle);
        }

        this.#removeInvalidLayers(newStyle);

        return newStyle;
    };

    #removeInvalidLayers = (newStyle) => {
        const errors = validate(newStyle);

        const indexMap = {};

        errors.forEach((error) => {
            // Error message looks like "layers[128].paint.line-width: number expected, null found"
            // So we split on : to get the first part
            const layerText = error.message.split(":")[0];

            // Then we find the index by splitting with a regular expression that searches for "[ ]"
            const layerIndex = layerText.split(/[[\]]+/g)[1];

            console.error(`GIS_DEV: Layer:${layerIndex} has invalid styling \n Specific error:${error.message}`);

            indexMap[layerIndex] = true;
        });

        newStyle.layers = newStyle.layers.filter((_, index) => !indexMap[index]);
    };

    #addCustomSources = (newStyle) => {
        const { sources, isPublic } = this.#extraData;

        sources.forEach((source) => {
            newStyle.sources[source.id] = makeMapSource(source, isPublic);
        });
    };

    #transformLayersToMapLayers = (layers) => {
        const { paintsDict, layoutsDict, zoomRangesDict } = this.#extraData;

        return layers.map((layer) => {
            const paints = paintsDict[layer.layerId]?.properties;
            const layouts = layoutsDict[layer.layerId]?.properties;
            const zoomRange = zoomRangesDict[layer.layerId];

            return makeMapLayer({ layer, paints, layouts, zoomRange });
        });
    };

    #addCustomLayers = (newStyle) => {
        const { layers } = this.#extraData;
        const symbolLayers = layers.filter((lay) => lay.type === "symbol");
        const geometricLayers = layers.filter((lay) => lay.type !== "symbol");

        const orderedSymbolLayers = this.#orderLayers(symbolLayers, HelperLayersIds.SymbolStartLayer);
        const orderedGeometricLayers = this.#orderLayers(geometricLayers, HelperLayersIds.GeometricStartLayer);

        const symbolMapLayers = this.#transformLayersToMapLayers(orderedSymbolLayers);
        const geometricMapLayers = this.#transformLayersToMapLayers(orderedGeometricLayers);

        const styleLayers = newStyle.layers;
        let geometricPositionIndex = styleLayers.length - 2;

        for (let i = styleLayers.length - 2; i >= 0; i--) {
            geometricPositionIndex = i;
            if (styleLayers[i].id === HelperLayersIds.GeometricStartLayer) break;
        }

        newStyle.layers.splice(geometricPositionIndex, 0, ...geometricMapLayers.reverse());

        newStyle.layers.splice(newStyle.layers.length - 2, 0, ...symbolMapLayers.reverse());
    };

    #orderLayers = (layers, topLayerId = null) => {
        const orderedLinkedList = layers.reduce((acc, layer) => {
            layer.drawUnderId && (acc[layer.drawUnderId] = layer);
            return acc;
        }, {});

        let nextId = topLayerId;
        const orderedLayerList = [];
        const unorderedLayerList = layers.filter((layer) => !layer.drawUnderId);

        while (nextId) {
            if (orderedLinkedList[nextId]) {
                orderedLayerList.push(orderedLinkedList[nextId]);
            }
            nextId = orderedLinkedList[nextId]?.layerId;
        }

        return [...orderedLayerList, ...unorderedLayerList];
    };

    #addHelperLayers = (newStyle) => {
        const layers = newStyle.layers;
        let lastSymbolIndex = layers.length;

        for (let i = layers.length - 1; i >= 0; i--) {
            if (layers[i].type !== "symbol") break;
            lastSymbolIndex = i;
        }

        //Added under the last symbol
        newStyle.layers.splice(lastSymbolIndex, 0, HelperLayers.GeometricHelperLayer);

        // This is added at the top
        newStyle.layers.push(HelperLayers.SymbolHelperLayer);
    };
}
