import { resetProjectData } from "actions/globalActions";
import { useIdsRequestInterceptor } from "app/hooks/useIdsRequestInterceptor";
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder";
import { setIds } from "features/core/slice";
import { FCWC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks/useAppDispatch";
import { saveCoreIds } from "utils/coreInfo";
import { decryptId } from "utils/cryptoUtils";
import { getStyleConfig } from "./actions/styles";
import { fetchInfoboxes } from "features/infobox/actions";

type Params = {
    clientId: string;
    projectId: string;
};

//This component should handle all the initial data load and the
//load of all the critical resources
const PublicBootstrap: FCWC = ({ children }) => {
    const [criticalResourcesLoaded, setCriticalResourcesLoaded] = useState(false);
    const [coreInfoLoaded, setCoreInfoLoaded] = useState(false);

    const dispatch = useAppDispatch();
    const { clientId, projectId } = useParams<Params>();

    const idsInterceptorLoading = useIdsRequestInterceptor();

    useEffect(() => {
        dispatch(resetProjectData());
        setCoreInfoLoaded(false);
        setCriticalResourcesLoaded(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, projectId]);

    useEffect(() => {
        if (!coreInfoLoaded) {
            saveCoreIds(clientId, projectId);
            dispatch(setIds({ clientId: decryptId(clientId), projectId: decryptId(projectId) }));
            setCoreInfoLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coreInfoLoaded]);

    useEffect(() => {
        if (coreInfoLoaded && !idsInterceptorLoading) {
            // Non-Critical

            // Critical
            const styleConfigPromise = dispatch(getStyleConfig());
            const infoboxesPromise = dispatch(fetchInfoboxes(true));
            const criticalPromises = [styleConfigPromise, infoboxesPromise];
            //These are the critical resources we need
            Promise.all(criticalPromises).then(() => setCriticalResourcesLoaded(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coreInfoLoaded, idsInterceptorLoading]);

    return (
        <LoadingPlaceholder
            loading={!criticalResourcesLoaded || !coreInfoLoaded || idsInterceptorLoading}
            message="Getting data"
        >
            {children}
        </LoadingPlaceholder>
    );
};

export default PublicBootstrap;
