import { DefaultTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

const legendSize = 24;

export const useStyles = makeStyles<DefaultTheme, { symbolColor: string, symbolIconColor: string }>(() => ({
    legend: {
        width: legendSize,
        height: legendSize,
        position: "relative"
    },
    symbol: (props) => ({
        backgroundColor: "transparent",
        color: props.symbolColor,
        lineHeight: "24px",
        fontWeight: "bold",
        fontSize: 24,
        width: "10px",
        minWidth: "10px",
        marginRight: "12px",
        "& svg": {
            width: legendSize,
            height: legendSize,
            fill: props.symbolIconColor
        }
    }),

}));
