import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Typography from "../CustomTypography/CustomTypography";
import AddIcon from "@mui/icons-material/AddCircle";
import DownloadIcon from "@mui/icons-material/GetApp";
import EditIcon from "@mui/icons-material/Edit";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import { useStyles } from "./styles";

const CustomDialog = (props) => {
    const { isOpen, handleClose, onConfirm, dialogTitle, dialogType, disabled, hideActionContainer } = props;
    const classes = useStyles();

    const typesWithoutCancelButton = {
        info: true
    };

    const displayConfirmationButton = () => {
        let buttonText = {
            create: "Create",
            apply: "Apply",
            add: "Add",
            upload: "Upload",
            update: "Update",
            start: "Start",
            info: "Understood"
        };

        return (
            <Button
                className={classes.flex}
                variant="contained"
                color="primary"
                onClick={onConfirm}
                disabled={disabled}
                data-testid={confirmationButtonTestId}
            >
                {dialogType === "add" && <AddIcon fontSize="small" className={classes.icon} />}
                {dialogType === "update" && <EditIcon fontSize="small" className={classes.icon} />}
                {dialogType === "start" && <DownloadIcon fontSize="small" className={classes.icon} />}
                {dialogType === "upload" && <PublishIcon fontSize="small" className={classes.icon} />}
                {buttonText[dialogType]}
            </Button>
        );
    };

    const cancelButton = () => {
        return (
            typesWithoutCancelButton[dialogType] || (
                <Button
                    onClick={handleClose}
                    variant="text"
                    color="primary"
                    className={classes.bold}
                    data-testid={cancelButtonTestId}
                >
                    Cancel
                </Button>
            )
        );
    };

    return (
        <Dialog
            aria-labelledby="atlas-dialog create-group-modal"
            open={isOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500
            }}
        >
            <DialogTitle>
                <div className={classes.flex}>
                    <Typography variant="h3" textWeight="bold">
                        {dialogTitle}
                    </Typography>
                    <CloseIcon className={classes.clickable} onClick={handleClose} data-testid={closeButtonTestId} />
                </div>
            </DialogTitle>
            <DialogContent dividers>{props.children}</DialogContent>
            {!hideActionContainer && (
                <DialogActions>
                    {displayConfirmationButton()}
                    {cancelButton()}
                </DialogActions>
            )}
        </Dialog>
    );
};

CustomDialog.defaultProps = {
    hideActionContainer: false
};

export default CustomDialog;

const confirmationButtonTestId = "qa-custom-modal-confirmation-button";
const cancelButtonTestId = "qa-custom-modal-cancel-button";
const closeButtonTestId = "qa-custom-modal-close-button";
