import { Grid } from "@mui/material";
import { FC } from "react";
import { MetadataType } from "../../../../../model/enums/MetadataType";
import Linkify from "react-linkify";
import { MetadataProperty as MetadataPropertyType } from "../../../../../model/metadata/MetadataProperty";
import { TagWithValue } from "../../../../../model/metadata/MetadataPropertyValue";
import CustomTypography from "../../../../common/CustomTypography/CustomTypography";
import useStyles from "./styles";
import dayjs from "dayjs";

type Props = {
    property: MetadataPropertyType;
};

const MetadataProperty: FC<Props> = ({ property }) => {
    const classes = useStyles();

    const lowerOpacity = (rgbaString: string, opacity = "0.15") => {
        if (rgbaString === "red") {
            //for backwards compatibility
            rgbaString = "rgba(255,0,0,1)";
        }

        const splitted = rgbaString.split(",");
        splitted[3] = opacity + ")";

        return splitted.join(",");
    };

    const renderPropertyValue = () => {
        switch (property.type) {
            case MetadataType.Text:
                return (
                    <CustomTypography>
                        <Linkify>{(property.value as string) || ""}</Linkify>
                    </CustomTypography>
                );

            case MetadataType.Number:
                return <CustomTypography>{property.value || ""}</CustomTypography>;

            case MetadataType.Date:
                return <CustomTypography>{dayjs(property.value as string).format("L")}</CustomTypography>;

            case MetadataType.TagList:
                return (
                    <div className={classes.tagList}>
                        {(property.value as TagWithValue[]).map((tag) =>
                            tag.enabled ? (
                                <div
                                    key={tag.id}
                                    style={{ color: tag.color, backgroundColor: lowerOpacity(tag.color) }}
                                    className={classes.tag}
                                >
                                    <span style={{ filter: "brightness(0.8)" }}>{tag.name}</span>
                                </div>
                            ) : null
                        )}
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <CustomTypography textWeight="bold">{property.name}</CustomTypography>
            </Grid>
            <Grid item xs={6}>
                {renderPropertyValue()}
            </Grid>
        </Grid>
    );
};

export default MetadataProperty;
