import apiClient from "./apiClient.ts";

export const getRasterPixelValue = (rasterId, long, lat, cancelToken) => () =>
    apiClient
        .get(`raster/${rasterId}/${long}/${lat}`, { cancelToken })
        .then((res) => res)
        .catch(() => null);

export const getPublicRasterPixelValue = (rasterId, long, lat, cancelToken) => () =>
        apiClient
            .get(`raster/public/${rasterId}/${long}/${lat}`, { cancelToken })
            .then((res) => res)
            .catch(() => null);