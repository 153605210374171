import { FC } from "react";
import { useMap } from "@emblautec/react-map-gl";

export const withMap =
    <P extends object>(WrappedComponent: FC<P>) =>
    (props: P) => {
        const maps = useMap();

        return <WrappedComponent {...maps} {...props} />;
    };
