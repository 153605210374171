import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: 8,
        marginLeft: -4
    },
    bold: {
        fontWeight: 700
    },
    flex: {
        display: "flex",
        justifyContent: "space-between"
    },
    clickable: {
        cursor: "pointer"
    }
}));
