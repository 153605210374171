import { createContext, FCWC, useState } from "react";
import { HandlerStacksStore, MapEvents, PopHandlerFT, PushHandlerFT, PushHandlersFT } from "./types";
import {
    defaultHandlerStoreValue,
    defaultPopHandlerFunctionValue,
    defaultPushHandlerFunctionValue,
    defaultPushHandlersFunctionValue
} from "./utils";

type MapHandlersContextValue = {
    pushHandler: PushHandlerFT;
    pushHandlers: PushHandlersFT;
    popHandler: PopHandlerFT;
    handlerStacksStore: HandlerStacksStore;
};

export const MapHandlersContext = createContext<MapHandlersContextValue>({
    handlerStacksStore: defaultHandlerStoreValue,
    pushHandler: defaultPushHandlerFunctionValue,
    pushHandlers: defaultPushHandlersFunctionValue,
    popHandler: defaultPopHandlerFunctionValue
});

// This context will be used for handlers that can't or shouldn't be active together with other handlers for the same event.
// Only the top handler of the stack will be active, and pushing a new one should change the active one. In case we want a
// "background" handler to be active, meaning a handler that can work idependantly from the state of the app, it should be
// added via the .on method on the map reference. One such exanple is the coordinate info, which adds on click and move handlers
export const MapHandlersProvider: FCWC = ({ children }) => {
    const [handlerStacksStore, setHandlerStacksStore] = useState<HandlerStacksStore>(defaultHandlerStoreValue);

    const pushHandler: PushHandlerFT = (mapId, event, handler) => {
        setHandlerStacksStore((handlerStacksStore) => {
            // This scenario is a bit too complicated for typescript. If we put || it won't work;
            const currentHandlersArray = handlerStacksStore[mapId][event] ?? [];

            return {
                ...handlerStacksStore,
                [mapId]: {
                    ...handlerStacksStore[mapId],
                    [event]: [...currentHandlersArray, handler]
                }
            };
        });
    };

    const pushHandlers: PushHandlersFT = (mapId, handlersDict) => {
        setHandlerStacksStore((handlerStacksStore) => {
            // This scenario is a bit too complicated for typescript. If we put || it won't work;
            const mapHandlerStacks = { ...handlerStacksStore[mapId] };
            Object.keys(handlersDict).forEach((untypedEvent) => {
                const event = untypedEvent as MapEvents;
                const handlerStack = mapHandlerStacks[event] ?? [];

                // Sadly we have to cast it to any, typescript has some problems figuring out the generics
                const newHandlerStack = [...handlerStack, handlersDict[event]] as any;
                mapHandlerStacks[event] = newHandlerStack;
            });

            return {
                ...handlerStacksStore,
                [mapId]: mapHandlerStacks
            };
        });
    };

    const popHandler: PopHandlerFT = (mapId, event) => {
        setHandlerStacksStore((handlerStacksStore) => {
            const currentHandlersArray = handlerStacksStore[mapId][event] ?? [];

            // Because typescript can't perfectly figure out generics in this case, we have to exclude
            // the array of type never
            const currentHandlersArrayWithoutNever = currentHandlersArray as Exclude<
                typeof currentHandlersArray,
                never[]
            >;
            const handlersArrayCopy = [...currentHandlersArrayWithoutNever];
            handlersArrayCopy.pop();
            return {
                ...handlerStacksStore,
                [mapId]: {
                    ...handlerStacksStore[mapId],
                    [event]: handlersArrayCopy
                }
            };
        });
    };

    return (
        <MapHandlersContext.Provider value={{ handlerStacksStore, pushHandler, pushHandlers, popHandler }}>
            {children}
        </MapHandlersContext.Provider>
    );
};
