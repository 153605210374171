import { FC, Fragment } from "react";
import { CircularProgress } from "@mui/material";

import useDprLocationHistory from "./useDprLocationHistory";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import { useStyles } from "./styles";
import useDefaultInfoboxStyles from "features/infobox/components/DefaultInfobox/styles";
import dayjs from "dayjs";

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature;
};

const DprProperties: FC<Props> = ({ feature }) => {
    const classes = useStyles();
    const infoboxClasses = useDefaultInfoboxStyles();

    const { locationHistory, isLoading, isError, shouldDisplayInfo } = useDprLocationHistory({ feature });

    const renderRow = (key: string, value: string, isIndented = true, isKeyBold = false) => (
        <tr key={key}>
            <td className={infoboxClasses.propertyKey} style={{ paddingLeft: isIndented ? "12px" : "0" }}>
                <CustomTypography variant="body2" textWeight={isKeyBold ? "bold" : "regular"}>
                    {key}
                </CustomTypography>
            </td>
            <td className={infoboxClasses.propertyValue}>
                <CustomTypography variant="body2">{value}</CustomTypography>
            </td>
        </tr>
    );

    if (!shouldDisplayInfo) return null;

    if (isLoading) {
        return (
            <tr>
                <td colSpan={2}>
                    <CircularProgress size={20} className={classes.status} />
                </td>
            </tr>
        );
    }

    if (isError) {
        return (
            <tr>
                <td colSpan={2}>
                    <CustomTypography color="error" className={classes.status} variant="body2">
                        Failed to fetch the DPR milestone information
                    </CustomTypography>
                </td>
            </tr>
        );
    }

    return (
        <>
            {!!locationHistory?.length && renderRow("DPR Milestones", "", false, true)}

            {locationHistory?.map((historyEntry) => (
                <Fragment key={`${historyEntry.locationName}-${historyEntry.milestoneId}-${historyEntry.dprDate}`}>
                    {renderRow("Milestone Name", historyEntry.milestoneDisplayName, true, true)}
                    {renderRow("Completion Date", dayjs(historyEntry.dprDate).format("LLL"))}
                    {renderRow("Vessel", historyEntry.vessel)}
                    {renderRow("Scope", historyEntry.scope)}
                </Fragment>
            ))}
        </>
    );
};

export default DprProperties;
