import makeStyles from "@mui/styles/makeStyles";

export const useDigitizeStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        position: "relative"
    },
    content: {
        maxHeight: "100%"
    },
    title: {
        padding: "8px 16px",
        backgroundColor: "#f5f5f5"
    },
    input: {
        display: "none"
    },
    layersContainer: {
        height: "calc(100% - 99px)",
        width: "100%",
        flex: 1,
        overflowY: "overlay"
    },
    digitizeToolsIcon: {
        minWidth: 32,
        "& .MuiListItemIcon-root": {
            fontSize: "0.865rem"
        }
    },
    digitizeToolsText: {
        "& .MuiListItemText-primary": {
            fontSize: "0.865rem"
        }
    },
    stylerDivWrapper: { flexGrow: 1, overflow: "auto", display: "flex" },
    marginRight: {
        marginRight: 8
    }
}));
