import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: any) => ({
    appCard: {
        display: "flex",
        width: "100%",
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)"
    },

    appName: {
        color: "black",
        fontWeight: "bold",
        fontSize: 18,
        letterSpacing: 0.19,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    image: {
        width: "100%",
        aspectRatio: "16 / 9",
        backgroundColor: theme.customColors.appBgColor
    },
    modifiedUtcText: {
        color: theme.customColors.darkGray,
        fontSize: 14,
        paddingBottom: 8
    },

    blueChip: {
        color: theme.customColors.primary3,
        fill: theme.customColors.primary3,
        backgroundColor: "#D3E2EA",
        alignSelf: "center"
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-between"
    }
}));
