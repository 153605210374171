import { ComponentProps, FC, FCWC, HTMLProps } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import clsx from "clsx";
import React from "react";
import { Button } from "@mui/material";

type Props = ComponentProps<typeof Link> & {
    className?: string;
    innerRef?: any;
    value?: string;
};

const AnchorElement = React.forwardRef((props: HTMLProps<HTMLAnchorElement>, ref: any) => {
    return <a ref={ref} {...props}></a>;
});

const ButtonLink: FCWC<Props> = ({ className, children, innerRef, ...props }) => {
    const classes = useStyles();
    const externalLink = props.to.toString().startsWith("http://") || props.to.toString().startsWith("https://");

    return externalLink ? (
        <Button component={AnchorElement} href={props.to.toString()} className={clsx(classes.link, className ?? "")}>
            {children}
        </Button>
    ) : (
        <Link className={clsx(classes.link, className ?? "")} ref={innerRef} {...props}>
            {children}
        </Link>
    );
};

export default React.forwardRef((props: Props, ref: any) => <ButtonLink {...props} innerRef={ref} />);
