import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    root: {
        width: "100%",
        display: "flex",
        flexFlow: "column",
        height: "100%"
    },
    header: {
        display: "flex",
        flex: "0 1 auto",
        borderBottom: `1px solid ${theme.customColors.borderColor}`
    },
    errorContainer: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: theme.customColors.errorColor
    }
}));
