import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';

import AddIcon from "@mui/icons-material/Add";

import LayerType from "./layerType";
import Style from "./layerStyle";
import { Divider } from "@mui/material";

const styles = () => ({
    root: {},
    selectValue: {
        display: "flex",
        alignItems: "center",
        fontSize: 16
    }
});

class LayerStyleEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedStyle: this.props.styles.length ? 0 : null,
            columns: []
        };
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, props) {
        if (this.props.layer && this.props.layer.datasetId !== prevProps.layer.datasetId) {
        }
        if (this.props.layer && this.props.styles.length !== prevProps.styles.length) {
            this.setState({
                selectedStyle: this.props.styles.length ? 0 : null
            });
        }
    }

    onSelectStyle = (index) => {
        this.setState({
            selectedStyle: index
        });
    };

    onAddStyle = () => {};

    render() {
        let selectedStyleIndex = this.state.selectedStyle;

        let styles = this.props.styles.map((style, index) => {
            return (
                <div key={index} onClick={() => this.onSelectStyle(index)}>
                    <LayerType
                        selected={index === selectedStyleIndex}
                        type={style.type}
                        expressionType={style.expressionType}
                        layerProperties={style.properties}
                        onRemove={() => this.props.onRemoveStyleFromLayer(this.props.layer, style)}
                    ></LayerType>
                </div>
            );
        });

        let selectedStyle = this.props.styles[selectedStyleIndex];
        let style = selectedStyle ? (
            <Style
                isDigitizeLayer={this.props.isDigitizeLayer}
                minZoom={this.props.layer.minZoom}
                layer={selectedStyle}
                onPropertiesChanged={(properties) => this.props.onPropertiesChanged(properties, selectedStyle)}
                onPropertyExpressionTypeChanged={(type) =>
                    this.props.onPropertyExpressionTypeChanged(type, selectedStyle, selectedStyleIndex)
                }
                onTypeChanged={(type) => this.props.onTypeChanged(type, selectedStyle, selectedStyleIndex)}
                onZoomSliderChange={(zoom) => this.props.onZoomSliderChange(selectedStyle, zoom)}
            />
        ) : null;

        return (
            <div className="style-list">
                <div className="styles-container">
                    <div className="styles">
                        {styles}
                        <div className="add-button" onClick={() => this.props.onAddStyleToLayer(this.props.layer)}>
                            <AddIcon />
                        </div>
                    </div>
                    <Divider orientation="vertical"></Divider>
                </div>

                <div className="style-container">{style}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mapState: state.map
    };
};

export default connect(mapStateToProps)(withStyles(styles)(LayerStyleEditor));
