import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import * as layerActions from "../../../../../actions/layerSelector";
import * as metadataSchemaActions from "../../../../../actions/metadataSchema";
import { METADATA_SCHEMA_ID } from "../../../../../constants/metadata";
import { MetadataType } from "../../../../../model/enums/MetadataType";
import { MetadataProperty as MetadataPropertyType, Tag } from "../../../../../model/metadata/MetadataProperty";
import { TagValue, TagWithValue } from "../../../../../model/metadata/MetadataPropertyValue";
import { getLayerMetadata, getLayerMetadataLoading } from "../../../../../selectors/layerSelector";
import { getMetadataSchema, getMetadataSchemaLoading } from "../../../../../selectors/metadataSchema";
import { useAppSelector } from "../../../../../store/hooks/useAppSelector";
import CustomDialog from "../../../../common/CustomModal/CustomModal";
import ErrorPlaceholder from "../../../../common/ErrorPlaceholder/ErrorPlaceholder";
import LoadingPlaceholder from "../../../../common/LoadingPlaceholder/LoadingPlaceholder";
import MetadataProperty from "../MetadataProperty/MetadataProperty";
import useStyles from "./styles";

type Props = {
    open: boolean;
    onClose: () => void;
    datasetId: string;
    datasetName: string;
};

const MetadataModal: FC<Props> = ({ open, onClose, datasetId, datasetName }) => {
    const classes = useStyles();

    const metadataSchema = useAppSelector(getMetadataSchema);
    const metadataSchemaLoading = useAppSelector(getMetadataSchemaLoading);
    const layerMetadata = useAppSelector(getLayerMetadata);
    const layerMetadataLoading = useAppSelector(getLayerMetadataLoading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            dispatch(metadataSchemaActions.getMetadataSchema(METADATA_SCHEMA_ID));
            dispatch(layerActions.getLayerMetadata(datasetId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const hasNoMetadata = metadataSchema.length === 0 || layerMetadata.length === 0;
    const loading = metadataSchemaLoading || layerMetadataLoading;

    const mergeTags = (propertyTags: Tag[], valueTags: TagValue[]): TagWithValue[] => {
        const tagValueDict = valueTags.reduce((acc, tagValue) => {
            acc[tagValue.id] = tagValue.enabled;
            return acc;
        }, {});

        return propertyTags.map((tag) => {
            return {
                ...tag,
                enabled: tagValueDict[tag.id]
            };
        });
    };

    const mergeAndFilterMetadata = (): MetadataPropertyType[] => {
        if (hasNoMetadata || loading) return [];

        const layerMetadataDict = layerMetadata.reduce((acc, property) => {
            acc[property.id] = property.value;
            return acc;
        }, {});

        return metadataSchema
            .filter((property) => !property.hidden)
            .map((property) => {
                const value = layerMetadataDict[property.id];

                return {
                    ...property,
                    value: property.type === MetadataType.TagList ? mergeTags(property.value as Tag[], value) : value
                };
            })
            .filter((property) => {
                switch (property.type) {
                    case MetadataType.TagList:
                        return (property.value as TagWithValue[]).filter((tag) => tag.enabled).length !== 0;
                    case MetadataType.Number:
                    case MetadataType.Date:
                    case MetadataType.Text:
                        return property.value !== "" && property.value !== null;
                    default:
                        return false;
                }
            });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mergedMetadata = useMemo(() => mergeAndFilterMetadata(), [layerMetadata, metadataSchema]);
    const dialogTitle = `Metadata of ${datasetName}`;

    return (
        <CustomDialog isOpen={open} hideActionContainer dialogTitle={dialogTitle} handleClose={onClose}>
            <LoadingPlaceholder loading={loading} message="Loading layer metadata">
                <ErrorPlaceholder
                    error={hasNoMetadata}
                    message="No metadata available for the current layer"
                    onTryAgain={null}
                >
                    <div className={classes.metadataContainer}>
                        {mergedMetadata.map((property) => (
                            <MetadataProperty property={property} key={property.id} />
                        ))}
                    </div>
                </ErrorPlaceholder>
            </LoadingPlaceholder>
        </CustomDialog>
    );
};

export default MetadataModal;
