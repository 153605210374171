import { CircularProgress, LinearProgress } from "@mui/material";
import { useStyles } from "./styles";
import CustomTypography from "../CustomTypography/CustomTypography";
import { FCWC } from "react";

type CommonProps = {
    loading: boolean;
};

type ConditionalProps =
    | {
          linearProgress?: false;
          message?: string;
          textVariant?: string;
          spinnerSize?: number;
      }
    | {
          linearProgress: true;
          message?: never;
          textVariant?: never;
          spinnerSize?: never;
      };
type Props = CommonProps & ConditionalProps;

const LoadingPlaceholder: FCWC<Props> = ({
    children,
    loading,
    message = "Loading",
    textVariant = "body1",
    spinnerSize = 50,
    linearProgress = false
}) => {
    const { classes } = useStyles();
    if (linearProgress) {
        return (
            <>
                {loading && <LinearProgress />}
                {children}
            </>
        );
    }
    return loading ? (
        <div className={classes.container}>
            <CustomTypography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </CustomTypography>
            <CircularProgress size={spinnerSize} />
        </div>
    ) : (
        <>{children}</>
    );
};

export default LoadingPlaceholder;
