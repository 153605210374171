import makeStyles from "@mui/styles/makeStyles";
import appStyles from "../../../../utils/styles";

export const useNavStyles = makeStyles(() => ({
    actionButtonActive: {
        backgroundColor: "hsla(0,0%,100%,.04)"
    },
    actionButtonInactive: {
        backgroundColor: appStyles.color.PRIMARY_BG,
        opacity: 0.7
    },
    actionButton: {
        textTransform: "none",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        height: 56,
        borderRadius: 0,
        "&:hover": {
            borderRadius: 0,
            backgroundColor: appStyles.color.TOOL_HOVER
        },
        fontSize: 14,
        color: "white"
    },
    navOpen: {
        justifyContent: "flex-start"
    },
    toolIcon: {
        width: 60,
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white"
    },
    toolIconClosed: {
        display: "flex",
        color: "white"
    },
    toolLabel: {
        display: "flex",
        alignItems: "center",
        fontSize: 14,
        color: "white"
    }
}));
