import { AuthenticationResult, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder";
import config from "config";
import { FCWC, useMemo, useState } from "react";

export const AuthenticationProvider: FCWC = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const msalInit = async (msalInstance: PublicClientApplication) => {
        msalInstance.enableAccountStorageEvents();

        await msalInstance.initialize();
        msalInstance.handleRedirectPromise().then(handleResponse).catch(handleError);
    };

    const handleResponse = async function (response: AuthenticationResult | null) {
        setLoading(false);

        if (response !== null) {
            msalInstance.setActiveAccount(response.account);
            return;
        }

        const activeAccount = msalInstance.getActiveAccount();
        if (activeAccount) return;

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 1) {
            msalInstance.setActiveAccount(accounts[0]);
            return;
        }
        
        if (accounts.length > 1) {
            msalInstance["browserStorage"].clear();
        }

        const isPublic = window.location.pathname.includes("/public/");
        if (isPublic) return;

        setLoading(true);
        const request: RedirectRequest = {
            authority: config.azureB2C.auth.authority,
            scopes: config.loginRequest.scopes
        };
        await msalInstance.loginRedirect(request);
    };

    const handleError: ((reason: any) => void | PromiseLike<void>) | null | undefined = async function (error) {
        // reset password
        if (error?.errorMessage?.indexOf(config.forgetPasswordErrorCode) > -1) {
            const userEmail = error.errorMessage.substring(
                error.errorMessage.lastIndexOf(":") + 2,
                error.errorMessage.length - 2
            );
            const request: RedirectRequest = {
                authority: config.azureB2C.auth.passwordResetAuthority,
                scopes: config.loginRequest.scopes,
                loginHint: userEmail
            };

            await msalInstance.loginRedirect(request);
        } else {
            // eslint-disable-next-line
            msalInstance["browserStorage"].clear();
        }
    };

    const msalInstance = useMemo(() => {
        const instance = new PublicClientApplication(config.azureB2C);
        msalInit(instance);

        return instance;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MsalProvider instance={msalInstance}>
            <LoadingPlaceholder loading={loading} message={"Authenticating"}>
                {children}
            </LoadingPlaceholder>
        </MsalProvider>
    );
};
