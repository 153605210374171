import React from "react";
import { Typography, TextField, FormControl, Select, InputLabel, FilledInput, MenuItem } from "@mui/material";

function SelectProperty(props) {
    function handleChange(e) {
        props.onPropertyChanged(e.target.value);
    }

    return (
        <div className="property">
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" onChange={props.onTitleChanged} value={props.title} />
                )}
                {!props.editable && (
                    <Typography variant="body1" className="name">
                        {props.title}
                    </Typography>
                )}
                <div className="text-container">
                    <FormControl fullWidth variant="filled" className="property-select">
                        <InputLabel htmlFor="filled-age-simple">{props.title} type</InputLabel>
                        <Select
                            value={props.value}
                            onChange={handleChange}
                            input={<FilledInput name="age" id="filled-age-simple" />}
                        >
                            {props.options.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
}

export default SelectProperty;
