import makeStyles from '@mui/styles/makeStyles';

export const useZoomLevelStyles = makeStyles((theme) => ({
    root: {
        fontWeight: "bold",
        color: "#333",
        backgroundColor: "hsla(0,0%,100%,.75)",
        borderWidth: "1px 1px 1px 1px",
        borderColor: "#333",
        borderStyle: "solid"
    },
    zoomContainer: {
        padding: "1px 6px 1px 6px",
        fontSize: "0.685rem"
    }
}));
