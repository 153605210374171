
import { ComponentProps, FCWC } from "react";
import Map from "@emblautec/react-map-gl";
import { MapLayer as MapLayerType } from "../../../model/map/MapLayer";
import { Source as SourceType } from "../../../model/map/Source";
import DefaultPermaHandlers from "../components/DefaultPermaHandlers/DefaultPermaHandlers";
import MapLayer from "../components/MapLayer/MapLayer";

import MapResizeObserver from "../components/MapResizeObserver/MapResizeObserver";
import MapSource from "../components/MapSource/MapSource";
import { useSourceFirstPolygonLayerIdDict } from "./logic/useSourceFirstGeometricLayerIdDict";
import AisMapLayer from "../components/AisMapLayer/AisMapLayer";
import { AisLayersData } from "../../../utils/customHooks/map/useAisLayers";
import { MapFilterRecord } from "model/map/MapFilterRecordType";

type Props = {
    sources: SourceType[];
    layers: MapLayerType[];
    aisLayersData?: AisLayersData;
    paintsDict: object;
    layoutsDict: object;
    zoomRangesDict: object;
    id: string;
    isPublic: boolean;
    filters?: MapFilterRecord;
} & ComponentProps<typeof Map>;


const CustomMap: FCWC<Props> = ({
    sources,
    layers,
    aisLayersData,
    paintsDict,
    layoutsDict,
    zoomRangesDict,
    children,
    isPublic,
    filters,
    ...mapOptions
}) => {
    const sourceFirstPolygonLayerIdDict = useSourceFirstPolygonLayerIdDict({ layers });

    return (
        <Map  mapLib={import('@emblautec/mapbox-gl')}  {...mapOptions}>
            <MapResizeObserver mapId={mapOptions.id} />
            <DefaultPermaHandlers />
            {children}
            {sources.map((source) => {
                return <MapSource key={source.id} source={source} isPublic={isPublic} />;
            })}
            {layers.map((layer) => (
                <MapLayer
                    key={layer.layerId}
                    {...layer}
                    paintProperties={paintsDict[layer.layerId]?.properties}
                    layoutProperties={layoutsDict[layer.layerId]?.properties}
                    minZoom={zoomRangesDict[layer.layerId].minZoom}
                    maxZoom={zoomRangesDict[layer.layerId].maxZoom}
                    polygonLayerId={sourceFirstPolygonLayerIdDict[layer.layerId]}
                    filters={filters ? filters[layer.resourceId] : []}
                />
            ))}
            {aisLayersData &&
                aisLayersData.aisLayers.map((aisLayer) => {
                    const { aisPaintsDict, aisLayoutsDict, aisZoomRangesDict } = aisLayersData;

                    return (
                        <AisMapLayer
                            key={aisLayer.layerId}
                            {...aisLayer}
                            paintProperties={aisPaintsDict[aisLayer.layerId]?.properties}
                            layoutProperties={aisLayoutsDict[aisLayer.layerId]?.properties}
                            minZoom={aisZoomRangesDict[aisLayer.layerId].minZoom}
                            maxZoom={aisZoomRangesDict[aisLayer.layerId].maxZoom}
                        />
                    );
                })}
        </Map>
    );
};

export default CustomMap;
