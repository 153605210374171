import { useEffect } from "react";
import { useMap } from "@emblautec/react-map-gl";

const NoCanvasOutline = () => {
    const { current } = useMap();

    useEffect(() => {
        if (current) {
            current.getCanvas().style.outline = "none";
        }
    }, [current]);

    return null;
};

export default NoCanvasOutline;
