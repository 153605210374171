import { useRef } from "react";
import { createDraw } from "../../../components/map/utils/createDraw";

// This hook should be used once we refactor the digitze to store the layers on a map source instead of the draw

// Decided not to add the control to the map here because the useEffects are run in
// the children before the parents. This means that if a parent passes the draw to a child
// which uses it in a init function, it won't be attached at that point in time
export const useDraw = () => {
    const draw = useRef(createDraw());

    return draw.current;
};
