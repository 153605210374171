import { FC, HTMLAttributes, useCallback, useEffect, useState } from "react";

import turfBBox from "@turf/bbox";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { useStyles } from "./styles";
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    CircularProgress,
    InputAdornment,
    TextField,
    createFilterOptions,
    debounce
} from "@mui/material";
import { LngLatBoundsLike, useMap } from "@emblautec/react-map-gl";
import { SearchResult } from "features/searchBar/models/SearchResult";
import { useAppSelector } from "store/hooks/useAppSelector";
import { getSelectedAppIsPublic, getSelectedAppSearchBar } from "selectors/appsSelectors";
import { SearchBar as SearchBarType } from "features/searchBar/models/SearchBar";
import { searchApplicationDatasetByColumnAsync } from "features/searchBar/actions";

const SearchBar: FC = () => {
    const classes = useStyles();
    const { mainMap } = useMap();

    const [options, setOptions] = useState<SearchResult[]>([]);
    const [loading, setLoading] = useState(false);

    const searchBar: SearchBarType = useAppSelector(getSelectedAppSearchBar);
    const isPublic: boolean = useAppSelector(getSelectedAppIsPublic);

    useEffect(() => {
        searchBar && searchBackendCall("");
    }, [searchBar]);

    const searchBackendCall = useCallback(
        debounce((searchText) => {
            setLoading(true);
            searchApplicationDatasetByColumnAsync(searchBar.applicationId, searchText, isPublic).then((res) => {
                setOptions(res.filter((x) => !!x.value));
                setLoading(false);
            });
        }, 500),
        [searchBar]
    );

    const onInputChange = (_: any, newInputValue: string) => {
        searchBackendCall(newInputValue);
    };

    const zoomTo = (_: any, value: unknown) => {
        if (value) {
            const valueWithType = value as SearchResult;
            let map = mainMap?.getMap();

            // boundingBox format: BOX(number number,number number)
            const boxCoord = valueWithType.boundingBox
                .slice(4, -1)
                .replaceAll(" ", ",")
                .split(",")
                .filter((x) => !!x) // I've added this as a safety net
                .map((x) => parseFloat(x));

            const bbox: LngLatBoundsLike = [
                [boxCoord[0], boxCoord[1]],
                [boxCoord[2], boxCoord[3]]
            ];

            map?.fitBounds(bbox, {
                padding: 200
            });
        }
    };

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: (option) => (option as SearchResult).value
    });

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            InputProps={{
                ...params.InputProps,
                className: classes.input,
                ...(loading && {
                    endAdornment: (
                        <InputAdornment position="end" className={classes.circularProgress}>
                            <CircularProgress size={15} />
                        </InputAdornment>
                    )
                })
            }}
            className={classes.textField}
            placeholder="Search"
        />
    );

    const renderOption = (
        props: HTMLAttributes<HTMLLIElement>,
        option: unknown,
        state: AutocompleteRenderOptionState
    ) => {
        const optionWithType = option as SearchResult;
        const matches = match(optionWithType.value, state.inputValue);
        const parts = parse(optionWithType.value, matches);
        return (
            <li {...props} key={optionWithType.value + props.id}>
                <div>
                    {parts.map((part, index) => (
                        <span
                            key={index}
                            style={{
                                fontWeight: part.highlight ? 700 : 400
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </div>
            </li>
        );
    };

    return (
        <div className={classes.content}>
            <Autocomplete
                freeSolo
                forcePopupIcon
                disableClearable
                options={options}
                getOptionLabel={(option) => (option as SearchResult).value}
                onChange={zoomTo}
                loading={loading}
                filterOptions={filterOptions}
                renderInput={renderInput}
                renderOption={renderOption}
                onInputChange={onInputChange}
                isOptionEqualToValue={(a, b) => (a as SearchResult).value == (b as SearchResult).value}
            />
        </div>
    );
};

export default SearchBar;
