import TablePagination from "@mui/material/TablePagination";
import React from "react";
import { IconButtonProps, SelectProps } from "@mui/material";

type Props = {
    count?: number;
    rowsPerPage: number;
    onRowsPerPageChange: (rowsPerPage: number) => void;
    page: number;
    onPageChange: (page: number) => void;
};

const AttributeTablePagination = ({ count, rowsPerPage, onRowsPerPageChange, page, onPageChange }: Props) => (
    <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count ?? -1}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={
            {
                "data-testid": backIconButtonTestId
            } as DataTestIdProps
        }
        nextIconButtonProps={
            {
                "data-testid": nextIconButtonTestId
            } as DataTestIdProps
        }
        SelectProps={
            {
                "data-testid": rowsPerPageSelectTestId
            } as DataTestIdSelectProps
        }
        onPageChange={(_, page) => onPageChange(page)}
        onRowsPerPageChange={(e) => onRowsPerPageChange(parseInt(e.target.value))}
    />
);

export default AttributeTablePagination;

const backIconButtonTestId = "qa-attribute-table-pagination-back-icon-button";
const nextIconButtonTestId = "qa-attribute-table-pagination-next-icon-button";
const rowsPerPageSelectTestId = "qa-attribute-table-pagination-rows-per-page-select";

type DataTestIdProps = IconButtonProps & {
    "data-testid"?: string;
};

type DataTestIdSelectProps = SelectProps<unknown> & {
    "data-testid"?: string;
};
