import { makeStyles } from "@mui/styles";
import appStyles from "../../../../utils/styles";

export const useStyles = makeStyles((theme: any) => ({
    root: {
        position: "absolute",
        backgroundColor: "#fff",
        bottom: 35,
        left: 6,
        zIndex: 1,
        maxWidth: 385,
        borderRadius: 8
    },
    rootLegend: {
        position: "relative",
        zIndex: 1
    },
    rootClosed: {
        position: "absolute",
        backgroundColor: "transparent",
        bottom: 35,
        left: 6,
        zIndex: 1,
        minWidth: 20,
        opacity: 0.9
    },
    closeButton: {
        padding: 8,
        paddingLeft: 16,
        color: theme.customColors.appTextColor,
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid" + theme.customColors.borderColor
    },
    openButton: {
        padding: 8,
        backgroundColor: appStyles.color.HEADER_BACKGROUND,
        color: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "50%"
    },
    headerText: {
        fontWeight: "bold"
    }
}));
