import { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import config from "config";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import ZanderModal from "features/zander/components/ZanderModal/ZanderModal";
import { useEraInfoboxStyles } from "./styles";
import { iframeCopyToClipboardUrl } from "components/map/utils/iframeCopyToClipboardUrl";
import customToastr from "utils/customToastr";

type Props = {
    lat: number;
    lng: number;
    landSeaMask: number;
};

const EsoxInfobox = ({ lat, lng, landSeaMask }: Props) => {
    const classes = useEraInfoboxStyles();
    const [isZanderToolOpen, setIsZanderToolOpen] = useState(false);

    const location = {
        lat: lat.toFixed(2),
        lng: lng.toFixed(2)
    };
    const onDownload = () => {
        const link = new URL(config.eraDatasetResource);
        link.searchParams.append("location", `n${location.lat}_e${location.lng}`);
        link.searchParams.append("container", "esoxera5");
        window.open(decodeURIComponent(link.toString()), "_blank");
    };

    const onDownloadMetaOnly = () => {
        const link = new URL(config.eraDatasetResource);
        link.searchParams.append("container", "esoxera5");
        link.searchParams.append("location", `n${location.lat}_e${location.lng}`);
        link.searchParams.append("onlyData", "true");
        window.open(decodeURIComponent(link.toString()), "_blank");
    };

    const onCopyLink = () => {
        let queryParam = `lat=${location.lat}&lng=${location.lng}`;

        iframeCopyToClipboardUrl(queryParam);

        customToastr.success("The text has been copied");
    };

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <CustomTypography variant="h3" fontWeight="bold">
                        Download
                    </CustomTypography>
                </div>
                <div className={classes.content}>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1">Latitude</CustomTypography>
                        <CustomTypography variant="subtitle1"> {lat} </CustomTypography>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1">Longitude</CustomTypography>
                        <CustomTypography variant="subtitle1"> {lng} </CustomTypography>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1">Land-sea mask</CustomTypography>
                        <CustomTypography variant="subtitle1"> {landSeaMask} </CustomTypography>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1" style={{ flexGrow: 1 }}>
                            Copy direct link to data point:
                        </CustomTypography>
                        <Tooltip key="copyTT" title="Copy link" placement="left">
                            <IconButton onClick={onCopyLink} color="secondary" className={classes.squareBtn}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1" style={{ flexGrow: 1 }}>
                            Download metocean data + ESOX tool:
                        </CustomTypography>
                        <Tooltip key="copyTT" title="Download" placement="left">
                            <IconButton onClick={onDownload} color="secondary" className={classes.squareBtn}>
                                <DownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1" sx={{ flexGrow: 1 }}>
                            Download metocean data
                        </CustomTypography>
                        <Tooltip key="copyTT" title="Download" placement="left">
                            <IconButton onClick={onDownloadMetaOnly} color="secondary" className={classes.squareBtn}>
                                <DownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1" sx={{ flexGrow: 1 }}>
                            ESOX Zander weather window statistics
                        </CustomTypography>
                        <Tooltip key="copyTT" title="Zander Tool" placement="left">
                            <IconButton
                                color="secondary"
                                className={classes.squareBtn}
                                onClick={() => setIsZanderToolOpen(true)}
                            >
                                <BuildIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <ZanderModal open={isZanderToolOpen} onClose={() => setIsZanderToolOpen(false)} lat={lat} long={lng} />
        </>
    );
};

export default EsoxInfobox;
