import React from "react";
import { Grid, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff"
    },
    header: {
        padding: 16,
        borderBottom: "2px solid #f5f7f8"
    },
    section: {
        padding: 16,
        borderBottom: "2px solid #ececec",
        backgroundColor: "#fff"
    },
    outputType: {
        width: "50%",
        backgroundColor: "#ececec",
        textAlign: "center",
        padding: 8,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#dcdcdc"
        }
    },
    outputTypeSelected: {
        width: "50%",
        textAlign: "center",
        // border: '2px solid #0C567E',
        backgroundColor: "#9dbbcb",
        padding: 8,
        color: "#fff"
    }
}));

const PrintOutput = ({ outputType, setOutputType }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container className={classes.section}>
                    <Grid
                        item
                        xs={6}
                        className={outputType === "image" ? classes.outputTypeSelected : classes.outputType}
                        onClick={() => setOutputType("image")}
                    >
                        <Typography variant="subtitle1">Image</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className={outputType === "pdf" ? classes.outputTypeSelected : classes.outputType}
                        onClick={() => setOutputType("pdf")}
                    >
                        <Typography variant="subtitle1">PDF</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PrintOutput;
