import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    headerIconButton: {
        width: 30,
        height: 30,
        borderRadius: 4
    },
    icon: {
        color: theme.customColors.appTextColor
    }
}));
