import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        overflow: "auto"
    },
    title: {
        padding: "8px 16px"
    }
}));
