import OverflowTip from "../../../../../components/common/OverflowTip/OverflowTip";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Close, CloudDownload } from "@mui/icons-material";
import useAttributeTableHeaderStyles from "./styles";

type Props = {
    onClose: () => void;
    layerName: string;
    isDownloadable: boolean;
    numberOfSelectedFeatures: number;
    onDownloadClick: () => void;
};

const AttributeTableHeader = ({
    onClose,
    layerName,
    isDownloadable,
    numberOfSelectedFeatures,
    onDownloadClick
}: Props) => {
    const classes = useAttributeTableHeaderStyles();

    return (
        <div className={classes.header}>
            <OverflowTip typographyClassName={classes.headerText} variant="h6">
                Attribute table - {layerName}
            </OverflowTip>
            <div className={classes.downloadContainer}>
                {numberOfSelectedFeatures > 0 && (
                    <Typography className={classes.downloadSelectedValue} variant="subtitle2">
                        {numberOfSelectedFeatures}
                    </Typography>
                )}
                <Tooltip
                    title={
                        isDownloadable
                            ? numberOfSelectedFeatures > 0
                                ? "Download selected features"
                                : "Download all features"
                            : "Layer is not downloadable"
                    }
                >
                    <div>
                        <IconButton
                            disabled={!isDownloadable}
                            size="small"
                            aria-label="Download"
                            onClick={onDownloadClick}
                            className={classes.downloadButton}
                            data-testid={downloadTestId}
                        >
                            <CloudDownload />
                        </IconButton>
                    </div>
                </Tooltip>
            </div>
            <IconButton className={classes.closeButton} onClick={onClose} size="large" data-testid={closeTestId}>
                <Close />
            </IconButton>
        </div>
    );
};

export default AttributeTableHeader;

const closeTestId = "qa-attribute-table-close-button";
const downloadTestId = "qa-attribute-table-download-button";
