export const digitizeLayerToStyle = (layer, layerNumber) => ({
    name: layer.name,
    sourceName: layer.name,
    paint: layer.paint,
    layout: layer.layout,
    properties: layer.properties,
    type: layer.styleType,
    layerId: layer.datasetId + ":" + layerNumber,
    datasetId: layer.datasetId,
    sourceId: layer.datasetId
});

export const digitizeLayerTypeToGeometry = (layerType) => {
    const typeToGeometry = {
        point: "POINT",
        line: "LINESTRING",
        polygon: "POLYGON",
        circle: "POLYGON"
    };
    return typeToGeometry[layerType];
};

export const digitizeLayerToPartialAppLayer = (digitizeLayer) => {
    return {
        geometryType: digitizeLayerTypeToGeometry(digitizeLayer.type),
        name: digitizeLayer.name,
        type: "vector",
        resourceId: digitizeLayer.id
    };
};
