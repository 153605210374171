import makeStyles from "@mui/styles/makeStyles";

export const useCoordinatesWidgetStyles = makeStyles((theme: any) => ({
    root: {
        fontWeight: "bold",
        color: theme.customColors.black,
        backgroundColor: theme.customColors.appBgColorLight,
        borderWidth: "1px 1px 1px 1px",
        borderColor: theme.customColors.black,
        borderStyle: "solid",
        display: "flex",
        alignItems: "center"
    },
    coordinateContainer: {
        padding: "1px 6px 1px 6px"
    },
    iconStyle: {
        height: 15,
        color: theme.customColors.black
    },
    iconStyleDisabled: {
        height: 15,
        color: "grey"
    },
    coordinatesText: {
        fontSize: "0.685rem",
        padding: "0px 8px",
        minWidth: 90
    },
    crsBox: {
        padding: 8,
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginRight: 8
    },
    crsName: {
        textTransform: "uppercase"
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    copyButton: {
        cursor: "pointer"
    }
}));
