export function toPoint(lngLat, properties, id) {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [lngLat.lng, lngLat.lat]
        },
        properties: properties,
        id: id
    };
}

export function toLine(coordinates, properties) {
    return {
        type: "Feature",
        geometry: {
            type: "LineString",
            coordinates: [...coordinates]
        },
        properties: properties
    };
}

export function lineToPolygon(coordinates, properties) {
    coordinates = [...coordinates, coordinates[0].slice()];

    return {
        type: "Feature",
        geometry: {
            type: "Polygon",
            coordinates: [coordinates]
        },
        properties: properties
    };
}
