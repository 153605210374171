import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    version: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        color: theme.customColors.gray
    }
}));
