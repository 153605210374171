import { createSelector } from "reselect";
import { RootState } from "../store/models/RootState";
import { dictGetterHOF } from "./common";

export const getLayerGroups = (state: RootState) => state.layerSelector.layerGroups;
export const getLayerVisibilityMap = (state: RootState) => state.layerSelector.layerVisibilityMap;
export const getLayerStylesMap = (state: RootState) => state.layerSelector.layerStylesMap;
export const getStylerLayerId = (state: RootState) => state.layerSelector.stylerLayerId;
export const getShouldFilter = (state: RootState) => state.layerSelector.shouldFilter;
export const getFetchingLayers = (state: RootState) => state.layerSelector.loading;
export const getLayersError = (state: RootState) => state.layerSelector.error;
export const getMapZoom = (state: RootState) => state.layerSelector.mapZoom;
export const getFetchingGeotiff = (state: RootState) => state.layerSelector.fetchingGeotiff;

export const getLayerMetadataLoading = (state: RootState) => state.layerSelector.fetchingMetadata;
export const getLayerMetadata = (state: RootState) => state.layerSelector.layerMetadata;

export const getLayerVisibility = dictGetterHOF(getLayerVisibilityMap);

export const getLayerStyles = dictGetterHOF(getLayerStylesMap);

export const getLayerForStyling = createSelector([getLayerGroups, getStylerLayerId], (layerGroups, stylerLayerId) => {
    if (stylerLayerId) return layerGroups.getRecursive(stylerLayerId);
    return null;
});
