import Tooltip from "@mui/material/Tooltip";
import CustomTypography from "../CustomTypography/CustomTypography";

const ReadOnlyField = (props) => {
    return (
        <Tooltip placement="top-start" title={props.tooltipTitle || ""} disableHoverListener={!props.tooltip}>
            <div>
                <CustomTypography variant="body2" textWeight="bold">
                    {props.label}
                </CustomTypography>
                <CustomTypography variant="body2">{props.text}</CustomTypography>
            </div>
        </Tooltip>
    );
};
export default ReadOnlyField;
