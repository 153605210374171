import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    signOut: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        color: theme.customColors.primaryColor
    },
    signOutText: {
        marginLeft: 8
    }
}));
