import React, { memo, useContext, useState } from "react";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Icon } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CircularProgress, Divider, Tooltip } from "@mui/material";
import Chip from "@mui/material/Chip";
import * as mapActions from "../../../reducers/map";
import * as layerSelectorActions from "../../../reducers/layerSelector";
import Context from "./contextMenu";
import { useDispatch, useSelector } from "react-redux";
import { getLayerStyles, getLayerVisibility } from "../../../selectors/layerSelector";
import { toggleAppLayer } from "../../../actions/globalActions";
import OverflowTip from "../../common/OverflowTip/OverflowTip";
import { PROPERTY_TYPES } from "../../../constants/styles/propertyTypes";
import { defaultBufferOpacity } from "features/buffer/components/utils";
import { BufferWWContext } from "features/buffer/components/BufferWWContextProvider";
import useLayerStyles from "./styles/layerStyles";

const Layer = ({ layer, layerHandlers }) => {
  const classes = useLayerStyles();
    const [contextOpen, setContextOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [opacity, setOpacity] = useState(layer.isBuffer ? defaultBufferOpacity * 100 : 100);

    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const styles = useSelector(getLayerStyles(layer.resourceId));
    const dispatch = useDispatch();

    const bufferWWContext = useContext(BufferWWContext);

    const toggleCMenu = (e) => {
        if (e) e.stopPropagation();
        setContextOpen((contextOpen) => !contextOpen);
        return null;
    };

    const closeCMenu = () => {
        setContextOpen(false);
    };

    const handleOpacity = (e, newValue) => {
        setOpacity(newValue);
    };

    const handleCommitOpacity = () => {
        styles.forEach((style) => {
            const newProperties = style.properties.map((paintProperty) =>
                paintProperty.name.includes("opacity") ? { ...paintProperty, value: opacity / 100 } : paintProperty
            );
            dispatch(
                mapActions.updateMapPaint({
                    layerId: style.styleId,
                    properties: newProperties.filter((property) => property.type === PROPERTY_TYPES.paint)
                })
            );
            dispatch(
                layerSelectorActions.changePropertiesOfLayerStyle({
                    layerId: layer.resourceId,
                    styleId: style.styleId,
                    properties: newProperties
                })
            );
        });
    };

    const toggleVisibility = (visible) => {
        const resourceId = layer.resourceId;
        dispatch(toggleAppLayer({ resourceId, visible }));
    };

    const onToggle = () => {
        toggleVisibility(!visible);
    };

    // Layer's elements
    const renderVerticalBufferIndicator = () => {
      return (
        <Divider
          orientation="vertical"
          sx={{ mr: 2, mt: -2, height: `calc(100% + 16px)`, borderStyle: "dashed" }}
        />
      )
    }

    const renderSwicthAndName = () => {
      return (
        <>
          <Switch
            className={classes.switch}
            edge="end"
            checked={!!visible}
            color={!layer.isShown ? "default" : "primary"}
            inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
            onClick={() => onToggle()}
          />
          <div className="layer__text">
            <OverflowTip variant="body2" title="" color="inherit">
              {layer.name}
            </OverflowTip>
          </div>
        </>
      )
    }

    const renderTemporaryLayerIndicator = () => {
      return (
        <>
          <Tooltip title={`Temporary Layer`}>
            <Chip
              icon={<HourglassBottomIcon />}
              size="small"
              color="warning"
              sx={{
                mr: 1,
                "& .MuiChip-icon": { mr: 0.5 },
                "& .MuiChip-label": { display: "none" }
              }}
            />
          </Tooltip>
          <Divider orientation="vertical" sx={{ mr: 1 }} />
        </>
      )
    }

    const renderFilteredIndicator = () => {
      return (
        <>
          <Tooltip title={`Filter(s) applied`}>
            <Chip
              icon={<FilterAltIcon />}
              size="small"
              color="warning"
              sx={{
                mr: 1,
                "& .MuiChip-icon": { mr: 0.5 },
                "& .MuiChip-label": { display: "none" }
              }}
            />
          </Tooltip>
          <Divider orientation="vertical" sx={{ mr: 1 }} />
        </>
      )
    }

    const renderVisibilityIndicator = () => {
      return (
        <>
          <Tooltip title={`Layer is only visible between zoom levels ${layer.minZoom} and ${layer.maxZoom}`}>
            <Icon className={classes.grayIcon}>
              <InfoIcon />
            </Icon>
          </Tooltip>
          <Divider orientation="vertical" sx={{ mr: 1 }} />
        </>
      )
    }

    const renderLoadingAndCancelBtn = () => {
      return (
        <>
          <CircularProgress size={"1em"} sx={{ mx: 2 }} />
          <Divider orientation="vertical" sx={{ mr: 1 }} />
          <IconButton
            size="large"
            className={classes.cancelBtn}
            onClick={() => bufferWWContext.cancelGenerate(layer.resourceId)}
          >
            <Close fontSize="small" />
          </IconButton>
        </>
      )
    }

    const renderActionMenu = () => {
      return (
        <>
          <IconButton
            className={classes.contextMenuBtn}
            ref={anchorRef}
            aria-controls={contextOpen ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              e.currentTarget.blur();
              toggleCMenu();
            }}
          >
            <MoreHoriz />
          </IconButton>
          <Context
            layer={layer}
            onToggle={onToggle}
            anchorEl={anchorRef.current}
            closeCMenu={closeCMenu}
            open={contextOpen}
            toggleCMenu={toggleCMenu}
            opacity={opacity}
            handleOpacity={handleOpacity}
            handleCommitOpacity={handleCommitOpacity}
            layerHandlers={layerHandlers}
          />
        </>
      )
    }


    return (
        <div className="layer">
          { layer.isBuffer && renderVerticalBufferIndicator() }

          { renderSwicthAndName() }

          { layer.isBuffer && !layer.options.loading && renderTemporaryLayerIndicator() }

          { layer.isFiltered && renderFilteredIndicator() }

          { !layer.isShown && renderVisibilityIndicator() }

          { layer.options.loading && renderLoadingAndCancelBtn() }

          { !layer.options.loading && renderActionMenu() }
        </div>
    );
};

export default memo(Layer);
