import { useStyles } from "./style";
import clsx from "clsx";

type Props = {
    rasterColor?: string;
};

const RasterIcon = ({ rasterColor }: Props) => {
    const { legend, raster } = useStyles({
        rasterColor
    });

    return <div className={clsx(legend, raster)}></div>;
};

export default RasterIcon;
