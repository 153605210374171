import { useEffect } from "react";
import { AppLayer } from "../../../model/app/AppLayer";
import { PointLike } from "mapbox-gl";
import { useAppSelector } from "../../../store/hooks/useAppSelector";
import { getMapClickPos } from "../../../selectors/mapSelectors";
import { useRefData } from "../../../utils/customHooks/map/useRefData";
import { useMap } from "@emblautec/react-map-gl";
import { getLayerGroups, getLayerStylesMap, getLayerVisibilityMap } from "../../../selectors/layerSelector";
import { getAISSubscriptionData, getAISSubscriptionInfo } from "../../../selectors/aisSelectors";
import { Style } from "../../../model/style/Style";
import { SourceType } from "../../../model/enums/SourceType";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import { geometryTypeToInt } from "../../map/components/MainMap/utils";
import { capitalize } from "@mui/material";

const useOnMapClick = (
    onClick: (
        features: mapboxgl.MapboxGeoJSONFeature[],
        rasters: AppLayer[],
        sourceToNameMapping: Record<string, string>
    ) => void,
    onNoData: () => void
) => {
    const mapClickPos = useAppSelector(getMapClickPos);
    const { mainMap } = useMap();
    const layerGroups = useAppSelector(getLayerGroups);
    const layerVisibilityMap = useAppSelector(getLayerVisibilityMap);
    const layersStylesMap = useAppSelector(getLayerStylesMap);
    const subscriptionData = useAppSelector(getAISSubscriptionData);
    const subscriptionInfo = useAppSelector(getAISSubscriptionInfo);

    const handlerData = useRefData({
        layerGroups,
        subscriptionData,
        subscriptionInfo,
        layersStylesMap,
        layerVisibilityMap,
        mainMap
    });

    useEffect(() => {
        if (!mapClickPos) return;

        const { layerGroups, subscriptionData, layerVisibilityMap, mainMap, layersStylesMap } = handlerData.current;
        const layers: string[] = [];
        const rasters: AppLayer[] = [];
        const sourceToNameMapping: Record<string, string> = {};

        const addVisibleLayersAndRasters = (lat: number, lng: number) => {
            layerGroups.forLayersRecursive((layer: AppLayer) => {
                if (!layerVisibilityMap[layer.resourceId]) return;

                if (layer.type === SourceType.Raster) {
                    const bounds = layer.bounds.coordinates[0];

                    if (!booleanPointInPolygon(point([lng, lat]), polygon([bounds]))) return;

                    rasters.push({ ...layer });
                }

                //We sort the styles so that we always check clicks against the best geoemtry type
                const styleArrayCopy = [...layersStylesMap[layer.resourceId]].sort(
                    (style1: Style, style2: Style) => geometryTypeToInt(style1.type) - geometryTypeToInt(style2.type)
                );

                const firstStyleLayer = styleArrayCopy[0];

                if (firstStyleLayer) {
                    const sourceName = !!layer.sourceName ? layer.sourceName : layer.sourceId;
                    sourceToNameMapping[sourceName] = capitalize(layer.name);
                    layers.push(firstStyleLayer.styleId);
                }
            });
        };

        const addAisLayers = () => {
            for (const subscriptionId in subscriptionData) {
                const subscriptionName = subscriptionInfo.find((s) => s.id === subscriptionId)?.name;

                if (!subscriptionName) continue;

                sourceToNameMapping[subscriptionId] = capitalize(subscriptionName);
                layers.push(subscriptionId);
            }
        };

        addVisibleLayersAndRasters(mapClickPos.lat, mapClickPos.lng);
        addAisLayers();

        const bbox: [PointLike, PointLike] = [
            [mapClickPos.x - 3, mapClickPos.y - 3],
            [mapClickPos.x + 3, mapClickPos.y + 3]
        ];

        // filters labels (except for vessels
        const features = (mainMap?.queryRenderedFeatures(bbox, { layers }) || []).filter((feature) => {
            const isVesselFeature = !!subscriptionData[feature.source];

            return feature.layer.type !== "symbol" || isVesselFeature;
        });

        if (features.length === 0 && rasters.length === 0) {
            onNoData();

            return;
        }

        onClick(features, rasters, sourceToNameMapping);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapClickPos]);
};

export default useOnMapClick;
