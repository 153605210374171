import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import { getPrintFeatures } from "../../../../selectors/printSelectors";
import PrintMap from "../../../map/PrintMap/PrintMap";
import MapLegendLandscape from "../MapLegendLandscape/MapLegendLandscape";
import MapLegendPortrait from "../MapLegendPortrait/MapLegendPortrait";
import { useMapPreviewStyles } from "../styles/mapPreviewStyles";

const MapPreview = ({ dpi, ...props }) => {
    const { orientation } = props;

    const classes = useMapPreviewStyles({ orientation });

    const printFeatures = useSelector(getPrintFeatures);

    return (
        <Suspense
            fallback={
                <div className={classes.loaderContainer}>
                    <CircularProgress />
                </div>
            }
        >
            <div className={classes.root}>
                <div className={classes.modalMapContainer}>
                    <PrintMap dpi={dpi} />
                </div>
                {printFeatures.showMapLegend && orientation === "landscape" && (
                    <div className={classes.modalLegendLandscape}>
                        <MapLegendLandscape />
                    </div>
                )}
                {printFeatures.showMapLegend && orientation === "portrait" && (
                    <div className={classes.modalLegendPortrait}>
                        <MapLegendPortrait />
                    </div>
                )}
            </div>
        </Suspense>
    );
};

export default MapPreview;
