import { Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getIsDrawerOpen } from "../../../../../selectors/sidebarAndDrawer";
import { useNavStyles } from "../useNavStyles";
import clsx from "clsx";

const NavButton = ({ onClick, icon, name, active }) => {
    const classes = useNavStyles();
    const isDrawerOpen = useSelector(getIsDrawerOpen);

    return isDrawerOpen ? (
        <Button
            onClick={onClick}
            className={clsx(
                active ? classes.actionButtonActive : classes.actionButtonInactive,
                classes.actionButton,
                classes.navOpen
            )}
        >
            <div className={classes.toolIcon}>{icon}</div>
            <span className={classes.toolLabel}>{name}</span>
        </Button>
    ) : (
        <Tooltip title={name} placement="left">
            <IconButton
                onClick={onClick}
                className={clsx(
                    active ? classes.actionButtonActive : classes.actionButtonInactive,
                    classes.actionButton
                )}
                size="large"
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
};

export default NavButton;
