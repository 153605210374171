import React from "react";
import MapboxLanguage from "@emblautec/mapbox-gl-language";
import { useControl } from "@emblautec/react-map-gl";


// Mutually exclusive with the language selector
const MapLanguage = ({ language }) => {
    useControl(() => new MapboxLanguage({ defaultLanguage: language }));

    return null;
};

export default MapLanguage;
