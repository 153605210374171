import UserMenu from "components/common/UserMenu/UserMenu";
import ModulesPicker from "features/core/components/ModulesPicker/ModulesPicker";
import { useStyles } from "./styles";
import clsx from "clsx";
import { FC } from "react";

type Props = {
    className?: string;
    userMenuIsCollapsed?: boolean;
};

const HeaderButtons: FC<Props> = ({ className = "", userMenuIsCollapsed = false }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.headerButtons, className)}>
            <ModulesPicker />
            <UserMenu collapsed={userMenuIsCollapsed} />
        </div>
    );
};

export default HeaderButtons;
