import React, { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import Typography from "../CustomTypography/CustomTypography";
import { useStyles } from "./style.js";
import clsx from "clsx";

const OverflowTip = ({ typographyClassName = "", className = "", ...props }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textElementRef = React.createRef();
    const classes = useStyles();

    useEffect(() => {
        let t;
        const observer = new ResizeObserver((entries) => {
            const textElement = entries[0];
            //This is mainly used to check that the values are different than 0
            //When isOverflowed changes, the Typography we attach the ref to changes too, thus
            //the ref.current returns values of 0 (it gets unmounted). We can ignore this because we know we'll
            //receive new values from the newly mounted typography.
            if (textElement.target.scrollWidth && textElement.target.clientWidth) {
                // This was added to improve performance by preventing forced reflow
                t = setTimeout(
                    () => setIsOverflowed(textElement.target.scrollWidth > textElement.target.clientWidth),
                    1
                );
            }
        });
        const oldRef = textElementRef.current;
        observer.observe(oldRef);
        return () => {
            t && clearTimeout(t);
            oldRef && observer.unobserve(oldRef);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOverflowed, textElementRef]);

    return (
        <div className={clsx(className, classes.textDiv)}>
            {isOverflowed ? (
                <Tooltip title={props.children} arrow>
                    <div>
                        <Typography ref={textElementRef} noWrap className={typographyClassName} {...props}>
                            {props.children}
                        </Typography>
                    </div>
                </Tooltip>
            ) : (
                <Typography ref={textElementRef} className={typographyClassName} {...props}>
                    {props.children}
                </Typography>
            )}
        </div>
    );
};

export default OverflowTip;
