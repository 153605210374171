import { createSelector } from "reselect";
import { RootState } from "../store/models/RootState";

const getDigitizeLayers = (state: RootState) => state.digitize.layers;
const getLayerVisibilityMap = (state: RootState) => state.layerSelector.layerVisibilityMap;

export const getLegendHasEntries = createSelector(
    [getDigitizeLayers, getLayerVisibilityMap],
    (digitizeLayers: any[], layerVisibilityMap) => {
        for (const layer of digitizeLayers) {
            if (layer.selected) return true;
        }
        for (const layerVisibility of Object.values(layerVisibilityMap)) {
            if (layerVisibility) return true;
        }
        return false;
    }
);
