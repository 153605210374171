import {
    AttributionControl,
    FullscreenControl,
    LngLatBoundsLike,
    Marker,
    NavigationControl,
    ScaleControl,
    ViewStateChangeEvent
} from "@emblautec/react-map-gl";
import { Collapse } from "@mui/material";
import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getSelectedAppIsPublic, getSelectedAppSearchBar } from "selectors/appsSelectors";
import { mapZoomEnd } from "actions/globalActions";
import { terrainExaggerationParamName } from "constants/map/queryParams";
import { MapIds } from "model/enums/MapIds";
import { Widgets } from "model/enums/Widgets";
import { mapClick, setBasemap, setLanguage } from "reducers/map";
import { getEditing } from "selectors/digitizeSelectors";
import {
    getBasemap,
    getFilters,
    getLayouts,
    getMapLanguage,
    getPaints,
    getRestrictedView,
    getSources,
    getZoomRanges
} from "selectors/mapSelectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useAisLayers } from "utils/customHooks/map/useAisLayers";
import { useMapHandlers } from "utils/customHooks/map/useMapHandlers";
import { useMapStyle } from "utils/customHooks/map/useMapStyle";
import { useOrderedMapLayers } from "utils/customHooks/map/useOrderedMapLayers";
import BoxZoom from "components/map/components/BoxZoom/BoxZoom";
import MapInjector from "components/map/components/MapInjector/MapInjector";
import NoCanvasOutline from "components/map/components/NoCanvasOutline/NoCanvasOutline";
import { getMapOptions } from "components/map/utils/getDefaultMapOptions";
import { SearchBar as SearchBarType } from "features/searchBar/models/SearchBar";
import SearchBar from "features/searchBar/components/SearchBar/SearchBar";
import { getClientId, getProjectId } from "features/core/selectors";
import { getBasemaps, getLanguages, getToggledWidgets } from "features/mapTools/selectors";
import CoordinatesSearch from "features/mapTools/components/CoordinatesSearch/CoordinatesSearch";
import Buffer from "features/buffer/components/Buffer/Buffer";
import { closeWidgets } from "features/mapTools/slice";
import Measure from "components/sidebar/measure/measure";
import CustomMap from "components/map/CustomMap/CustomMap";
import TokenLoader from "components/map/CustomMap/TokenLoader";
import MapEditing from "components/map/infoBoxes/mapEditing";
import BasemapSelector from "components/map/mapTools/basemapSelector";
import ExaggerationSlider from "components/map/mapTools/exaggerationSlider";
import MapCopyState from "components/map/mapTools/mapCopyState";
import MapLanguageControl from "components/map/CustomControls/MapLanguageControl/MapLanguageControl";
import PitchToggleControl from "components/map/CustomControls/PitchToggleControl/PitchToggleControl";
import Legend from "components/map/mapTools/Legend/Legend";
import MapTools from "components/map/mapTools/MapTools";
import { useAppDispatch } from "../../../../store/hooks/useAppDispatch";
import { MapLayerMouseEvent } from "mapbox-gl";
import InfoboxPopup from "../../../infobox/components/InfoboxPopup/InfoboxPopup";

type Props = {
    location: { lon: number; lat: number } | null;
};

const MainMap: FC<Props> = ({ location }) => {
    const { pushHandler, popHandler, handlers } = useMapHandlers({
        mapId: MapIds.MainMap
    });

    const getAccessTokenRef = useRef<() => string>(() => "");
    const [fullscreenEl, setFullscreenEl] = useState<Element>(document.body);

    const [terrainEnabled, setTerrainEnabled] = useState(() => {
        const queryParams = new URLSearchParams(document.location.search);
        return !!queryParams.get(terrainExaggerationParamName);
    });

    const [terrainExaggeration, setTerrainExaggeration] = useState(() => {
        const queryParams = new URLSearchParams(document.location.search);
        return parseFloat(queryParams.get(terrainExaggerationParamName) || "1");
    });

    const sources = useAppSelector(getSources);

    const paintsDict = useAppSelector(getPaints);
    const layoutsDict = useAppSelector(getLayouts);
    const zoomRangesDict = useAppSelector(getZoomRanges);

    const basemap = useAppSelector(getBasemap);
    const basemaps = useAppSelector(getBasemaps);
    const mapLanguage = useAppSelector(getMapLanguage);
    const languages = useAppSelector(getLanguages);
    const clientId = useAppSelector(getClientId);
    const projectId = useAppSelector(getProjectId);
    const enabledWidgets = useAppSelector(getToggledWidgets);
    const editingFeature = useAppSelector(getEditing);
    const isPublic: boolean | undefined = useAppSelector(getSelectedAppIsPublic);
    const searchBar: SearchBarType | null = useAppSelector(getSelectedAppSearchBar);
    const filters = useAppSelector(getFilters);
    const restrictedView = useAppSelector(getRestrictedView);

    const aisLayersData = useAisLayers();

    const layers = useOrderedMapLayers();

    const { mapStyle, setMapStyle } = useMapStyle({
        basemap,
        extraData: {
            sources,
            layers: [...layers, ...aisLayersData.aisLayers],
            paintsDict: { ...paintsDict, ...aisLayersData.aisPaintsDict },
            layoutsDict: { ...layoutsDict, ...aisLayersData.aisLayoutsDict },
            zoomRangesDict: { ...zoomRangesDict, ...aisLayersData.aisZoomRangesDict },
            isPublic: isPublic ?? false
        }
    });

    const glMapOptions = getMapOptions(getAccessTokenRef, clientId, projectId);

    const dispatch = useAppDispatch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onClick = useCallback(
        (e: MapLayerMouseEvent) =>
            dispatch(mapClick({ lat: e.lngLat.lat, lng: e.lngLat.lng, x: e.point.x, y: e.point.y })),
        []
    );

    useEffect(() => {
        document.onfullscreenchange = () => {
            setFullscreenEl(document.fullscreenElement || document.body);
        };

        return () => {
            document.onfullscreenchange = null;
            dispatch(closeWidgets());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onZoomEnd = useCallback((e: ViewStateChangeEvent) => {
        const zoom = e.viewState.zoom;
        dispatch(mapZoomEnd(zoom));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set the handlers
    useEffect(() => {
        pushHandler("onZoomEnd", onZoomEnd);
        pushHandler("onClick", onClick);

        return () => {
            popHandler("onZoomEnd");
            popHandler("onClick");
        };
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
    }, [onZoomEnd, onClick]);

    const onBasemapChanged = (basemap: string) => {
        dispatch(setBasemap(basemap));
    };

    const onLanguageChanged = (language: string) => {
        dispatch(setLanguage(language));
    };

    const onTerrainToggled = () => {
        const isTerrainEnabled = !terrainEnabled;
        setTerrainEnabled(isTerrainEnabled);
    };

    const onTerrainExaggerationChanged = (exaggeration: number) => {
        setTerrainExaggeration(exaggeration);
    };

    const terrainData = useMemo(
        () => ({ source: "mapbox-dem", exaggeration: terrainExaggeration }),
        [terrainExaggeration]
    );

    const mapProps: ComponentProps<typeof CustomMap> = {
        id: MapIds.MainMap,
        maxZoom: undefined,
        minZoom: undefined,
        maxBounds: undefined,
        sources,
        layers,
        aisLayersData,
        paintsDict,
        layoutsDict,
        zoomRangesDict,
        mapStyle,
        terrain: terrainEnabled ? terrainData : undefined,
        isPublic: isPublic ?? false,
        filters: filters ?? {},
        ...handlers,
        ...glMapOptions
    };

    if (restrictedView?.enabled) {
        mapProps.maxZoom = restrictedView.maxZoom;
        mapProps.minZoom = restrictedView.minZoom;
        mapProps.maxBounds = restrictedView.mapBounds as LngLatBoundsLike;
    }

    return (
        <>
            {isPublic !== undefined && !isPublic && <TokenLoader getAccessTokenRef={getAccessTokenRef} />}
            <CustomMap {...mapProps}>
                {/* Logic Components / Initting*/}
                <BoxZoom />
                <NoCanvasOutline />
                {/*Extra info/Tools  */}
                <Collapse in={editingFeature} timeout={500}>
                    <MapEditing />
                </Collapse>
                <BasemapSelector
                    value={basemap}
                    basemaps={basemaps}
                    onChange={onBasemapChanged}
                    fullScreenEl={fullscreenEl}
                />
                <ExaggerationSlider
                    fullScreenEl={fullscreenEl}
                    terrainEnabled={terrainEnabled}
                    onTerrainToggled={onTerrainToggled}
                    exaggerationValue={terrainExaggeration}
                    onTerrainExaggerationChanged={onTerrainExaggerationChanged}
                    onTerrainExaggerationCommitted={() => {}} //Will be removed when we convert the component to ts
                />
                <NavigationControl />
                <FullscreenControl />
                <MapCopyState />

                <MapLanguageControl
                    defaultLanguage={mapLanguage}
                    mapLanguage={mapLanguage}
                    languages={languages}
                    onLanguageChanged={onLanguageChanged}
                    setMapStyle={setMapStyle}
                    position="top-right"
                />
                <PitchToggleControl position="top-right" setTerrainToggle={setTerrainEnabled} />
                <Legend />

                {!!searchBar && <SearchBar />}
                {location && <Marker latitude={location.lat} longitude={location.lon} />}

                {/*Widgets*/}

                <MapInjector>
                    {enabledWidgets.hasOwnProperty(Widgets.Measure) && <Measure />}
                    {enabledWidgets.hasOwnProperty(Widgets.Search) && <CoordinatesSearch />}
                    {enabledWidgets.hasOwnProperty(Widgets.Buffer) && <Buffer layer={enabledWidgets[Widgets.Buffer]} />}
                </MapInjector>

                {/*Bottom controls */}
                <MapTools />
                <AttributionControl
                    customAttribution={
                        '<a class="map-attribution" href="https://lautec.com/" target="_blank">© LAUTEC</a>'
                    }
                />
                <ScaleControl position="bottom-right" />
                <InfoboxPopup />
            </CustomMap>
        </>
    );
};

export default MainMap;
