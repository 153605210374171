import { mapIdsArray } from "../../../../model/enums/MapIds";
import { HandlerStacksStore, PopHandlerFT, PushHandlerFT, PushHandlersFT } from "./types";

export const defaultHandlerStoreValue = mapIdsArray.reduce((acc, mapId) => {
    acc[mapId] = {};
    return acc;
}, {}) as HandlerStacksStore; // This cast is needed because ts doesn't recognize the presence of all the enum values

export const defaultPushHandlerFunctionValue: PushHandlerFT = () => {};

export const defaultPushHandlersFunctionValue: PushHandlersFT = () => {};

export const defaultPopHandlerFunctionValue: PopHandlerFT = () => {};
