import fileExtensions from "../../constants/files/fileExtensions";
import { fileTypes } from "../../constants/files/fileTypes";

export const fileTypeToDownloadExtension = (fileType) => {
    switch (fileType) {
        case fileTypes.GEO_JSON:
            return fileExtensions.GEO_JSON;
        case fileTypes.GEO_PACKAGE:
            return fileExtensions.GEO_PACKAGE;
        case fileTypes.CSV:
            return fileExtensions.CSV;
        case fileTypes.SHAPEFILE:
            return fileExtensions.SHAPEFILE;
        default:
            return "";
    }
};
