export enum HelperLayersIds {
    SymbolStartLayer = "symbol-section-start-layer",
    GeometricStartLayer = "geometric-section-start-layer"
}

const geometricHelperLayer = {
    type: "background",
    layout: { visibility: "none" },
    paint: {},
    id: HelperLayersIds.GeometricStartLayer
} as const;

const symbolHelperLayer = {
    type: "background",
    layout: { visibility: "none" },
    paint: {},
    id: HelperLayersIds.SymbolStartLayer
} as const;

export const HelperLayers = {
    SymbolHelperLayer: symbolHelperLayer,
    GeometricHelperLayer: geometricHelperLayer
} as const;
