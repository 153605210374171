import { createSlice } from "@reduxjs/toolkit";
import { Infobox } from "./models/Infobox";
import { fetchInfoboxes } from "./actions";

type InfoboxState = {
    infoboxes: Infobox[];
    fetching: boolean;
    loading: boolean;
};

const initialState: InfoboxState = {
    infoboxes: [],
    fetching: false,
    loading: false
};

const infoboxSlice = createSlice({
    name: "infobox",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfoboxes.pending, (state) => {
                state.fetching = true;
            })
            .addCase(fetchInfoboxes.rejected, (state) => {
                state.infoboxes = [];
            })
            .addCase(fetchInfoboxes.fulfilled, (state, { payload }) => {
                state.infoboxes = payload;
            });
    }
});

export default infoboxSlice.reducer;
