import { useEffect } from "react";
import { useScopedDraw } from "../../../../utils/customHooks/map/useScopedDraw";
import { useDigitizeFeatures } from "../../PrintMap/logic/useDigitizeFeatures";

// TODO: add types for Digitize
const DigitizeLayers = () => {
    const draw = useScopedDraw();
    const digitizeFeatures = useDigitizeFeatures();

    useEffect(() => {
        addFeatures(digitizeFeatures);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addFeatures = (features) => {
        if (features.length > 0) {
            const drawnFeatures = {
                type: "FeatureCollection",
                features,
                paint: {
                    "line-color": "red"
                }
            };
            // Last time when tried, I had problems casting anything to fit this type
            draw.add(drawnFeatures);
        }
    };

    return null;
};

export default DigitizeLayers;
