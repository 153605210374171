import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as yup from "yup";
import { AuthenticationProvider } from "app/providers/AuthenticationProvider/AuthenticationProvider";
import dayjs from "dayjs";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import MapProviders from "./app/providers/MapProviders/MapProviders";
import StylingProviders from "./app/providers/StylingProviders/StylingProviders";
import { store } from "./store/store";
import "./utils/dayjs/customLocale";

const utc = require("dayjs/plugin/utc");
const relativeTime = require("dayjs/plugin/relativeTime");
const localizedFormat = require("dayjs/plugin/localizedFormat");

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <StylingProviders>
                <MapProviders>
                    <AuthenticationProvider>
                        <App />
                    </AuthenticationProvider>
                </MapProviders>
            </StylingProviders>
        </Provider>
    </Router>,
    document.getElementById("root")
);

serviceWorkerRegistration.registerTileCache();
