import React from "react";
import { Typography, IconButton, Grid, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { VisibilityOff } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1
    },
    title: {
        padding: "8px 16px"
    },
    visibilityOff: {
        color: "#000",
        marginRight: 20
    }
}));

const FiltersRegion = ({ handleHideAll }) => {
    const classes = useStyles();
    const onHideAllToggle = () => {
        handleHideAll();
    };

    return (
        <Grid container className={classes.root} justifyContent="flex-end" alignItems="center">
            <Grid item xs={10}>
                <Grid container justifyContent="flex-start" className={classes.title}>
                    <Typography variant="h6">Layers</Typography>
                </Grid>
            </Grid>
            <Grid item xs={2}>
                <Grid container justifyContent="flex-end">
                    <Tooltip title="Turn off all layers">
                        <IconButton className={classes.visibilityOff} onClick={onHideAllToggle} size="large">
                            <VisibilityOff />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FiltersRegion;
