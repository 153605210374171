import React from "react";
import { useStyles } from "./styles";

type Props = {
    circleColor?: string;
};

const PointIcon = ({ circleColor }: Props) => {
    const { legend, circle } = useStyles({ circleColor });
    return <div className={legend}>{circleColor && <div className={circle} />}</div>;
};

export default PointIcon;
