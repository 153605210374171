import * as types from "./actionTypes";
import axiosClient from "./apiClient.ts";

export function getApps() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_APPS_START });
        return axiosClient
            .get("application/published")
            .then(function (res) {
                return dispatch({
                    type: types.FETCH_APPS_COMPLETE,
                    result: res.data.map((app) => ({
                        id: app.id,
                        name: app.name,
                        modifiedUtc: app.modifiedUtc,
                        isPublished: app.isPublished,
                        public: app.public
                    }))
                });
            })
            .catch((err) => {
                dispatch({ type: types.FETCH_APPS_FAILED, result: err.message });
                return null;
            });
    };
}

export function getDistinctColumnValues(datasetId, column) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/distinct?column=` + column).then(function (res) {
            return { result: res.data };
        });
    };
}

export function getDatasetColumns(datasetId) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/columns`).then(function (res) {
            return { result: res.data, id: datasetId };
        });
    };
}

export function getMinMaxColumnValues(datasetId, column) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/minmax?column=` + column).then(function (res) {
            return { result: res.data };
        });
    };
}
