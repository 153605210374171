import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "actions/apiClient";
import { HealthEnum } from "./model/HealthEnum";

export const fetchHealth = createAsyncThunk<HealthEnum, undefined, { rejectValue: HealthEnum }>(
    "fetchHealth",
    (_, { rejectWithValue }) =>
        axiosClient
            .get<HealthEnum>("health")
            .then((res) => res.data)
            .catch(() => rejectWithValue(HealthEnum.Unhealthy))
);
