import { FC } from "react";
import { MenuItem, Typography, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks/useAppSelector";
import ButtonLink from "components/common/ButtonLink/ButtonLink";
import { appParam, clientIdParam, projectIdParam } from "constants/map/queryParams";
import clsx from "clsx";
import config from "config";
import { getClientById } from "features/core/selectors";
import { CoreClient } from "features/core/models/CoreClient";
import { CoreProject } from "features/core/models/CoreProject";
import { StatusType } from "features/core/models/StatusType";
import { useStyles } from "./style";

type Params = {
    clientId: string;
    projectId: string;
};

type Props = {
    className?: string;
};

const ProjectsPicker: FC<Props> = (props) => {
    const classes = useStyles();

    const { clientId, projectId } = useParams<Params>();
    const currentClient: CoreClient | undefined = useAppSelector(getClientById(clientId));

    const currentProject = currentClient?.projects?.find((x) => x.id === projectId);

    const getPathname = (project: CoreProject) => {
        const redirectToLanding = project.region?.id !== currentProject?.region?.id;
        if (!redirectToLanding) return `/${project.clientId}/${project.id}`;

        const queryParams = new URLSearchParams();
        queryParams.append(clientIdParam, project.clientId);
        queryParams.append(projectIdParam, project.id);
        queryParams.append(appParam, "viewer");
        return `${config.landingUrl}?${queryParams.toString()}`;
    };

    const renderCoreProjects = () => {
        return currentClient?.projects
            ?.filter((x) => x.status === StatusType.Active)
            .map((project) => (
                <MenuItem
                    key={project.id}
                    className={classes.project}
                    component={ButtonLink}
                    to={getPathname(project)}
                    value={project.id}
                >
                    <Typography className={classes.projectName} variant="body2">
                        {project.name}
                    </Typography>
                    {!!project.logoUrl && <img src={project.logoUrl} className={classes.projectLogo} alt="" />}
                </MenuItem>
            ));
    };

    return (
        <div className={clsx(classes.projectsPickerWrapper, props.className)}>
            <Select
                inputProps={{ className: classes.projectsPicker }}
                id="projects-picker-select"
                value={currentProject?.id ?? "--noproject--"}
                variant="standard"
                disableUnderline
            >
                {renderCoreProjects()}
                <MenuItem className={classes.selectProjectMenuItem} value={"--noproject--"}>
                    <span className={classes.selectProjectText}>Select project</span>
                </MenuItem>
            </Select>
        </div>
    );
};

export default ProjectsPicker;
