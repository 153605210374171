import makeStyles from "@mui/styles/makeStyles";

const useAttributeTableHeaderStyles = makeStyles((theme: any) => ({
    header: {
        position: "relative",
        backgroundColor: theme.customColors.primaryColor,
        padding: theme.spacing(1),
        color: theme.customColors.white,
        textAlign: "center"
    },
    headerText: {
        textAlign: "center",
        color: theme.customColors.white,
        paddingRight: 86
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0,
        color: theme.customColors.white
    },
    downloadContainer: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 7,
        right: 50
    },
    downloadSelectedValue: {
        color: theme.customColors.white,
        marginRight: 5,
        marginTop: 2
    },
    downloadButton: {
        color: theme.customColors.white
    }
}));

export default useAttributeTableHeaderStyles;
