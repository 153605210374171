export const measureJss = (theme) => ({
    root: {
        position: "absolute",
        top: 16,
        left: 16,
        zIndex: 2,
        backgroundColor: "#fff",
        minWidth: 300,
        borderRadius: 4
    },
    header: {
        padding: 8,
        paddingLeft: 16,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f5f7f8",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    headerText: {
        flexGrow: 1,
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"]
    },
    content: {
        padding: 16
    },
    copyBtn: {
        padding: 5,
        minWidth: 0
    },
    arrowIcon: {
        marginLeft: 8
    },
    infoRow: {
        display: "flex"
    },
    infoRowSpan: {
        flexGrow: 1
    },
    measurementUnit: {
        justifyContent: "left",
        paddingLeft: 10,
        paddingRight: 0
    },
    measurementUnitText: {
        textTransform: "lowercase"
    },
    measurementValue: {
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1.75,
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        paddingRight: 8,
        alignItems: "center",
        display: "inline-flex"
    },
    infoPanel: {
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
});
