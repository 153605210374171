import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosError, AxiosRequestConfig } from "axios";
import apiClient from "../actions/apiClient";

type ErrorType = {
    status: number;
    message: string;
};

const axiosBaseQuery =
    (
        baseUrl: string
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig["method"];
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
            cancelToken?: AxiosRequestConfig["cancelToken"];
            onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
        },
        unknown,
        ErrorType
    > =>
    ({ url, method, data, params, cancelToken, onUploadProgress }) =>
        new Promise((resolve, reject) => {
            apiClient
                .request({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    cancelToken,
                    onUploadProgress
                })
                .then((result) => {
                    resolve({ data: result.data });
                })
                .catch((axiosError) => {
                    const err = axiosError as AxiosError;

                    reject({
                        status: err.response?.status,
                        message: err.response?.data.error || "An unexpected error occurred"
                    });
                });
        });

export const isApiError = (error: any): error is ErrorType => error !== undefined && "message" in error;

export default axiosBaseQuery;
