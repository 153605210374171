import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./customBaseQuery";
import config from "../config";

const baseAtlasApi = createApi({
    baseQuery: axiosBaseQuery(config.apiUrl),
    endpoints: () => ({})
});

export default baseAtlasApi;

export const resetApiState = baseAtlasApi.util?.resetApiState;
