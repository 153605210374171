import { FC } from "react";
import { useStyles } from "./styles";
import config from "config";

type Props = {
    isPublic?: boolean;
};

const Logo: FC<Props> = ({ isPublic = false }) => {
    const classes = useStyles();
    return isPublic ? (
        <img className={classes.logo} src="/media/Lautec_WindGIS-ProductLogo_Black.svg" alt="LAUTEC WindGIS"></img>
    ) : (
        <a href={config.landingUrl} className={classes.link}>
            <img className={classes.logo} src="/media/Lautec_WindGIS-ProductLogo_Black.svg" alt="LAUTEC WindGIS"></img>
        </a>
    );
};

export default Logo;
