import { Tooltip } from "@mui/material";
import { ComponentProps, FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { getIsDrawerOpen } from "../../../../../selectors/sidebarAndDrawer";
import ButtonLink from "../../../../common/ButtonLink/ButtonLink";
import { useNavStyles } from "../useNavStyles";
import clsx from "clsx";

type Props = ComponentProps<typeof ButtonLink> & {
    icon: ReactNode;
    name: string;
    active: boolean;
};

const NavLink: FC<Props> = ({ icon, name, active, ...props }) => {
    const classes = useNavStyles();
    const isDrawerOpen = useSelector(getIsDrawerOpen);

    const renderLink = () => (
        <ButtonLink
            className={clsx(
                active ? classes.actionButtonActive : classes.actionButtonInactive,
                classes.actionButton,
                isDrawerOpen && classes.navOpen
            )}
            {...props}
        >
            <span className={isDrawerOpen ? classes.toolIcon : classes.toolIconClosed}>{icon}</span>
            <span className={classes.toolLabel}>{isDrawerOpen && name}</span>
        </ButtonLink>
    );

    return isDrawerOpen ? (
        renderLink()
    ) : (
        <Tooltip title={name} placement="left">
            {renderLink()}
        </Tooltip>
    );
};

export default NavLink;
