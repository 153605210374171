import { ComponentProps, MutableRefObject } from "react";
import Map from "@emblautec/react-map-gl";
import config from "../../../config";

const transformRequestResourceTypes = ["Tile", "SpriteJSON", "SpriteImage"];

export const getMapOptions = (
    getAuthToken: MutableRefObject<() => string>,
    clientId: number | null,
    projectId: number | null
): Partial<ComponentProps<typeof Map>> => ({
    mapboxAccessToken: config.mapboxApiKey,
    dragPan: true,
    optimizeForTerrain: false,
    styleDiffing: false,
    hash: false,
    maxZoom: 24,
    antialias: true,
    boxZoom: true,
    attributionControl: false,
    //@ts-ignore
    useWebGL2: true,
    transformRequest: (url: string, resourceType: string) => {
        if (transformRequestResourceTypes.includes(resourceType) && url.startsWith(config.apiUrl)) {
            return {
                url: `${url}?key=${getAuthToken.current()}&ClientId=${clientId}&ProjectId=${projectId}`
            };
        }
        return { url };
    }
});
