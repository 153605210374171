import makeStyles from "@mui/styles/makeStyles";

const useAttributeRowStyles = makeStyles((theme: any) => ({
    cell: {
        borderRight: "1px solid lightgrey",
        cursor: "pointer",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 200
    }
}));

export default useAttributeRowStyles;
