import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    root: {
        padding: 10,
        backgroundColor: "#fff"
    },
    innerRoot: {
        border: "1px solid grey"
    },
    titlecontainer: {
        borderBottom: "1px solid " + theme.customColors.borderColor,
        marginBottom: 8
    },
    legendTitle: {
        fontSize: 14,
        fontWeight: "bold",
        width: "15%",
        textAlign: "center"
    },
    rightSidePanel: {
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 0"
    },
    sectionStyle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 20
    },
    notesSectionStyle: {
        backgroundColor: "#fff",
        borderTop: "1px solid lightgrey",
        color: "#000",
        fontSize: 20
    },
    labelsContainer: {
        maxHeight: 240,
        overflow: "hidden"
    }
}));
