import { ZanderParameter } from "./models/ZanderParameter";
import { ZanderParameterTypeEnum } from "./models/ZanderParameterTypeEnum";
import { ZanderSheet } from "./models/ZanderSheet";
import * as yup from "yup";
import sheetsSchema from "./validators/sheetsSchema";
import { MAX_HOUR, MIN_HOUR } from "./constants";

yup.addMethod(yup.array, "unique", function (message, mapper = (a: any) => a) {
    return this.test("unique", message, function (list: any[]) {
        return list?.length === new Set(list?.map(mapper)).size;
    });
});

export const parametersDisplayNames = {
    [ZanderParameterTypeEnum.WindSpeed10]: "Mean wind speed at 10m height [m/s]",
    [ZanderParameterTypeEnum.WindSpeed100]: "Mean wind speed at 100m height [m/s]",
    [ZanderParameterTypeEnum.WaveHeight]: "Significant wave height (Hs) [m]",
    [ZanderParameterTypeEnum.WavePeriod]: "Peak wave period (Tp) [s]"
};

const initialPercentiles = [50, 80];

const initialParameters: ZanderParameter[] = [
    {
        type: ZanderParameterTypeEnum.WindSpeed10,
        maxLimits: []
    },
    {
        type: ZanderParameterTypeEnum.WindSpeed100,
        maxLimits: [14]
    },
    {
        type: ZanderParameterTypeEnum.WaveHeight,
        maxLimits: [1.75, 2]
    },
    {
        type: ZanderParameterTypeEnum.WavePeriod,
        maxLimits: []
    }
];

const emptyParameters: ZanderParameter[] = [
    {
        type: ZanderParameterTypeEnum.WindSpeed10,
        maxLimits: []
    },
    {
        type: ZanderParameterTypeEnum.WindSpeed100,
        maxLimits: []
    },
    {
        type: ZanderParameterTypeEnum.WaveHeight,
        maxLimits: []
    },
    {
        type: ZanderParameterTypeEnum.WavePeriod,
        maxLimits: []
    }
];

export const initialSheet: ZanderSheet = {
    weatherWindowDurations: [3, 6, 12],
    parameters: initialParameters,
    startHour: 0,
    endHour: 24,
    percentiles: initialPercentiles
};

export const emptySheet: ZanderSheet = {
    weatherWindowDurations: [],
    parameters: emptyParameters,
    startHour: 0,
    endHour: 24,
    percentiles: []
};

export const sanitizeSheets = (sheets: ZanderSheet[]) =>
    sheets.map((sheet) => ({
        ...sheet,
        percentiles: sheet.percentiles.map((percentile) => percentile / 100),
        parameters: sheet.parameters.filter((params) => params.maxLimits.length > 0)
    }));

export const validateSheets = (sheets: ZanderSheet[]) => {
    for (const sheet of sheets) {
        if (sheet.startHour === MIN_HOUR && sheet.endHour === MAX_HOUR) {
            continue;
        }

        const maxLength = sheet.endHour - sheet.startHour;

        if (sheet.weatherWindowDurations.some((duration) => duration > maxLength)) {
            return false;
        }
    }

    try {
        sheetsSchema.validateSync(sheets);
        return true;
    } catch (error) {
        return false;
    }
};

export const linkDownload = (link: string) => {
    const a = document.createElement("a");
    a.download = "esoxDownload";
    a.href = link;
    a.click();
};
