export const SOURCE_TYPES = {
    vector: "vector",
    raster: "raster",
    rasterDem: "raster-dem",
    geojson: "geojson",
    image: "image",
    video: "video",
    custom: "custom",
    canvas: "canvas"
} as const;

type SourceKeys = keyof typeof SOURCE_TYPES;
export type SourceTypes = typeof SOURCE_TYPES[SourceKeys];
