import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles<Theme>((theme) => ({
    metadataContainer: {
        height: 400,
        minWidth: 450,
        maxWidth: 450,
        overflow: "auto",
        padding: "16px"
    }
}));

export default useStyles;
