import baseAtlasApi from "../../store/baseAtlasApi";
import { DprDataset } from "./models/DprDataset";
import { DprDatasetColumns } from "./models/DprDatasetColumns";
import { DprMilestoneHistoryEntry } from "./models/DprMilestoneHistoryEntry";

export const dprIntegrationApi = baseAtlasApi.injectEndpoints({
    endpoints: (build) => ({
        fetchDprDataset: build.query<DprDataset, string>({
            query: (datasetId) => ({
                url: `dpr/milestones/datasets/${datasetId}`,
                method: "GET"
            })
        }),
        fetchLocationStatusHistory: build.query<
            DprMilestoneHistoryEntry[],
            { datasetId: string; locationName: string }
        >({
            query: ({ datasetId, locationName }) => ({
                url: `dpr/milestones/datasets/${datasetId}/locations/${locationName}/status/history`,
                method: "GET"
            })
        }),
        fetchDprDatasetColumns: build.query<DprDatasetColumns, string>({
            query: (datasetId) => ({
                url: `dpr/milestones/datasets/${datasetId}/columns`,
                method: "GET"
            })
        })
    })
});

export const {
    useFetchDprDatasetQuery,
    useFetchLocationStatusHistoryQuery,
    useFetchDprDatasetColumnsQuery,
    usePrefetch: useDatasetsPrefetch
} = dprIntegrationApi;
