import makeStyles from "@mui/styles/makeStyles";

export const useAppViewStyles = makeStyles((theme: any) => ({
    root: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    },
    topbar: {
        display: "flex"
    },
    topbarLeft: {
        display: "flex",
        alignItems: "center"
    },
    logo: {
        height: 30
    },
    container: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
    },
    appsGrid: {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.customSpacing.appCardWidth}px, 1fr))`,
        gap: theme.spacing(3),
        padding: theme.spacing(3, 2.5)
    },
    searchFieldContainer: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        minHeight: 60,
        paddingInline: theme.spacing(2.5),
        backgroundColor: theme.customColors.appBgColor
    },
    searchField: {
        width: "100%",
        maxWidth: 400,
    },
    selectAppTitle: {
        fontSize: 20,
        fontWeight: 400,
        margin: theme.spacing(1, 0, 1, 2.5)
    },
    searchIcon: {
        color: theme.customColors.darkGray,
        width: 20
    },
    noApps: {
        display: "flex",
        justifyContent: "center",
        color: theme.customColors.mutedColor
    }
}));
