import { RefObject, useCallback, useMemo } from "react";

type Args = { ref: RefObject<HTMLElement>; minHeight: number };

const useResizableMouseEvent = ({ ref, minHeight }: Args) => {
    const onMouseDown = useCallback(() => {
        document.addEventListener("mouseup", onMouseUp, true);
        document.addEventListener("mousemove", onMouseMove, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onMouseUp = useCallback(() => {
        document.removeEventListener("mouseup", onMouseUp, true);
        document.removeEventListener("mousemove", onMouseMove, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const offsetHeight = useMemo(() => document.body.offsetHeight, []);

    const onMouseMove = useCallback((e: MouseEvent) => {
        e.stopPropagation();

        if (!!ref.current) {
            let newHeight = offsetHeight - e.clientY;

            if (newHeight <= minHeight) newHeight = minHeight;

            ref.current.style.height = `${newHeight}px`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return onMouseDown;
};
export default useResizableMouseEvent;
