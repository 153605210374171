import React from "react";
import { useStyles } from "./style";
import {Icons} from '@emblautec/layer-styler-component';

type Props = {
    symbolColor: string;
    symbolImage: string;
    symbolIconColor: string;
};

const SymbolIcon = ({ symbolColor,symbolImage, symbolIconColor }: Props) => {
    const { symbol, legend } = useStyles({ symbolColor, symbolIconColor });
    return (
        <div className={legend}>
            <div className={symbol}>{symbolImage !== "" ? Icons[symbolImage] : "A"}</div>
        </div>
    );
};

export default SymbolIcon;
