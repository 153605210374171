import * as digitizeActions from "../../../actions/digitize";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import DigitizeLayer from "./digitizeLayer";
import LayerFeatures from "./digitizeLayerFeatures";
import { getDigitizeLayers, getDigitizeEditingStatus } from "../../../selectors/digitizeSelectors";
import { closeWidgets } from "features/mapTools/slice";
import { useMainDraw } from "../../../utils/customHooks/map/useMainDraw";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        backgroundColor: "#fff"
    }
}));

const DigitizeLayers = ({
    selectedLayer,
    setSelectedLayer,
    renderDistanceMarkers,
    resetDrawingState,
    drawingDistanceMarkersArray,
    setIsStylerOpen,
    deselectAllFeaturesAndLabels
}) => {
    const classes = useStyles();

    const draw = useMainDraw();

    const editing = useSelector(getDigitizeEditingStatus);
    const digitizeLayers = useSelector(getDigitizeLayers);

    const dispatch = useDispatch();

    const onCloseWidgets = () => {
        dispatch(closeWidgets());
    };

    const onDraw = (layer) => {
        setSelectedLayer(layer);
        onCloseWidgets();
        dispatch(digitizeActions.editingFeatures(true));
        if (editing) {
            draw.trash();
        }
        switch (layer.type) {
            case "point":
                draw.changeMode("draw_point");
                break;
            case "line":
                draw.changeMode("draw_line_string");
                break;
            case "polygon":
                draw.changeMode("draw_polygon");
                break;
            case "circle":
                draw.changeMode("drag_circle");
                break;
            default:
                return null;
        }
    };

    return (
        <Grid container className={classes.root}>
            {!!Object.keys(selectedLayer).length ? (
                <LayerFeatures
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    onDraw={onDraw}
                    renderDistanceMarkers={renderDistanceMarkers}
                    drawingDistanceMarkersArray={drawingDistanceMarkersArray}
                    setIsStylerOpen={setIsStylerOpen}
                    deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                />
            ) : (
                digitizeLayers.map((layer) => (
                    <DigitizeLayer
                        key={layer.id}
                        resetDrawingState={resetDrawingState}
                        setIsStylerOpen={setIsStylerOpen}
                        layer={layer}
                        selectedLayer={selectedLayer}
                        setSelectedLayer={setSelectedLayer}
                        onDraw={onDraw}
                        deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                    />
                ))
            )}
        </Grid>
    );
};

export default DigitizeLayers;
