import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import baseAtlasApi from "./baseAtlasApi";

type WindowWithReduxDev = typeof window & { __REDUX_DEVTOOLS_EXTENSION__: boolean };

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            immutableCheck: false,
            serializableCheck: false
        }).concat(baseAtlasApi.middleware),
    devTools: process.env.NODE_ENV !== "production" && (window as WindowWithReduxDev).__REDUX_DEVTOOLS_EXTENSION__
});
