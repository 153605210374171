import { FC } from "react";
import OverflowTip from "../../../common/OverflowTip/OverflowTip";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import useDefaultInfoboxStyles from "../../../../features/infobox/components/DefaultInfobox/styles";
import { Collapse, IconButton, Tooltip } from "@mui/material";
import { ExpandLess } from "@mui/icons-material";

type Props = {
    name: string;
    value: number;
    lat: number;
    long: number;
    coordinatesPrecision?: number;
    rasterValuePrecision?: number;
    hasMultipleFeatures: boolean;
    toggleFeature: (index: number) => void;
    featureIndex: number;
    collapsed: boolean;
};

const RasterFeature: FC<Props> = ({
    name,
    value,
    lat,
    long,
    coordinatesPrecision = 6,
    rasterValuePrecision = 2,
    hasMultipleFeatures,
    toggleFeature,
    featureIndex,
    collapsed
}) => {
    const classes: any = useDefaultInfoboxStyles();

    const table = (
        <table className={classes.table}>
            <tbody>
                <tr className={classes.property}>
                    <td className={classes.propertyKey}>
                        <CustomTypography variant="body2" textWeight="bold" className={classes.overflowText}>
                            {"Coord (lat, lng)"}
                        </CustomTypography>
                    </td>
                    <td className={classes.propertyValue}>
                        <CustomTypography variant="body2" className={classes.overflowText}>
                            {lat.toFixed(coordinatesPrecision) + ", " + long.toFixed(coordinatesPrecision)}
                        </CustomTypography>
                    </td>
                </tr>
                <tr className={classes.property}>
                    <td className={classes.propertyKey}>
                        <CustomTypography variant="body2" textWeight="bold" className={classes.overflowText}>
                            {"Value"}
                        </CustomTypography>
                    </td>
                    <td className={classes.propertyValue}>
                        <CustomTypography variant="body2" className={classes.overflowText}>
                            {value?.toFixed(rasterValuePrecision) ?? "No data"}
                        </CustomTypography>
                    </td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <div className={classes.feature}>
            <div className={classes.featureHeaderContainer}>
                <div className={classes.featureHeader}>
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <IconButton
                                onClick={(_) => toggleFeature(featureIndex)}
                                className={classes.innerCollapseBtn}
                                size="small"
                            >
                                <ExpandLess className={collapsed ? classes.expandedLeft : classes.collapsed} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <OverflowTip variant="subtitle1" textWeight="bold">
                        {name}
                    </OverflowTip>
                </div>
            </div>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <div className={classes.borderBox}>{table}</div>
                </Collapse>
            ) : (
                table
            )}
        </div>
    );
};

export default RasterFeature;
