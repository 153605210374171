import { MetadataProperty } from "./../model/metadata/MetadataProperty";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getMetadataSchema } from "../actions/metadataSchema";
import { resetProjectData } from "actions/globalActions";

type SliceState = {
    loading: boolean;
    schema: MetadataProperty[];
};

const initialState: SliceState = {
    loading: false,
    schema: []
};

const metadataSchemaSlice = createSlice({
    name: "metadataSchema",
    initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMetadataSchema.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMetadataSchema.fulfilled, (state, { payload }) => {
                state.schema = payload;
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isAnyOf(getMetadataSchema.rejected, getMetadataSchema.fulfilled), (state) => {
                state.loading = false;
            });
    }
});

export const { reset } = metadataSchemaSlice.actions;
export default metadataSchemaSlice.reducer;
