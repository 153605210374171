import { MenuItem } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:active": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white
            }
        },
        padding: "4px 6px"
    }
}))(MenuItem);

export default StyledMenuItem;
