import { createSlice, isAnyOf, isFulfilled, isRejected } from "@reduxjs/toolkit";
import { getCurrentUser } from "./actions";
import { User } from "./model/User";

type AuthState = {
    fetching: boolean;
    user?: User;
};

const initialState: AuthState = {
    fetching: false,
    user: undefined
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurrentUser.pending, (state) => {
            state.fetching = true;
        });

        builder.addCase(getCurrentUser.fulfilled, (state, { payload }) => {
            state.user = payload;
        });

        builder.addMatcher(isAnyOf(isFulfilled(getCurrentUser), isRejected(getCurrentUser)), (state) => {
            state.fetching = false;
        });
    }
});

export default authSlice.reducer;
