// TODO: remove this once the export from the package is typed

import { Dayjs } from "dayjs";

export enum Operators {
    Equal = "==",
    NotEqual = "!=",
    LessThan = "<",
    GreaterThan = ">",
    LessThanOrEqualTo = "<=",
    GreaterThanOrEqualTo = ">=",
    Between = "between",
    Undefined = "",
    Contains = "contains"
};

export type StringOperators = Operators.Equal | Operators.NotEqual | Operators.Undefined | Operators.Contains;
export type NumericOperators =
    | Operators.Equal
    | Operators.NotEqual
    | Operators.LessThan
    | Operators.LessThanOrEqualTo
    | Operators.GreaterThanOrEqualTo
    | Operators.GreaterThan
    | Operators.Between
    | Operators.Undefined;
export type DateOperators =
    | Operators.Equal
    | Operators.NotEqual
    | Operators.LessThan
    | Operators.LessThanOrEqualTo
    | Operators.GreaterThanOrEqualTo
    | Operators.GreaterThan
    | Operators.Between
    | Operators.Undefined;
export type UndefinedOperators = Operators.Undefined;

export enum Types {
    Integer = "integer",
    DoublePrecision = "double precision",
    String = "string",
    CharacterVarying = "character varying",
    Date = "date",
    Undefined = ""
};

export type LiteralType = Types.CharacterVarying | Types.String;
export type NumericType = Types.Integer | Types.DoublePrecision;
export type DateType = Types.Date;
export type UndefinedType = Types.Undefined;

export const operatorsByType: Record<Types, Operators[]> = {
    [Types.Integer]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
        Operators.Undefined
    ],
    [Types.DoublePrecision]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
        Operators.Undefined
    ],
    [Types.String]: [Operators.Equal, Operators.NotEqual, Operators.Contains, Operators.Undefined],
    [Types.CharacterVarying]: [Operators.Equal, Operators.NotEqual, Operators.Contains, Operators.Undefined],
    [Types.Date]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
        Operators.Undefined
    ],
    [Types.Undefined]: [Operators.Undefined]
};

export type LayerFilter = {
    fieldName: string;
    applied: boolean;
} & (
        | {
            type: LiteralType;
            operatorName: StringOperators;
            value: string;
        }
        | {
            type: NumericType;
            operatorName: NumericOperators;
            value: number;
            secondValue?: number;
        }
        | {
            type: DateType;
            operatorName: DateOperators;
            value: Dayjs;
            secondValue?: Dayjs;
        }
        | {
            type: UndefinedType;
            operatorName: UndefinedOperators;
            value: string;
        }
    );