import { makeStyles } from "@mui/styles";

export const useZanderModalStyles = makeStyles((theme: any) => ({
    zanderDialog: {
        "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: 660,
            maxHeight: "90%"
        }
    },
    zanderDialogActions: {
        padding: 20,
        display: "flex",
        alignItems: "left"
    },
    recaptchaDisclaimer: {
        maxWidth: 300,
        marginLeft: "auto"
    },
    spacing: {
        marginRight: 8
    },
    tabLabel: {
        display: "flex",
        fontSize: 600
    },
    closeTabIcon: {
        display: "none",
        position: "absolute",
        right: 8,
        marginTop: -8,
        cursor: "pointer",
        color: theme.customColors.mutedColor,
        "&:hover": {
            color: theme.customColors.appTextColor
        }
    },
    closeIconVisible: {
        display: "initial"
    },
    activeTab: {
        cursor: "unset"
    },
    tabsWrapper: {
        "& .MuiTabs-root": {
            borderBottom: `1px solid ${theme.customColors.borderColor}`
        }
    },
    honeypot: {
        position: "absolute",
        left: "-9999px"
    }
}));
