import { useStyles } from "./style";
import clsx from "clsx";

type Props = {
    fillColor?: string;
    lineColor?: string;
    circleColor?: string;
};

const PolygonIcon = ({ fillColor, lineColor, circleColor }: Props) => {
    const { legend, fill, polygonBorder, line, circle, rt, rb, lb, lt } = useStyles({
        fillColor,
        lineColor,
        circleColor
    });

    return (
        <div
            className={clsx(
                legend,
                fillColor && fill,
                lineColor && line,
                !lineColor && fillColor && polygonBorder
            )}
        >
            {circleColor && (
                <>
                    <div className={clsx(circle, rt)} />
                    <div className={clsx(circle, rb)} />
                    <div className={clsx(circle, lb)} />
                    <div className={clsx(circle, lt)} />
                </>
            )}
        </div>
    );
};

export default PolygonIcon;
