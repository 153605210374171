import makeStyles from "@mui/styles/makeStyles";
import isMobile from "../../../../utils/isMobile";

export const usePrintPreviewStyles = makeStyles((theme) => ({
    root: {
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "100%",
        gridTemplateAreas: '"settings printPreview"',
        overflowY: "auto",
        width: isMobile.phone ? "81vw" : "auto",
        backgroundColor: "#f5f7f8",
        border: "2px solid #000",
        borderRadius: 8,
        boxShadow: theme.shadows[5]
    },
    settingsContainer: {
        gridArea: "settings",
        height: "100%",
        maxHeight: "90vh",
        overflowY: "overlay",
        width: 350
    },
    printPreviewContainer: {
        display: "grid",
        gridTemplateColumns: "minmax(0%, 100%) min-content",
        gridTemplateRows: "minmax(75%, 100%) min-content",
        gridTemplateAreas: '"mapPreview mapLegendLandscape" "mapLegendPortrait mapLegendPortrait"',
        gridArea: "printPreview",
        height: "90vh",
        minWidth: "calc(90vh / 1.4142)",
        overflow: "auto"
    },
    mapPreviewPortraitContainer: {
        height: "100%",
        width: "calc(90vh / 1.4142)"
    },
    mapPreviewLandscapeContainer: {
        height: "100%",
        width: "calc(90vh * 1.4142)"
    },
    mapLegendLandscape: {
        gridArea: "mapLegendLandscape"
    },
    mapLegendPortrait: {
        gridArea: "mapLegendPortrait"
    },
    header: {
        position: "sticky",
        backgroundColor: "#f5f7f8",
        boxShadow: theme.shadows[1],
        top: 0,
        zIndex: 5
    },
    section: {
        padding: 16
    },
    closeButton: {
        position: "absolute",
        top: 3,
        right: 3,
        color: theme.palette.primary.main,
        zIndex: 1500
    },
    closeButtonLandscape: {
        position: "absolute",
        top: 7,
        right: 7,
        zIndex: 3000,
        fontSize: "0.54rem"
    }
}));
