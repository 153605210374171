import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    clients: {
        maxHeight: 290,
        overflowY: "auto"
    },
    client: {
        display: "flex",
        justifyContent: "space-between"
    },

    clientLogo: {
        height: 25,
        marginLeft: 8
    },
    activeSession: {
        fontSize: 12,
        color: theme.customColors.mutedColor
    },
    clientName: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 200
    },
    selected: {
        backgroundColor: theme.customColors.lightGray,
        padding: "8px 16px",
        cursor: "default"
    }
}));
