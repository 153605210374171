import { MapProvider } from "@emblautec/react-map-gl";
import { FCWC } from "react";
import { MainDrawProvider } from "../../../components/map/contexts/MainDrawContext/MainDrawContext";
import { MapHandlersProvider } from "../../../components/map/contexts/MapHandlersContext/MapHandlersContext";

const MapProviders: FCWC = ({ children }) => {
    return (
        <MapProvider>
            <MapHandlersProvider>
                <MainDrawProvider>{children}</MainDrawProvider>
            </MapHandlersProvider>
        </MapProvider>
    );
};

export default MapProviders;
