import { useEffect } from "react";
import useSignalR from "utils/customHooks/useSignalR";
import { ZanderResponse } from "../models/ZanderReponse";
import { ZANDER_EVENT, ZANDER_HUB } from "../constants";

const useZander = (handler: (response: ZanderResponse) => void) => {
    const { addEvent, removeEvent, connectionId } = useSignalR(ZANDER_HUB);

    useEffect(() => {
        addEvent(ZANDER_EVENT, handler);

        return () => removeEvent(ZANDER_EVENT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return connectionId;
};

export default useZander;
