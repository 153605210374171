import CloseIcon from "@mui/icons-material/Clear";
import OpenIcon from "@mui/icons-material/Layers";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LEGEND_PATH_SUFFIX } from "../../../../constants/routes";
import { getLegendHasEntries } from "../../../../selectors/generalSelectors";
import { getShowMapLegend } from "../../../../selectors/printSelectors";
import widgetTypes from "../../../../utils/tools/widgets";
import LegendEntries from "../../../legend/LegendEntries/LegendEntries";
import { useLegendEntryStyles } from "./legendEntryStyles";
import { useStyles } from "./styles";
import { getToggledWidgetsSet } from "features/mapTools/selectors";

const Legend = () => {
    const classes = useStyles();
    const legendEntryClasses = useLegendEntryStyles();

    const [open, setOpen] = useState(false);

    const showMapLegend = useSelector(getShowMapLegend);
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT);
    const hasLegendEntries = useSelector(getLegendHasEntries);

    const history = useHistory();

    useEffect(() => {
        history.listen((location) => {
            let pathLocation = location.pathname.split("/");
            pathLocation = pathLocation[pathLocation.length - 1];
            if (pathLocation === LEGEND_PATH_SUFFIX) {
                setOpen(false);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOpen(showMapLegend);
    }, [showMapLegend]);

    const spacingOptions = {
        singleEntryBreakpointSpacing: 12,
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 4,
        multiLabelItemVerticalBreakpointSpacing: 12
    };

    const onOpenToggle = () => {
        setOpen(!open);
    };

    if (!hasLegendEntries) {
        return null;
    }

    return (
        <>
            {!modalOpen && (
                <div className={open ? classes.root : classes.rootClosed}>
                    <div
                        size="small"
                        onClick={onOpenToggle}
                        className={open ? classes.closeButton : classes.openButton}
                    >
                        {open && <Typography className={classes.headerText}>Legend</Typography>}
                        {open ? <CloseIcon style={{ width: 20 }} /> : <OpenIcon />}
                    </div>
                    <Box style={{ display: open ? "flex" : "none" }}>
                        <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
                    </Box>
                </div>
            )}
        </>
    );
};

export default Legend;
