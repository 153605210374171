import React from "react";
import withStyles from "@mui/styles/withStyles";
import { Tab, Tabs } from "@mui/material";

const StyledTabs = withStyles((theme) => ({
    root: {
        height: 54,
        minHeight: 54,
        padding: 0,
        width: "100%",
        zIndex: 1,
        backgroundColor: theme.customColors.appBgColorLight,
        borderBottom: `1px solid ${theme.customColors.borderColor}`
    },
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent"
    }
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        height: 54,
        minWidth: "fit-content",
        paddingInline: theme.customSpacing.generalSidePadding,
        fontSize: 16,
        fontWeight: 600 + " ! important",
        borderBottom: "2px solid transparent",
        flexDirection: "row",

        "&:focus": {
            opacity: 1
        },

        "& *": {
            marginRight: 8,
            marginBottom: "0 !important"
        }
    },
    selected: {
        borderBottomColor: theme.customColors.primaryColor,
        fontWeight: "bold",
        color: theme.customSpacing.appTextColor + "!important"
    },
    labelIcon: {
        minHeight: "initial"
    },
    wrapper: {
        flexDirection: "row",
        "& *": {
            marginRight: 8,
            marginBottom: "0 !important"
        }
    }
}))((props) => <Tab {...props} />);

export { StyledTabs, StyledTab };
