import React from "react";
import {
    TextField,
    Typography,
    Checkbox,
    Grid,
    IconButton,
    Tooltip,
    FormControlLabel,
    Radio,
    FormLabel,
    RadioGroup,
    FormControl,
    InputAdornment
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector, useDispatch } from "react-redux";
import * as printActions from "../../../actions/print";
import { FilterNone, LibraryAddCheck } from "@mui/icons-material";
import { getPrintFeatures } from "../../../selectors/printSelectors";
import { TITLE_CHAR_LIMIT, NOTES_CHAR_LIMIT } from "../../../constants/print/characterLimits";
import CheckedIcon from "@mui/icons-material/CheckCircleOutline";
import { useParams } from "react-router-dom";
import { getProjectForClient } from "features/core/selectors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import config from "config";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff"
    },
    header: {
        padding: 10,
        borderBottom: "2px solid #f5f7f8"
    },
    section: {
        padding: 16,
        borderBottom: "2px solid #ececec"
    },
    formatSelector: {
        width: "80%",
        padding: 8
    },
    radioGroup: {
        paddingLeft: 42,
        justifyContent: "space-between"
    },
    updateLogoLink: {
        display: "flex",
        marginLeft: 4
    }
}));

const PrintFeatures = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const printFeatures = useSelector(getPrintFeatures);

    const params = useParams();
    const currentProject = useSelector(getProjectForClient(params));

    const selectedAll =
        printFeatures.showMapCopyright &&
        printFeatures.showMapDate &&
        printFeatures.showMapLogo &&
        printFeatures.showMapScalebar &&
        printFeatures.showMapTitle &&
        printFeatures.showMapNorthArrow &&
        printFeatures.showMapLegend;

    const onSelectAll = () => {
        if (selectedAll) {
            onShowLegendToggle();
            onShowScalebarToggle();
            onShowCopyrightToggle();
            onShowDateToggle();
            onShowNorthArrowToggle();
            onShowLogoToggle();
            onShowTitleToggle();
            onShowNotesToggle();
        } else {
            !printFeatures.showMapLegend && onShowLegendToggle();
            !printFeatures.showMapScalebar && onShowScalebarToggle();
            !printFeatures.showMapCopyright && onShowCopyrightToggle();
            !printFeatures.showMapDate && onShowDateToggle();
            !printFeatures.showMapNorthArrow && onShowNorthArrowToggle();
            !printFeatures.showMapLogo && onShowLogoToggle();
            !printFeatures.showMapTitle && onShowTitleToggle();
            !printFeatures.showMapTitle && onShowNotesToggle();
        }
    };

    const onShowLegendToggle = () => {
        dispatch(printActions.toggleMapLegend());
    };

    const onShowScalebarToggle = () => {
        dispatch(printActions.toggleMapScalebar());
    };

    const onShowCopyrightToggle = () => {
        dispatch(printActions.toggleMapCopyright());
    };

    const onShowDateToggle = () => {
        dispatch(printActions.toggleMapDate());
    };

    const onDateFormatToggle = (e) => {
        dispatch(printActions.toggleMapDateFormat(e.target.value));
    };

    const onShowNorthArrowToggle = () => {
        dispatch(printActions.toggleMapNorthArrow());
    };

    const onShowLogoToggle = () => {
        if (!currentProject?.logoUrl) return;

        dispatch(printActions.toggleMapLogo());
    };

    const onShowTitleToggle = () => {
        dispatch(printActions.toggleMapTitle());
    };

    const onShowNotesToggle = () => {
        dispatch(printActions.toggleMapNotes());
    };

    const getUpdateLogoUrl = () => {
        return `${config.coreAppUrl}clients/${params.clientId}/details/projects/list/(project:${params.projectId})`;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems="center" className={classes.header}>
                    <Grid item xs={10}>
                        <Grid container justifyContent="flex-start">
                            <Typography variant="subtitle1">Additional Info</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title="Select All" placement="bottom">
                            <IconButton aria-label="select-all" onClick={() => onSelectAll()} size="large">
                                {selectedAll ? <LibraryAddCheck /> : <FilterNone />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.section}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapLegend} onChange={onShowLegendToggle} />
                            <Typography variant="body1">Legend</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapCopyright} onChange={onShowCopyrightToggle} />
                            <Typography variant="body1">Copyright</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapScalebar} onChange={onShowScalebarToggle} />
                            <Typography variant="body1">Scalebar</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapDate} onChange={onShowDateToggle} />
                            <Typography variant="body1">Date</Typography>
                        </Grid>
                    </Grid>

                    {printFeatures.showMapDate && (
                        <Grid item xs={12}>
                            <Grid container alignItems="center" className={classes.radioGroup}>
                                <FormControl component="fieldset">
                                    <FormLabel component="label">Date format</FormLabel>
                                    <RadioGroup
                                        aria-label="map-date-type"
                                        name="map-date"
                                        value={printFeatures.mapDateFormat}
                                        onChange={onDateFormatToggle}
                                    >
                                        <Grid container>
                                            <FormControlLabel
                                                value="local"
                                                control={<Radio checkedIcon={<CheckedIcon />} />}
                                                label="Local time"
                                            />
                                            <FormControlLabel
                                                value="utc"
                                                control={<Radio checkedIcon={<CheckedIcon />} />}
                                                label="UTC time"
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox
                                checked={printFeatures.showMapLogo}
                                onChange={onShowLogoToggle}
                                disabled={!currentProject?.logoUrl}
                            />
                            <Typography variant="body1">Logo</Typography>
                            {!currentProject?.logoUrl && (
                                <Tooltip title="No logo set for this project. Click here to set one">
                                    <a href={getUpdateLogoUrl()} target="_blank" className={classes.updateLogoLink}>
                                        <OpenInNewIcon fontSize="inherit" color="primary" />
                                    </a>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapNorthArrow} onChange={onShowNorthArrowToggle} />
                            <Typography variant="body1">North Arrow</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapTitle} onChange={onShowTitleToggle} />
                            <Typography variant="body1">Map Title</Typography>
                        </Grid>
                    </Grid>
                    {printFeatures.showMapTitle && (
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                placeholder="Map title"
                                value={printFeatures?.mapTitle}
                                onChange={(e) => dispatch(printActions.onMapTitleChange(e.target.value))}
                                inputProps={{ maxLength: TITLE_CHAR_LIMIT }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {printFeatures.mapTitle.length} / {TITLE_CHAR_LIMIT}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            <Checkbox checked={printFeatures.showMapNotes} onChange={onShowNotesToggle} />
                            <Typography variant="body1">Notes</Typography>
                        </Grid>
                    </Grid>
                    {printFeatures.showMapNotes && (
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                placeholder="Map notes"
                                value={printFeatures?.mapNotes}
                                onChange={(e) => dispatch(printActions.onMapNotesChange(e.target.value))}
                                inputProps={{ maxLength: NOTES_CHAR_LIMIT }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {printFeatures.mapNotes.length} / {NOTES_CHAR_LIMIT}
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PrintFeatures;
