import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type StylesParams = { printFeatures: { showMapTitle: boolean; showMapNotes: boolean } };

export const useStyles = makeStyles<Theme, StylesParams>((theme: any) => ({
    root: {
        padding: 10,
        backgroundColor: "#fff",
        height: "100%"
    },
    innerRoot: {
        border: "1px solid grey",
        maxHeight: "87vh",
        overflow: "hidden"
    },
    titlecontainer: {
        borderBottom: "1px solid " + theme.customColors.borderColor
    },
    legendTitle: {
        fontSize: 15,
        width: "100%",
        textAlign: "center"
    },
    sectionStyle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 20,
        maxHeight: "12vh",
        padding: "0 18px",
        minHeight: 22
    },
    labelsContainer: {
        overflow: "hidden"
    }
}));
