import makeStyles from "@mui/styles/makeStyles";

export const useLayerSelectorStylerStyle = makeStyles((theme) => ({
    stylerDivWrapper: { flexGrow: 1, overflow: "auto", display: "flex" },
    backIcon: {
        color: theme.customColors.black
    },
    header: {
        width: "100%",
        backgroundColor: theme.customColors.white,
        borderTop: "1px solid " + theme.customColors.borderColor
    },
    stylerTabs: {
        borderTop: "1px solid " + theme.customColors.borderColor
    }
}));
