import ExitToApp from "@mui/icons-material/ExitToApp";
import { MenuItem } from "@mui/material";
import useAuthentication from "app/hooks/useAuthentication";
import { FC } from "react";
import { useStyles } from "./styles";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import { MENU_ITEM_HEIGHT } from "../UserMenu";

type Props = {
    onSignOut: () => void;
};

const SignOut: FC<Props> = ({ onSignOut }) => {
    const classes = useStyles();

    const { logoutRedirect } = useAuthentication();

    const signOut = () => {
        logoutRedirect();
        onSignOut();
    };

    return (
        <MenuItem onClick={signOut} sx={{ height: MENU_ITEM_HEIGHT }}>
            <div className={classes.signOut}>
                <ExitToApp />
                <CustomTypography variant="body2" className={classes.signOutText} color="inherit">
                    Sign out
                </CustomTypography>
            </div>
        </MenuItem>
    );
};

export default SignOut;
