import axiosClient from "actions/apiClient";
import { coreApiClient } from "actions/coreApiClient";
import useAuthentication from "app/hooks/useAuthentication";
import { AxiosRequestConfig } from "axios";
import { useEffect, useRef, useState } from "react";

export const useTokenRequestInterceptor = () => {
    const [loading, setLoading] = useState(true);
    const atlasInterceptorId = useRef(0);
    const coreInterceptorId = useRef(0);

    const { getAccessToken } = useAuthentication();

    const authInterceptor = async (cfg: AxiosRequestConfig) => {
        const accessToken = await getAccessToken();
        cfg.headers.Authorization = `Bearer ${accessToken}`;

        return cfg;
    };

    // register interceptors, this needs to be done only once
    useEffect(() => {
        atlasInterceptorId.current = axiosClient.interceptors.request.use(authInterceptor);
        coreInterceptorId.current = coreApiClient.interceptors.request.use(authInterceptor);
        setLoading(false);

        return () => {
            axiosClient.interceptors.request.eject(atlasInterceptorId.current);
            coreApiClient.interceptors.request.eject(coreInterceptorId.current);
            setLoading(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading;
};
