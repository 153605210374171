import { FC, useMemo, useState } from "react";
import { getAppsSelector } from "selectors/appsSelectors";
import { useAppSelector } from "store/hooks/useAppSelector";
import { useStyles } from "./styles";
import { useHistory, useParams } from "react-router-dom";
import { Divider, FormControl, MenuItem, MenuList, Select, Skeleton } from "@mui/material";
import OverflowTip from "components/common/OverflowTip/OverflowTip";
import ButtonLink from "components/common/ButtonLink/ButtonLink";
import CustomChip from "components/CustomChip/CustomChip";
import PublicIcon from "@mui/icons-material/Public";
import { CoreClient } from "features/core/models/CoreClient";
import { getClientById } from "features/core/selectors";
import { App } from "model/app/App";
import CustomTypography from "components/common/CustomTypography/CustomTypography";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { clientIdParam } from "constants/map/queryParams";
import config from "config";

type Props = {
    appName?: string;
};

type Params = {
    clientId: string;
    projectId: string;
};

const AppsPicker: FC<Props> = ({ appName }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const apps: App[] = useAppSelector(getAppsSelector);

    const { clientId, projectId } = useParams<Params>();
    const currentClient: CoreClient | undefined = useAppSelector(getClientById(clientId));

    const currentProject = currentClient?.projects?.find((x) => x.id === projectId);

    const history = useHistory();

    const getPathname = (app: any) => {
        const publicRoute = app.public ? "public/" : "";
        const pathname = `/${clientId}/${projectId}/${publicRoute}${app.id}/map/layer-selector`;
        if (history.location.pathname !== pathname) return pathname;
        return "#";
    };

    const getLandingPathname = () => {
        const queryParams = new URLSearchParams();
        queryParams.append(clientIdParam, clientId);
        return `${config.landingUrl}?${queryParams.toString()}`;
    };

    const renderValue = () => {
        return (
            <div className={classes.appAndProject}>
                <OverflowTip variant="body2" className={classes.appAndProjectNames}>
                    {currentProject?.abbreviation ?? ""} / {appName}
                </OverflowTip>
                {!!currentProject?.logoUrl && <img src={currentProject.logoUrl} height={25} alt="" />}
            </div>
        );
    };

    const renderMenuItem = (app: App) => {
        return (
            <MenuItem key={app.id} value={app.name} selected={app.name === appName} onClick={() => setOpen(false)}>
                <ButtonLink className={classes.link} to={getPathname(app)}>
                    <div className={classes.overflowText}>
                        <OverflowTip variant="body2" className={classes.appName}>
                            {app.name}
                        </OverflowTip>
                    </div>
                    {app.public && (
                        <CustomChip label="Public" Icon={PublicIcon} className={classes.blueChip} expanded={false} />
                    )}
                </ButtonLink>
            </MenuItem>
        );
    };

    const sortByModified = (app1: App, app2: App) => {
        return new Date(app2.modifiedUtc).getTime() - new Date(app1.modifiedUtc).getTime();
    };

    const filteredAndSortedApps = useMemo(() => apps.sort(sortByModified), [apps]);

    return appName && !!filteredAndSortedApps.length ? (
        <FormControl className={classes.formControl}>
            <Select
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                id="apps-menu-select"
                renderValue={renderValue}
                className={classes.appsMenu}
                variant="standard"
                disableUnderline
                value=""
                displayEmpty
            >
                <div className={classes.project}>
                    <div>
                        <OverflowTip textWeight="bold" variant="caption" fontSize="inherit">
                            {currentProject?.name}
                        </OverflowTip>
                        <CustomTypography variant="caption" color="muted" fontSize="inherit">
                            ({currentProject?.abbreviation})
                        </CustomTypography>
                    </div>
                    <ButtonLink to={getLandingPathname()} className={classes.switchProject}>
                        <SwapHorizIcon fontSize="small" />
                        <CustomTypography variant="caption" textWeight="semibold" color="inherit" fontSize="inherit">
                            Switch Project
                        </CustomTypography>
                    </ButtonLink>
                </div>
                <Divider />

                <MenuList className={classes.apps}>{filteredAndSortedApps.map((app) => renderMenuItem(app))}</MenuList>

                <Divider />
            </Select>
        </FormControl>
    ) : (
        <Skeleton variant="rectangular" className={classes.formControl} height={35} />
    );
};

export default AppsPicker;
