import { useState } from "react";
import { Menu, MenuItem, Grid, Slider, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { useDispatch, useSelector } from "react-redux";
import { getLayerVisibility } from "../../../selectors/layerSelector";
import useContextMenuStyles from "./styles/contextMenuStyles";
import { useMap } from "@emblautec/react-map-gl";
import GeotiffDownloader from "./GeotiffDownloader/GeotiffDownloader";
import { getSelectedAppIsPublic } from "selectors/appsSelectors";
import { getFeatureFlags } from "selectors/featureFlagsSelectors";
import { SourceType } from "model/enums/SourceType";
import { toggleWidgetWithArgs } from "features/mapTools/slice";
import { Widgets } from "model/enums/Widgets";
import customToastr from "utils/customToastr";
import Download from "downloadjs";
import { getSource } from "selectors/mapSelectors";

const CustomSlider = withStyles({
    valueLabel: {
        fontSize: 11
    }
})(Slider);

const Context = ({
    layer,
    onToggle,
    anchorEl,
    closeCMenu,
    toggleCMenu,
    open,
    opacity,
    handleOpacity,
    handleCommitOpacity,
    layerHandlers
}) => {
    const classes = useContextMenuStyles();

    const [downloadGeotiffOpen, setDownloadGeotiffOpen] = useState(false);

    const { handleOpenDownloadModal, handleOpenLayerMetadata, handleOpenAttributeTable, onStyleLayer, onFilterLayer, onClearFilters } =
        layerHandlers;
    const visible = useSelector(getLayerVisibility(layer.resourceId));
    const isPublic = useSelector(getSelectedAppIsPublic);
    const featureFlags = useSelector(getFeatureFlags);

    const source = useSelector(getSource(layer.sourceId));

    const { mainMap } = useMap();

    const dispatch = useDispatch();

    const zoomLayer = (layer, group) => {
        if (!layer.bounds?.coordinates?.length) {
            customToastr.warning("Layer has no bounds");
            return;
        }

        if (!visible) {
            onToggle(layer);
        }
        let bounds = [layer.bounds.coordinates[0][0], layer.bounds.coordinates[0][2]];
        mainMap.fitBounds(bounds, { padding: 100 });
    };

    const onOpenGeotiffDownloader = (e) => {
        setDownloadGeotiffOpen(true);
        toggleCMenu();
    };

    const onCloseGeotiffDownloader = (e) => {
        setDownloadGeotiffOpen(false);
    };

    const onOpenBufferWidget = (e) => {
        dispatch(toggleWidgetWithArgs({ widgetName: Widgets.Buffer, args: layer }));
    };

    const onDownload = () => {
        layer.isBuffer ? downloadBuffer() : handleOpenDownloadModal(layer);
        toggleCMenu();
    };

    const downloadBuffer = () => {
        if (!source || !source.data) {
            customToastr.error("Failed to download the layer");
            return;
        }

        const geojson = JSON.stringify(source.data);
        Download(geojson, "buffer.geojson");
    };

    return (
        <>
            <Menu
                className={classes.customMenuStyle}
                elevation={0}
                anchorEl={anchorEl}
                onClose={() => {
                    closeCMenu();
                }}
                open={open}
            >
                <MenuItem>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Typography variant="caption">0</Typography>
                        </Grid>
                        <Grid item xs>
                            <Grid container alignItems="center">
                                <CustomSlider
                                    step={10}
                                    marks
                                    min={0}
                                    max={100}
                                    value={opacity}
                                    defaultValue={opacity}
                                    onChange={handleOpacity}
                                    onChangeCommitted={() => handleCommitOpacity(layer)}
                                    valueLabelDisplay="on"
                                    aria-label="Always visible"
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">100</Typography>
                        </Grid>
                    </Grid>
                </MenuItem>

                {zoomLayer && (
                    <MenuItem
                        onClick={() => {
                            zoomLayer(layer);
                            toggleCMenu();
                        }}
                    >
                        Zoom To Layer
                    </MenuItem>
                )}
                {!isPublic && (
                    <MenuItem
                        disabled={layer.options.loading}
                        onClick={() => {
                            onStyleLayer(layer);
                            toggleCMenu();
                        }}
                    >
                        Change Layer Style
                    </MenuItem>
                )}
                {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            onFilterLayer(layer);
                            toggleCMenu();
                        }}
                    >
                        Filter Layer
                    </MenuItem>
                )}
                {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            onClearFilters(layer);
                            toggleCMenu();
                        }}
                    >
                        Clear filters
                    </MenuItem>
                )}

                {layer.type !== SourceType.Raster && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            handleOpenLayerMetadata(layer);
                            toggleCMenu();
                        }}
                    >
                        View Layer Metadata
                    </MenuItem>
                )}

                {layer.type !== SourceType.Raster && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            handleOpenAttributeTable(layer);
                            toggleCMenu();
                        }}
                    >
                        View Layer Attribute Table
                    </MenuItem>
                )}
                {layer.type !== SourceType.Raster && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            onOpenBufferWidget();
                            toggleCMenu();
                        }}
                    >
                        Generate Buffer
                    </MenuItem>
                )}
                {layer.options.downloadable && !isPublic && <MenuItem onClick={onDownload}>Download Layer</MenuItem>}
                {layer.options.downloadableGeoTiff && !isPublic && (
                    <MenuItem onClick={onOpenGeotiffDownloader}>Download Geotiff</MenuItem>
                )}
            </Menu>

            <GeotiffDownloader open={downloadGeotiffOpen} layer={layer} handleClose={onCloseGeotiffDownloader} />
        </>
    );
};

export default Context;
