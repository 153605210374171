import { DefaultTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

const legendSize = 26;

const lineHeight = 6;

const pointSize = 6;

const pointDistanceToMiddle = (legendSize - pointSize) / 2;

const lineDistanceToMiddle = (legendSize - lineHeight) / 2;

const pointMarginOffset = 2.5; // In order to avoid weird overlaps

export const useStyles = makeStyles<DefaultTheme, { lineColor?: string; lineDashArray?: Array<number>; circleColor?: string }>((theme) => ({
    legend: {
        width: legendSize,
        height: legendSize,
        position: "relative"
    },
    line: (props) => ({
        position: "absolute",
        height: lineHeight,
        top: lineDistanceToMiddle,
        left: !!props.circleColor ? pointMarginOffset : 0,
        right: !!props.circleColor ? pointMarginOffset : 0
    }),
    circle: (props) => ({
        height: pointSize,
        width: pointSize,
        position: "absolute",
        backgroundColor: props.circleColor,
        borderRadius: 100
    }),
    left: {

        bottom: 9,
        left: 0
    },
    right: {
        bottom: 9,
        right: 0
    }
}));