import dayjs from "dayjs";
export const customDayjsLocale = {
    name: "enCustom", // name String
    weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"], // weekdays Array
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ], // months Array
    ordinal: (n) => `${n}º`, // ordinal Function (number) => return number + output
    formats: {
        // abbreviated format options allowing localization
        LTS: "h:mm:ss A",
        LT: "h:mm A",
        L: "YYYY/MM/DD",
        LL: "YYYY, MMMM D",
        LLL: "YYYY, MMMM D  h:mm A",
        LLLL: "dddd, YYYY, MMMM D  h:mm A",
        // lowercase/short, optional formats for localization
        l: "YYYY/MM/DD",
        ll: "YYYY, MMM D ",
        lll: "YYYY, MMM D  h:mm A",
        llll: "ddd, YYYY, MMM D  h:mm A"
    },
    relativeTime: {
        // relative time format strings, keep %s %d as the same
        future: "in %s", // e.g. in 2 hours, %s been replaced with 2hours
        past: "%s ago",
        s: "a few seconds",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
    },
    meridiem: (hour, minute, isLowercase) => {
        // OPTIONAL, AM/PM
        return hour > 12 ? "PM" : "AM";
    }
};
dayjs.locale("enCustom", customDayjsLocale);
