export function registerTileCache() {
    if (!("serviceWorker" in navigator)) return;

    window.addEventListener("load", function () {
        navigator.serviceWorker
            .register("/tile-cache-sw.js")
            .then((registration) => {
                if (process.env.NODE_ENV !== "production") console.log("Tile cache ServiceWorker registration successful with scope: ", registration.scope);
            })
            .catch((err) => {
                if (process.env.NODE_ENV !== "production") console.log("Tile cache ServiceWorker registration failed: ", err);
            });
    });
}
