import * as types from "./actionTypes";

export const addDrawnFeatures = (features) => ({ type: types.DRAW_FEATURES_CREATED, result: features });

export const editFeature = (feature) => ({ type: types.DRAW_FEATURE_EDITED, result: feature });

export const removeFeature = (feature) => ({ type: types.DRAW_FEATURE_DELETED, result: feature });

export const renameFeature = (feature, newName) => {
    let newFeat = { ...feature };
    newFeat.properties.name = newName;
    return { type: types.DRAW_FEATURES_RENAMED, result: newFeat };
};

export const selectFeature = (feature) => ({ type: types.SELECT_DIGITIZE_FEATURE, result: feature });

export const addLayer = (layer) => ({ type: types.ADD_DIGITIZE_LAYER, result: layer });

export const removeLayer = (layer) => ({ type: types.DELETE_DIGITIZE_LAYER, result: layer });

export const editLayer = (newLayer) => ({ type: types.EDIT_DIGITIZE_LAYER, result: newLayer });

export const editingFeatures = (editing) => ({ type: types.DIGITIZE_EDITING, result: editing });

export const displayInfoHelp = (info) => ({ type: types.DIGITIZE_HELP, result: info });

export const addDigitizeStyle = (style) => ({ type: types.ADD_DIGITIZE_STYLE, result: style });

export const updateDigitizeStyle = (style) => ({ type: types.UPDATE_DIGITIZE_STYLE, result: style });

export const removeDigitizeStyle = (style) => ({ type: types.REMOVE_DIGITIZE_STYLE, result: style });

export const removeAllDigitizeStylesForLayer = (datasetId) => ({
    type: types.REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER,
    result: datasetId
});

export const renameDigitizeStylesNameForLayer = (updatedLayer) => ({
    type: types.RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER,
    result: updatedLayer
});

export const reinitializeDigitizeStylesLayerId = (layer) => ({
    type: types.REINITIALIZE_DIGITIZE_STYLES_LAYER_ID,
    result: layer
});

export const toggleDigitizeLayer = (digitizeLayerId, selected) => ({
    type: types.TOGGLE_DIGITIZE_LAYER,
    result: { digitizeLayerId, selected }
});

export const toggleAllDigitizeLayers = (activationState) => ({
    type: types.TOGGLE_ALL_DIGITIZE_LAYERS,
    result: { activationState }
});
