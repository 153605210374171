import appStyles from "../../../../utils/styles";

export const toolsMenuJss = () => ({
    closeButton: {
        color: "white",
        height: 49, // not 48 because the header also has a divider of 1px
        padding: 0,
        width: "100%",
        minWidth: "auto",
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        borderRadius: 0,
        "&:hover": {
            backgroundColor: appStyles.color.TOOL_HOVER,
            borderRadius: 0
        }
    },
    closedMenu: {
        fontSize: "1.5em"
    },
    headerItem: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },
    measureIcon: {
        fill: 'currentColor',
        paddingLeft: 3
    },
    root: {
        flex: "1 1 100%",
        backgroundColor: "#024F79"
    },
    content: {
        backgroundColor: appStyles.color.PRIMARY_BG,
        height: "100%"
    },
    toolIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 60
    }
});
