import { makeStyles } from "@mui/styles";

export const useLimitsCreatorStyles = makeStyles((theme: any) => ({
    icon: {
        marginRight: 8
    },
    chip: {
        marginRight: 8,
        height: 28,
        width: 58,
        fontSize: 12,
        fontWeight: "bold",
        background: "white",
        justifyContent: "space-between",
        borderColor: theme.customColors.primaryColor
    },
    chipError: {
        color: theme.customColors.errorColor,
        borderColor: theme.errorColor
    },
    percentilesTextField: {
        width: 25,
        paddingTop: 6
    },
    percentilesInput: {
        fontSize: 12,
        fontWeight: "bold",
        borderColor: theme.customColors.primaryColor
    },
    rightAlign: {
        display: "flex"
    },
    limitCreator: {
        minHeight: 35,
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    inputChipTextField: {
        width: 58,
        height: 28,
        marginRight: 8,
        marginBottom: 0 + "!Important",
        "& .MuiOutlinedInput-root": {
            position: "relative",
            borderRadius: 16,
            height: 28,
            color: theme.customColors.primaryColor
        }
    },
    grow: {
        flexGrow: 1
    }
}));
