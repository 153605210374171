import { useStyles } from "./styles";
import clsx from "clsx";

type Props = {
    lineColor?: string;
    lineDashArray?: Array<number>;
    circleColor?: string;
};

const LineIcon = ({ lineColor,lineDashArray, circleColor }: Props) => {
    const { legend, line,  circle, left, right } = useStyles({ lineColor,lineDashArray ,circleColor });

    const GenerateSvgLine = (lineColor?: string, lineDashArray?: Array<number>) => {
        const dashes = lineDashArray ? lineDashArray[0] : 0;
        const gaps = lineDashArray ? lineDashArray[1] : 0;
    
        return `url(\"data:image/svg+xml,%3csvg width='24' height='4' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0' y1='1' x2='24' y2='1' stroke='${lineColor}' stroke-width='6' stroke-dasharray='${dashes}%2c${gaps}' /%3e%3c/svg%3e\")`
    }

    return (
        <div className={legend}>
            {lineColor && <div className={line} style={{backgroundImage:GenerateSvgLine(lineColor,lineDashArray)}} />}
            {circleColor && (
                <>
                    <div className={clsx(circle, left)} />
                    <div className={clsx(circle, right)} />
                </>
            )}
        </div>
    );
};

export default LineIcon;
