import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
    projectsPickerWrapper: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        marginLeft: theme.spacing(2),
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        borderRight: `1px solid ${theme.customColors.borderColor}`,

        "& .MuiInput-root": {
            height: "100%"
        },

        "& .MuiSelect-icon": {
            right: theme.spacing(1)
        }
    },
    projectsPicker: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "100% !important",
        padding: theme.spacing(0, 4, 0, 2) + " !important",

        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07)"
        }
    },
    project: {
        display: "flex",
        justifyContent: "space-between",
        color: "black"
    },
    projectName: {
        display: "flex",
        alignItems: "center"
    },
    projectLogo: {
        height: 25,
        marginLeft: 35
    },
    selectProjectMenuItem: {
        display: "none"
    },
    selectProjectText: { color: theme.customColors.mutedColor }
}));
