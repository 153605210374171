import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        right: 0,
        bottom: 0,
        zIndex: 1
    },
    rootLegend: {
        position: "relative",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 2
    },
    text: {
        padding: "0 5px",
        backgroundColor: "rgba(255,255,255,0.60)",
        bottom: 0,
        left: 0,
        right: 0,
        maxWidth: "13vw",
        fontSize: "12px"
    },
    textLegend: {
        fontSize: "0.5rem"
    }
}));

const MapCopyright = ({ mapCopyright, modal = false, showMapLegend }) => {
    const classes = useStyles({ showMapLegend });
    return (
        <div id="map-copyright" className={`${modal ? classes.rootLegend : classes.root}`}>
            <Typography variant="caption" className={showMapLegend ? classes.textLegend : classes.text}>
                {mapCopyright}
            </Typography>
        </div>
    );
};

export default MapCopyright;
