import dayjs from "dayjs";
import "dayjs/plugin/utc";
import { AisMapLayer } from "../../../model/map/AisMapLayer";
import { GeoJsonSource } from "model/map/Source";

export const AIS = "AIS";
export const AIS_TITLE = "Vessel Position";
export const POSITION_RECEIVED_PROPERTY = "TIMESTAMP";

const zoomThreshold = 6;

export const mapSubToGeoJsonSource = (id: string, boats: any[]): GeoJsonSource => {
    return {
        id,
        type: "geojson",
        data: mapBoatsToGeoJson(boats),
        maxZoom: 24
    };
};

export const mapBoatsToGeoJson = (boats: any[]) => {
    return {
        type: "FeatureCollection",
        features: boats.map((boat) => mapBoatToFeature(boat))
    } as GeoJSON.FeatureCollection<GeoJSON.Point>;
};

const mapBoatToFeature = (boat: any) => {
    let textField = boat.NAME || boat.MMSI;
    let iconOpacity = isShipOld(boat.TIMESTAMP) ? 0.3 : 1;
    const iconImage = mapBoatTypeToColor(boat.AIS_TYPE_SUMMARY);

    //Marine Traffic returns 511 in the heading property for vessels with unknown heading.
    //In that case we show course instead
    const heading = boat.HEADING === "511" ? boat.COURSE : boat.HEADING;

    return {
        id: `${boat.SHIP_ID}-${boat.MMSI}`,
        type: "Feature",
        properties: {
            ...boat,
            // These are used for the style
            "icon-rotate": parseInt(heading),
            "text-field": textField,
            "icon-opacity": iconOpacity,
            "icon-image": iconImage
        },
        geometry: {
            coordinates: [boat.LON, boat.LAT],
            type: "Point"
        }
    };
};

const isShipOld = (timestamp: string) => {
    const ONE_MINUTE = 60 * 1000; /* ms */
    let shipTimeUtc = dayjs.utc(timestamp);
    let nowUtc = dayjs.utc();

    return nowUtc.diff(shipTimeUtc) > 30 * ONE_MINUTE;
};

export const mapSubToLayer = (subId: string) => {
    return {
        sourceId: subId,
        layerId: subId,
        type: "symbol" as const
    } as AisMapLayer;
};

export const mapSubToLayout = (subId: string) => {
    return {
        layerId: subId,
        properties: [
            { name: "visibility", value: "none" },
            { name: "icon-image", value: ["get", "icon-image"] },
            { name: "icon-rotate", value: ["get", "icon-rotate"] },
            { name: "icon-rotation-alignment", value: "map" },
            {
                name: "icon-size",
                value: [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    // zoom is 5 (or less) -> icon size will be 0.5
                    5,
                    0.5,
                    // zoom is 10 (or greater) -> icon size will be 1
                    10,
                    1
                ]
            },
            { name: "text-field", value: ["step", ["zoom"], "", zoomThreshold, ["get", "text-field"]] },
            { name: "text-variable-anchor", value: ["left", "right", "top", "bottom"] },
            { name: "text-radial-offset", value: 1 },
            { name: "text-justify", value: "auto" },
            { name: "text-size", value: 10 },

            { name: "icon-allow-overlap", value: true },
            { name: "text-optional", value: true }
        ]
    };
};

export const mapSubToPaint = (subId: string) => {
    return {
        layerId: subId,
        properties: [
            { name: "icon-opacity", value: ["get", "icon-opacity"] },
            { name: "text-halo-color", value: "white" },
            { name: "text-halo-blur", value: 0.5 },
            { name: "text-halo-width", value: 1 }
        ]
    };
};

export const mapSubToZoomRange = (subId: string) => {
    return {
        layerId: subId,
        minZoom: 0,
        maxZoom: 24
    };
};

export const getTypeDescription = (type: string) => {
    const descriptions = {
        StaticFleet: "Vessel Positions of a Static Fleet",
        DynamicFleet: "Vessel Positions of a Dynamic Fleet",
        Port: "Vessel Positions within a Port",
        PredefinedBoundingBox: "Vessel Positions in a Predefined Bounding Box",
        LiveData: "Vessel Positions in a Predefined Bounding Box",
        DynamicBoundingBox: "Vessel Positions in a Dynamic Bounding Box",
        SingleVessel: "Single Vessel Positions",
        CustomArea: "Vessel Positions in a Custom Area",
        VesselPositions: "Vessel positions",
        VesselPositionsAreaOfInterest: "Vessel positions in area of interest",
        SingleVesselPositions: "Single vessel positions"
    };
    return descriptions[type] as string;
};

const mapBoatTypeToColor = (boatType: string) => {
    let boatIcon = "boat-15";

    const boatTypeToImage = {
        Cargo: `${boatIcon}-green`,
        Tanker: `${boatIcon}-red`,
        Passenger: `${boatIcon}-blue`,
        "High-Speed Craft": `${boatIcon}-yellow`,
        "Special Craft": `${boatIcon}-teal`,
        Tug: `${boatIcon}-teal`,
        "Pleasure Craft": `${boatIcon}-purple`,
        Fishing: `${boatIcon}-orange`,
        "Navigation Aid": `navigational-aid`
    };

    return (boatTypeToImage[boatType] as string) || boatIcon;
};

export const getRenamedPropertyKey = (key: string) => {
    const values = {
        TIMESTAMP: "Position Received (UTC)"
    };

    return values[key] as string;
};
