import React, { useState } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as digitizeActions from "../../../actions/digitize";
import turfCenter from "@turf/center";
import { useDispatch, useSelector } from "react-redux";
import EditFeature from "./digitizeEditFeature";
import { getDigitizeFeaturesForLayerSelector } from "../../../selectors/digitizeSelectors";
import { useMap } from "@emblautec/react-map-gl";
import { useMainDraw } from "../../../utils/customHooks/map/useMainDraw";

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff"
    },
    featureContainer: {
        height: 45
    },
    featureName: {},
    noFeaturesText: {
        textAlign: "center"
    },
    editFieldButtons: {
        justifyContent: "end",
        display: "flex"
    },
    textField: {
        width: 200
    },
    iconButton: {
        margin: 2
    }
}));

const DigitizeFeatures = ({ selectedLayer, renderDistanceMarkers, setSelectedLayer, deselectAllFeaturesAndLabels }) => {
    const classes = useStyles();

    const { mainMap } = useMap();
    const draw = useMainDraw();

    const dispatch = useDispatch();
    const digitizeFeaturesForLayer = useSelector(getDigitizeFeaturesForLayerSelector(selectedLayer.id));
    const [selectedFeatures, setSelectedFeatures] = useState([]);

    const editLayerFeature = (feature, newName) => {
        let featureToBeChanged = digitizeFeaturesForLayer.find((digitizeFeature) => digitizeFeature.id === feature.id);
        featureToBeChanged.properties.name = newName;

        draw.setFeatureProperty(featureToBeChanged.id, "name", newName);
        dispatch(digitizeActions.renameFeature(featureToBeChanged, newName));
    };

    const deleteLayerFeature = (e, feature) => {
        deselectAllFeaturesAndLabels();

        e.stopPropagation();
        let newLayerFeatures = [];
        let newLayer = {};
        let drawingDistanceLabels = {
            type: "FeatureCollection",
            features: []
        };
        if (mainMap.getSource("digitize-drawn-line-measurement")) {
            mainMap.getSource("digitize-drawn-line-measurement").setData(drawingDistanceLabels);
        }
        e.stopPropagation();
        draw.delete(feature.id);
        newLayerFeatures = selectedLayer.features.filter((layerFeature) => layerFeature !== feature.id);
        newLayer = { ...selectedLayer, features: newLayerFeatures };
        setSelectedLayer(newLayer);
        dispatch(digitizeActions.editLayer(newLayer));
        dispatch(digitizeActions.removeFeature(feature));
        dispatch(digitizeActions.selectFeature(null));
    };

    const onFeatureClick = (e, feature) => {
        if (e.shiftKey) {
            setSelectedFeatures({
                selectedFeatures: [...selectedFeatures, feature]
            });
            draw.changeMode("simple_select", {
                featureIds: [...selectedFeatures.map((x) => x.id), feature.id]
            });
        } else {
            feature.geometry.type !== "Point" && renderDistanceMarkers({ features: [feature] });
            if (!feature.properties.isCircle) {
                //treating the default feature coordinates as coming from a point
                let coordinates = feature.geometry.coordinates;
                if (Array.isArray(coordinates[0])) {
                    if (coordinates.length === 1) {
                        coordinates = coordinates[0];
                    }
                    setSelectedFeatures({
                        selectedFeatures: [feature]
                    });
                    dispatch(digitizeActions.selectFeature(feature));
                    //checking if the feature coordinates are from a line
                    if (Array.isArray(coordinates[0])) {
                        coordinates = coordinates[0];
                        //checking if the feature coordinates are from a polygon
                        if (Array.isArray(coordinates[0])) {
                            coordinates = coordinates[0];
                        }
                    }
                }
            }
            let absoluteFeatureCenter = turfCenter(feature);

            mainMap.flyTo({
                center: [absoluteFeatureCenter.geometry.coordinates[0], absoluteFeatureCenter.geometry.coordinates[1]],
                speed: 0.5,
                curve: 1
            });
            draw.changeMode("simple_select", { featureIds: [feature.id] });
        }
    };

    return (
        <Grid container className={classes.root}>
            {digitizeFeaturesForLayer.length ? (
                digitizeFeaturesForLayer.map((feature) => (
                    <Grid item xs={12} key={feature?.id}>
                        <EditFeature
                            feature={feature}
                            classes={classes}
                            onFeatureClick={onFeatureClick}
                            deleteLayerFeature={deleteLayerFeature}
                            editLayerFeature={editLayerFeature}
                            setSelectedFeatures={setSelectedFeatures}
                            deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                        />
                        <Divider />
                    </Grid>
                ))
            ) : (
                <Grid item xs={12}>
                    <Typography className={classes.noFeaturesText} variant="subtitle1">
                        The layer has no features.
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default DigitizeFeatures;
