import { FeatureCollection } from "@turf/helpers";
import { AppLayer } from "model/app/AppLayer";
import { SourceType } from "model/enums/SourceType";

const minZoom = 0;
const maxZoom = 24;

export const getBufferSource = (sourceId: string, data: FeatureCollection) => {
    return {
        id: sourceId,
        type: "geojson",
        minZoom,
        maxZoom,
        data
    };
};

export const getBufferLayer = (sourceId: string, layerId: string, resourceId: string) => {
    return {
        sourceId: sourceId,
        layerId: layerId,
        resourceId: resourceId,
        type: "fill"
    };
};

export const getBufferPaintProperties = () => {
    return bufferPaint.map((x) => ({
        name: x.name,
        value: x.value
    }));
};

export const getBufferLayoutProperties = () => {
    return [
        {
            name: "visibility",
            value: "visible"
        }
    ];
};

export const mapMapboxFeaturesToTurfFeatures = (features: mapboxgl.MapboxGeoJSONFeature[]): FeatureCollection => {
    return {
        type: "FeatureCollection",
        features: features.map((feature) => ({
            type: feature.type,
            id: feature.id,
            properties: feature.properties,
            geometry: {
                type: feature.geometry.type,
                coordinates: feature.geometry.type !== "GeometryCollection" ? feature.geometry.coordinates : [],
                ...(feature.geometry.type === "GeometryCollection" && { geometries: feature.geometry.geometries })
            }
        }))
    };
};

export const getInitialBufferAppLayer = (
    layerResourceId: string,
    layerName: string,
    originalAppLayer: AppLayer
): AppLayer => {
    return {
        resourceId: layerResourceId,
        name: layerName,
        type: SourceType.Vector,
        bounds: {
            coordinates: [],
            type: "Polygon"
        },
        options: {
            downloadable: true,
            enabled: true,
            loading: true
        },
        sourceId: "",
        sourceName: "",
        geometryType: "",
        rowCount: originalAppLayer.rowCount,
        minZoom,
        maxZoom,
        isShown: true,
        isBuffer: true
    };
};

export const getAppLayerStyle = (styleId: string) => {
    return { type: "fill", maxZoom, minZoom, styleId, properties: bufferPaint };
};

export const defaultBufferOpacity = 0.3;

const bufferPaint = [
    {
        name: "fill-color",
        value: "darkcyan",
        type: "paint",
        title: "fill",
        propertyType: "color",
        expressionType: "none"
    },
    {
        name: "fill-outline-color",
        value: "black",
        type: "paint",
        title: "outline",
        propertyType: "color",
        expressionType: "none"
    },
    {
        name: "fill-opacity",
        value: defaultBufferOpacity,
        type: "paint",
        title: "opacity",
        propertyType: "float",
        expressionType: "none"
    },
    {
        name: "fill-antialias",
        value: true,
        type: "paint",
        title: "antialias",
        propertyType: "boolean",
        expressionType: "none"
    }
];

export const allowedBufferUnits = {
    meters: { key: "m", toMeters: (x: number) => x },
    kilometers: { key: "km", toMeters: (x: number) => x * 1000 },
    nauticalmiles: { key: "nm", toMeters: (x: number) => x * 1852 }
};
