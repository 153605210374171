import { DefaultTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";

const legendSize = 24;

export const useStyles = makeStyles<DefaultTheme, { circleColor?: string }>((theme) => ({
    legend: {
        width: legendSize,
        height: legendSize,
        position: "relative"
    },
    circle: (props) => ({
        height: "100%",
        width: "100%",
        backgroundColor: props.circleColor,
        borderRadius: 100
    })
}));
