import { useEffect } from "react";
import { useMap } from "@emblautec/react-map-gl";

const BoxZoom = () => {
    const { current } = useMap();
    useEffect(() => {
        // This is used in order to fix a problem in which the boxZoom
        // would get enabled and then disabled under the hood
        if (current && !current?.getMap().boxZoom.isEnabled()) {
            current.getMap().boxZoom.enable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current?.getMap().boxZoom.isEnabled()]);
    return null;
};

export default BoxZoom;
